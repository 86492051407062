import React, { useMemo } from 'react';
import { Affix, Button, Col, Layout, Row, Table } from 'antd';
import { observer } from 'mobx-react';
import { LoaderBox } from '../../components/LoaderBox';
import { CurrentCampaignTitle } from '../../components/CurrentCampaignTitle';
import { RequestForQuotationPageStore } from './RequestForQuotationPageStore';
import {
  RequestForQuotationCellParams,
  RequestForQuotationRow,
} from './RequestForQuotationPage.types';
import { floatInputFormatter, numberFormatter, priceFormatter } from '../../utils';
import { editableCellHandler, editableComponents } from '../../components/EditableTable';
import {
  NestedInput,
  NestedInputNumber,
  NestedSelect,
} from '../../components/EditableTable/nested';
import {
  ApnRow,
  formatConvertedPriceEuro,
  getUpdateCurrencyHandler,
  isRowGreyed,
  rowClasses,
} from '../../common/apn';
import { ApnFilter } from '../../components/ApnFilter';
import { FilterOutlined } from '@ant-design/icons';
import { ApnCommentIcons } from '../../components/ApnCommentIcons';
import { RFQMenu } from './RFQMenu';
import { ColumnMYA } from '../../components/ColumnMYA';
import { PreviousSessions } from '../../components/PreviousSessions';
import { PreviousYearInfo } from '../../components/PreviousYearInfo';
import { setBundlingId } from '../../api/cached/getBundlings';
import { Rule } from 'antd/es/form';
import { requiredRule } from '../../common/constants';
import moment from 'moment';

const pageStore = new RequestForQuotationPageStore();
const commentRules: Rule[] = [
  requiredRule,
  { type: 'string', max: 200, message: 'Comment exceed the max length.' },
  {
    type: 'string',
    max: 200,
    message: `Max characters is ${numberFormatter(200)}`,
  },
];
export const rowClassName = (record: ApnRow): string => {
  const classNames: string[] = [rowClasses.editable];

  if (isRowGreyed(record)) {
    classNames.push(rowClasses.grey);
  } else if (record.isCompleted) {
    classNames.push(rowClasses.highlighted);
  }

  return classNames.join(' ');
};

export const RequestForQuotationPage: React.FC = observer(() => {
  const { table, currencyList, bundlingList, canEdit } = pageStore;
  React.useEffect(() => {
    pageStore.init();
  }, []);

  const updateCurrency = useMemo(() => getUpdateCurrencyHandler(currencyList), [currencyList]);

  const localCellHandler = (params: RequestForQuotationCellParams) =>
    editableCellHandler<RequestForQuotationRow>({
      store: table,
      keyField: 'quotaId',
      isEditable: ({ isCompleted }) => canEdit && !isCompleted,
      ...params,
    });

  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>
            Request for Quotation <CurrentCampaignTitle />
          </h1>
        </Col>
        <Col>
          <RFQMenu store={pageStore} />
          <Button
            onClick={() => pageStore.filter.toggle()}
            icon={<FilterOutlined />}
            title='Filters'
            type={pageStore.filter.isUsed ? 'primary' : 'default'}
          />
        </Col>
      </Row>
      <Layout>
        <Layout.Content>
          <Table<RequestForQuotationRow>
            size='small'
            rowKey='quotaId'
            dataSource={table.data}
            loading={table.isLoading}
            pagination={table.paginator}
            components={editableComponents}
            onChange={table.onTableChange}
            rowClassName={rowClassName}
          >
            <Table.Column
              title='Manufacturer'
              key='manufacturerName'
              dataIndex='manufacturerName'
              width='12%'
            />
            <Table.Column
              title='Negotiator'
              key='negotiatorName'
              dataIndex='negotiatorName'
              width='8%'
            />
            <Table.Column title='Order code' key='orderCode' dataIndex='orderCode' width='8%' />
            <Table.Column title='BSH Part No' key='bshPartNo' dataIndex='bshPartNo' width='8%' />
            <Table.Column title='EMS' key='emsName' dataIndex='emsName' width='5%' />
            <Table.Column
              title='Release status'
              key='orderCodeStatusName'
              width='10%'
              render={({ orderCodeStatusName, updated_on }) => (
                <span style={{ whiteSpace: 'nowrap' }}>
                  {orderCodeStatusName ? orderCodeStatusName : ''}{' '}
                  {updated_on && orderCodeStatusName ? <br></br> : ''}{' '}
                  {updated_on ? moment(updated_on).format('DD.MM.YYYY') : ''}
                </span>
              )}
            />
            <Table.Column
              title='Commodity'
              key='commodity'
              width='8%'
              render={({ commodityCode, commodityGpmt, commodityName }) =>
                `${commodityCode || ''} ${commodityGpmt || ''} ${commodityName || ''}`
              }
            />
            <Table.Column
              title='Demand'
              key='demand'
              dataIndex='demand'
              render={value => numberFormatter(value)}
              width='8%'
              onCell={localCellHandler({
                field: 'demand',
                component: NestedInputNumber,
                extraProps: {
                  min: 0,
                  formatter: floatInputFormatter,
                },
              })}
            />
            <Table.Column
              key='MYA'
              title='MYA'
              width='4%'
              render={row => <ColumnMYA row={row} />}
            />
            <Table.Column
              title='Current Unit Price'
              key='price'
              width='8%'
              dataIndex='price'
              render={price => priceFormatter(price)}
              onCell={localCellHandler({
                field: 'price',
                component: NestedInputNumber,
                extraProps: {
                  min: 0,
                  max: 999999999,
                  formatter: floatInputFormatter,
                },
              })}
            />
            <Table.Column
              title='Current currency'
              key='currencyCode'
              dataIndex='currencyCode'
              width='4%'
              onCell={localCellHandler({
                field: 'currencyCode',
                component: NestedSelect,
                extraProps: {
                  options: currencyList,
                },
                preSave: updateCurrency,
              })}
            />
            <Table.Column
              title='EUR'
              key='convertedPriceEuro'
              dataIndex='convertedPriceEuro'
              width='3%'
              render={value => formatConvertedPriceEuro(value)}
            />
            <Table.Column
              title='Comment'
              key='negotiatorComment'
              dataIndex='negotiatorComment'
              width='14%'
              onCell={localCellHandler({
                field: 'negotiatorComment',
                component: NestedInput,
                rules: commentRules,
              })}
            />
            <Table.Column
              title=''
              key='commentsExt'
              width='3%'
              render={row => <ApnCommentIcons row={row} />}
            />
            <Table.Column
              title='Bundling'
              key='bundlingName'
              dataIndex='bundlingName'
              width='4%'
              onCell={localCellHandler({
                field: 'bundlingName',
                component: NestedSelect,
                extraProps: {
                  options: bundlingList,
                  allowClear: true,
                },
                preSave: setBundlingId,
              })}
            />
            <Table.Column
              title='Previous Sessions'
              key='previousSessions'
              width='4%'
              align='center'
              render={({ quotaId }) => <PreviousSessions quotaId={quotaId} />}
            />
            <Table.Column
              key='previousYearInfo'
              title='Additional info'
              width='3%'
              render={row => <PreviousYearInfo data={row} year={pageStore.campaign?.year} />}
            />
          </Table>
        </Layout.Content>
        <Affix>
          <Layout.Sider
            collapsible
            collapsedWidth={0}
            trigger={null}
            collapsed={!pageStore.filter.isOpen}
            width={300}
          >
            <ApnFilter store={pageStore.filter} />
          </Layout.Sider>
        </Affix>
      </Layout>
    </LoaderBox>
  );
});

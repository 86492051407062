import { action, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { TableStore, FnDataLoad } from '../../common/TableStore';
import {
  ManufacturerRow,
  ManufacturerFilter,
  ManufacturerActionsMap,
} from './ManufacturerPage.types';
import { getRows } from '../../api/getRows';
import { updatePageActions } from '../../common/updatePageActions';

const load: FnDataLoad<ManufacturerRow, ManufacturerFilter> = (params: ManufacturerFilter) =>
  getRows('/api/v1/Manufacturer', params);

export class ManufacturerTableStore extends TableStore<ManufacturerRow, ManufacturerFilter> {
  constructor() {
    super(
      {
        page: 0,
        pageSize: 10,
      },
      load
    );
  }
}

export class ManufacturerPageStore extends LoaderStore {
  table: ManufacturerTableStore;
  actions?: ManufacturerActionsMap;

  setActions(actions: ManufacturerActionsMap) {
    this.actions = actions;
  }

  constructor() {
    super();
    this.table = new ManufacturerTableStore();
    makeObservable(this, {
      actions: observable,
      setActions: action,
    });
  }

  init() {
    if (this.loaderStatus === 'none') {
      this.onReady();
      this.onWait();
      updatePageActions('Manufacturer', this)
        .then(() => {
          this.onReady();
          this.table.init();
        })
        .catch(e => this.onError(e));
    } else {
      updatePageActions('Manufacturer', this);
      this.table.reload();
    }
  }
}

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import {
  PaginationParams,
  RequestStateWithPagination,
  ResponseWithPagination,
} from './state-types';
import { getRequestInitialStateWithPagination } from './request-slice-utils';
import { RequestStatuses } from './request-constants';

export const createRequestSliceWithPagination = <
  T,
  Reducers extends SliceCaseReducers<RequestStateWithPagination<T>>
>({
  name = '',
  initialDataState,
  reducers,
}: {
  name: string;
  initialDataState: T;
  reducers: ValidateSliceCaseReducers<RequestStateWithPagination<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState: getRequestInitialStateWithPagination(initialDataState),
    reducers: {
      request(state) {
        state.status = RequestStatuses.request;
      },

      requestSuccess(
        state: RequestStateWithPagination<T>,
        { payload }: PayloadAction<ResponseWithPagination<T>>
      ) {
        state.status = RequestStatuses.requestSuccess;
        state.data = payload.data;
        state.meta = payload.meta;
      },

      requestError(state) {
        state.status = RequestStatuses.error;
      },

      setPagination(state, { payload }: PayloadAction<Partial<PaginationParams>>) {
        if (payload.pageSize) {
          state.meta.pageSize = payload.pageSize;
        }

        if (payload.page) {
          state.meta.page = payload.page;
        }
      },
      ...reducers,
    },
  });
};

import API from '@aws-amplify/api';
import { MAIN_API } from './init-aws-amplify';

export interface Part {
  partId: number;
  bshPartNo: string;
  commodityId: number;
  designation: string;
}

export const part = {
  getByPartNo: (partNo: string): Promise<Part> => {
    return API.get(MAIN_API, `/api/v1/material/parts/${partNo}`, {}) as Promise<Part>;
  },
};

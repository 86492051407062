import * as React from 'react';
import { Select } from 'antd';
import { SapVendor } from '../../api/types';
import { Rest } from '../../api/Rest';
import { drawErrorMessage } from '../../common/drawErrorMessage';

const { Option } = Select;

interface SapVendorProps {
  disabled?: boolean;
  value?: string;
  onChange?: (emsCode: string) => void;
}

export const SapVendorSelect: React.FC<SapVendorProps> = ({ disabled, value, onChange }) => {
  const [sapVendors, setSapVendors] = React.useState<SapVendor[] | null>(null);
  const loading = sapVendors === null;
  React.useEffect(() => {
    Rest.get<{ data: SapVendor[] }>('/api/v1/Settings/sapvendors', { pageSize: '1000' })
      .then(res => setSapVendors(res.data))
      .catch(e => drawErrorMessage(e));
  }, []);
  return (
    <Select
      mode='multiple'
      loading={loading}
      showSearch
      placeholder='Start typing to find Sap Vendor'
      disabled={disabled}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => {
        return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {sapVendors?.map(sapVendor => (
        <Option value={sapVendor.sapVendorId} key={sapVendor.sapVendorId}>
          {sapVendor.name}
        </Option>
      ))}
    </Select>
  );
};

import React, { useEffect } from 'react';
import { Row, Col, Table } from 'antd';
import { CampaignMenu } from './components/campaign-menu';
import { observer } from 'mobx-react';
import { CampaignPageStore } from './CampaignPageStore';
import { LoaderBox } from '../../components/LoaderBox';
import { CampaignActionsView } from './components/CampaignActionsView';
import './CampaignPage.less';
import { mapCampaignStatus } from '../../utils';
import { CampaignDownloadColumn } from './components/CampaignDownloadColumn';

const pageStore = new CampaignPageStore();
const { Column } = Table;

const colWidth = '25%';

export const Campaign = observer(() => {
  const { table, currentCampaign } = pageStore;

  useEffect(() => {
    pageStore.init();
  }, []);

  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>{currentCampaign?.year} Campaign</h1>
        </Col>
        <Col>
          <CampaignMenu store={pageStore} />
        </Col>
      </Row>
      <div className='campaign-table-box'>
        <Table
          size='small'
          dataSource={table.data}
          rowKey='year'
          loading={table.isLoading}
          pagination={false}
          className='campaign-table'
        >
          <Column title='Year' key='year' dataIndex='year' align='center' width={colWidth} />
          <Column
            title='Status'
            key='statusName'
            dataIndex='statusName'
            align='center'
            width={colWidth}
            render={mapCampaignStatus}
          />
          <Column
            title='Actions'
            key='Actions'
            width={colWidth}
            align='center'
            render={row => <CampaignActionsView row={row} store={pageStore} />}
          />
          <Column
            title='Reports'
            key='Reports'
            width={colWidth}
            align='center'
            render={row => <CampaignDownloadColumn row={row} store={pageStore} />}
          />
        </Table>
      </div>
    </LoaderBox>
  );
});

import React, { FunctionComponent } from 'react';
import { Typography } from 'antd';

const { Title } = Typography;

export const Overview: FunctionComponent = () => {
  return (
    <>
      <Title>Overview</Title>
      <p>Content</p>
    </>
  );
};

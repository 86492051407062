import { makeObservable, observable, action, computed } from 'mobx';
import { ErrorMessageType, formatErrorMessage } from '../api/utils/error-message-formatter';

export type LoaderStatus = 'none' | 'wait' | 'ready' | 'error';

export class LoaderStore {
  loaderStatus: LoaderStatus = 'none';
  loaderError?: Error;

  constructor() {
    makeObservable(this, {
      loaderStatus: observable,
      loaderError: observable,
      onWait: action,
      onReady: action,
      onError: action,
      errorMessage: computed,
      isForbidden: computed,
    });
  }
  onWait() {
    this.loaderStatus = 'wait';
  }
  onReady() {
    this.loaderStatus = 'ready';
    this.loaderError = undefined;
  }
  onError(error: Error) {
    this.loaderStatus = 'error';
    this.loaderError = error;
  }
  get errorMessage(): ErrorMessageType {
    if (this.loaderError instanceof Error) {
      return formatErrorMessage(this.loaderError);
    }
    return '';
  }
  get isForbidden(): boolean {
    // @ts-ignore
    return this.loaderError?.response?.status === 403;
  }
}

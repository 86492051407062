import { action, computed, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { FnDataLoad, RemoteResponse, TableStore } from '../../common/TableStore';
import {
  MatrixBasisEditData,
  MatrixBasisFilter,
  MatrixBasisPageActionsMap,
  MatrixBasisRightFields,
  MatrixBasisRow,
} from './MatrixBasisPage.types';
import { getRows } from '../../api/getRows';
import { makeStrParams, Rest } from '../../api/Rest';
import { FnFormSave, FormStore } from '../../common/FormStore';
import { MatrixBasisQuotaStore } from './MatrixBasisQuota';
import { updatePageActions } from '../../common/updatePageActions';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { LabelValue } from '../../common/types';
import { transformCplComponents } from '../../common/cpl/transformCplComponents';
import { Modal } from 'antd';
import { MatrixBasisTools } from './MatrixBasisTools';

const loadDemand: FnDataLoad<MatrixBasisRow, MatrixBasisFilter> = filter =>
  getRows('/api/v1/Batch/matrixbasis', transformCplComponents(filter));

const editSave: FnFormSave<MatrixBasisRow> = async (oldData, newData) => {
  await Rest.patch(`/api/v1/Batch/cpl/edit/${oldData.demandId}`, newData).catch(e => {
    if (e.response?.data?.reason !== undefined) {
      if (JSON.stringify(e.response?.data?.reason).startsWith('{"B1YC0":["WARNING')) {
        const { destroy } = Modal.confirm({
          title: `Component will be deleted!`,
          content: `The existing BSH Part No with "Estimate" price type for the same year will be deleted. This operation can't be cancelled or reverted.
       \n Press OK to continue`,
          onOk: async () => {
            newData.IsAllowDeleteEstimates = true;
            editSave(oldData, newData);
            destroy();
          },
        });
      }
      drawErrorMessage(e, "Can't add new component");
    } else {
      drawErrorMessage(e, "Can't add new component");
    }
  });
};

const editSave2: FnFormSave<MatrixBasisEditData> = async (oldData, newData) => {
  await Rest.patch(`/api/v1/Batch/cpl/edit/${oldData.demandId}`, newData).catch(e => {
    if (e.response?.data?.reason !== undefined) {
      if (JSON.stringify(e.response?.data?.reason).startsWith('{"B1YC0":["WARNING')) {
        const { destroy } = Modal.confirm({
          title: `Component will be deleted!`,
          content: `The existing BSH Part No with "Estimate" price type for the same year will be deleted. This operation can't be cancelled or reverted.
       \n Press OK to continue`,
          onOk: async () => {
            newData.IsAllowDeleteEstimates = true;
            editSave2(oldData, newData);
            destroy();
          },
        });
      }
      drawErrorMessage(e, "Can't add new component");
    } else {
      drawErrorMessage(e, "Can't add new component");
    }
  });
};
export class DemandTableStore extends TableStore<MatrixBasisRow, MatrixBasisFilter> {
  checkedRows = [] as MatrixBasisRow[];
  lockProcess = new Set<number>();
  editForm: FormStore<MatrixBasisRow>;
  edit: FormStore<MatrixBasisEditData>;
  quotas: Record<number, MatrixBasisQuotaStore> = {};

  constructor() {
    super({ page: 0, pageSize: 10 }, loadDemand);
    const onEditFinish = () => {
      this.modifyRowExt('demandId', this.editForm.data);
    };

    const onEditFinish2 = () => {
      this.reloadRow(this.edit.data.demandId);
    };

    this.editForm = new FormStore<MatrixBasisRow>(editSave, onEditFinish);
    this.edit = new FormStore<MatrixBasisEditData>(editSave2, onEditFinish2);
    makeObservable(this, {
      checkedRows: observable,
      lockProcess: observable,
      quotas: observable,
      setCheckedRows: action,
      canLock: computed,
      canUnlock: computed,
      lockChecked: action,
      toggleLock: action,
      onLockingFinish: action,
      deleteRow: action,
      onExpand: action,
      expandedRowKeys: computed,
      reloadRow: action,
    });
  }
  onData(response: RemoteResponse<MatrixBasisRow>, newFilter: MatrixBasisFilter) {
    super.onData(response, newFilter);
    this.checkedRows = [];
    this.lockProcess.clear();
    this.quotas = {};
  }
  setCheckedRows(checkedRows: MatrixBasisRow[]) {
    this.checkedRows = checkedRows;
  }
  get canLock(): boolean {
    return !!this.checkedRows.find(row => !row.isLocked);
  }
  get canUnlock(): boolean {
    return !!this.checkedRows.find(row => row.isLocked);
  }

  lockChecked(isLock: boolean, reload: () => void) {
    interface Body {
      isLock: boolean;
      demands: number[];
    }
    const body = {
      isLock,
      demands: this.checkedRows.map(({ demandId }) => demandId),
    };
    this.lock();
    Rest.post<void, Body>('/api/v1/Demand/bulk/lock', body)
      .then(() => {
        this.onGroupLockSuccess();
        reload();
      })
      .finally(() => this.unlock());
  }
  onGroupLockSuccess() {
    this.setCheckedRows([]);
  }
  toggleLock(row: MatrixBasisRow) {
    this.lockProcess.add(row.demandId);
    const isLocked = !row.isLocked;
    const body = { isLocked };
    const { demandId } = row;
    Rest.patch<void, typeof body>(`/api/v1/Demand/${row.demandId}`, body)
      .then(() => this.onSingleLockSuccess(demandId, isLocked))
      .finally(() => this.onLockingFinish(demandId))
      .then(() => this.reloadRow(demandId));
  }
  reloadRow(demandId: number) {
    this.lock();
    Rest.get<MatrixBasisRow>(`/api/v1/Batch/Cpl/${demandId}`)
      .then(response => {
        this.modifyRowExt('demandId', response);
      })
      .finally(() => this.unlock());
  }
  onSingleLockSuccess(demandId: number, isLocked: boolean) {
    const index = this.data.findIndex(row => row.demandId === demandId);
    if (index >= 0) {
      this.modifyRow(index, { isLocked });
    }
  }
  onLockingFinish(demandId: number) {
    this.lockProcess.delete(demandId);
  }
  isLocking(row: MatrixBasisRow): boolean {
    return this.lockProcess.has(row.demandId);
  }
  deleteRow(row: MatrixBasisRow) {
    this.lock();
    Rest.del(`/api/v1/Batch/cpl/${row.demandId}`)
      .catch(e => drawErrorMessage(e, "Can't delete this record"))
      .then(() => this.reload())
      .finally(() => this.unlock());
  }

  onExpand(isExpand: boolean, row: MatrixBasisRow) {
    const { demandId, isLocked, priceType, filterCurrencyRate } = row;
    if (!isExpand) {
      delete this.quotas[demandId];
    } else {
      const qStore = new MatrixBasisQuotaStore(demandId, isLocked, priceType, filterCurrencyRate);
      this.quotas[demandId] = qStore;
      qStore.init();
    }
  }
  get expandedRowKeys(): number[] {
    return Object.keys(this.quotas).map(id => +id);
  }
}

export class MatrixBasisPageStore extends LoaderStore {
  table: DemandTableStore;
  isFilterOpen: boolean = false;
  addLineStatus: 'none' | 'active' | 'save' = 'none';
  permissions?: MatrixBasisPageActionsMap;
  bundlingOptions: LabelValue[] = [];

  constructor() {
    super();
    this.table = new DemandTableStore();
    makeObservable(this, {
      permissions: observable,
      isFilterOpen: observable,
      addLineStatus: observable,
      isAddLine: computed,
      toggleFilter: action,
      isFilterUsed: computed,
      startAddLine: action,
      closeAddLine: action,
      createNewLine: action,
      onCreateNewLine: action,
      setActions: action,
    });
  }
  setActions(actions: MatrixBasisPageActionsMap) {
    this.permissions = actions;
  }
  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([updatePageActions('Batch', this)])
        .then(() => {
          this.onReady();
          this.table.init();
        })
        .catch(e => this.onError(e));
    } else {
      this.table.reload();
      updatePageActions('Batch', this);
    }
  }
  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }
  get isFilterUsed(): boolean {
    const { filter } = this.table;
    return !!MatrixBasisRightFields.find(key => filter[key] !== undefined && filter[key] !== false);
  }
  get allowBulk(): boolean {
    return !!this.permissions?.bulklock;
  }

  get isAddLine(): boolean {
    return this.addLineStatus !== 'none';
  }
  get isAddLineSaving(): boolean {
    return this.addLineStatus === 'save';
  }
  startAddLine() {
    this.addLineStatus = 'active';
  }

  createNewLine(values: Partial<MatrixBasisRow>) {
    this.addLineStatus = 'save';
    Rest.post<string, typeof values>('/api/v1/Batch/Cpl', values)
      .then(response => {
        return Rest.get<MatrixBasisRow>(`/api/v1/Batch/Cpl/${response}`);
      })
      .then(response => {
        this.onCreateNewLine(response);
      })
      .catch(e => {
        if (e.response?.data?.reason !== undefined) {
          if (JSON.stringify(e.response?.data?.reason).startsWith('{"B1YC0":["WARNING')) {
            const { destroy } = Modal.confirm({
              title: `Component will be deleted!`,
              content: `The existing BSH Part No with "Estimate" price type for the same year will be deleted. This operation can't be cancelled or reverted.
       \n Press OK to continue`,
              onOk: async () => {
                values.IsAllowDeleteEstimates = true;
                this.createNewLine(values);
                destroy();
              },
            });
            return;
          }
          drawErrorMessage(e, "Can't add new component");
          this.startAddLine();
        } else {
          drawErrorMessage(e, "Can't add new component");
          this.startAddLine();
        }
      });
  }

  onCreateNewLine(row: MatrixBasisRow) {
    this.closeAddLine();
    this.table.reload();
    this.table.data = [...this.table.data, row];
  }

  closeAddLine() {
    this.addLineStatus = 'none';
  }

  getExportKey = (): Promise<string> => {
    const pureFilter = {} as Partial<MatrixBasisFilter>;
    // @ts-ignore
    MatrixBasisRightFields.forEach(key => (pureFilter[key] = this.table.filter[key]));
    const filter = transformCplComponents(pureFilter);
    const params = makeStrParams(filter);
    return Rest.postExt<string, void>('/api/v1/Batch/Cpl/export', {
      queryStringParameters: params,
      responseType: 'text',
    });
  };
}

import React from 'react';
import { MenuItem } from './constants';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuPermissions } from '../../store/utils/state-types';

const { SubMenu } = Menu;

export const renderMenuItem = ({ title, to, children, name }: MenuItem) =>
  children ? (
    <SubMenu key={name} title={title}>
      {children.map(renderMenuItem)}
    </SubMenu>
  ) : (
    to && (
      <Menu.Item key={to}>
        <Link to={to}>{title}</Link>
      </Menu.Item>
    )
  );

export const getAvailableMenuItems = (menuItems: MenuItem[], permissions: MenuPermissions) => {
  return menuItems.reduce((result: MenuItem[], item: MenuItem) => {
    if (!item.children) {
      return permissions[item.name] ? [...result, item] : result;
    }

    const children = item.children.filter(({ name }) => permissions[name]);

    return children.length ? [...result, { ...item, children }] : result;
  }, []);
};

import { Rest } from '../Rest';
import { NegotiatorItem } from '../types';

let cache: NegotiatorItem[];

export const getNegotiators = async (): Promise<NegotiatorItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: NegotiatorItem[] }>('/api/v1/Settings/negotiators');
    cache = response.data;
  }
  return cache;
};

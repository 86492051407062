import { makeAutoObservable } from 'mobx';
import { BatchResponse, BomFilter, DownloadPrepare, FileNameType } from './ExportBom.types';
import { makeStrParams, Rest } from '../../../api/Rest';
import { RcFile } from 'antd/es/upload';
import { delay } from '../../../utils/delay';
import { drawErrorMessage } from '../../../common/drawErrorMessage';
import { UploadFile } from 'antd/es/upload/interface';

type ExportBomStatus = 'hidden' | 'ready' | 'save';

const importType = 'BomRtf';

export class ExportBomStore {
  status: ExportBomStatus = 'hidden';
  lockCounter = 0;
  batchId?: number;
  downloadUrl: string = '';
  logTargetName: string = '';
  importStatus: 'success' | 'error' = 'success';
  itemsMap = new Map<string, number>(); // file uid => itemId for delete operation
  logUrlMap = new Map<string, string>(); // file uid => logUrl
  wrongItems = new Set<string>(); // file uids of invalid items
  goodItems = 0;

  constructor() {
    makeAutoObservable(this);
  }
  get visible(): boolean {
    return this.status !== 'hidden';
  }
  get saving(): boolean {
    return this.status === 'save';
  }
  protected clear(status: ExportBomStatus) {
    this.status = status;
    this.batchId = undefined;
    this.downloadUrl = '';
    this.itemsMap.clear();
    this.wrongItems.clear();
    this.lockCounter = 0;
    this.goodItems = 0;
  }
  activate() {
    this.clear('ready');
  }
  close = () => {
    this.deleteBatch();
    this.clear('hidden');
  };

  protected async deleteBatch() {
    if (this.batchId) {
      await Rest.del(`/api/v1/batch/${this.batchId}`);
    }
  }
  prepareDownload = async (
    years: number | undefined,
    ems: string,
    calctype: string,
    pcs: string
  ) => {
    if (!this.batchId || this.status !== 'ready') {
      return;
    }
    if (this.invalidCount > 0) {
      return;
    }
    this.status = 'save';
    try {
      const pureFilter = {} as Partial<BomFilter>;
      // @ts-ignore
      //demandRightFields.forEach(key => (pureFilter[key] = this.table.filter[key]));
      const filter = pureFilter;
      filter.year = years;
      filter.pcs = pcs;
      filter.ems = ems;
      filter.matrixType = calctype;

      const params = makeStrParams(filter);
      await Rest.postExt(`/api/v1/Report/batch/${this.batchId}/export/BomMatrix`, {
        queryStringParameters: params,
        responseType: 'text',
      });
      interface BatchExportStatus {
        ready: boolean;
        failed: boolean;
      }
      for (;;) {
        const { ready, failed } = await Rest.get<BatchExportStatus>(
          `/api/v1/Batch/${this.batchId}`
        );
        if (failed) {
          throw Error('Something went wrong. Downloading is not possible.');
        }
        if (ready) break;
        await delay(1000);
      }
      const res: DownloadPrepare = await Rest.get(`/api/v1/Batch/${this.batchId}/download`);
      this.setDownloadUrl(res.url);
    } catch (e) {
      drawErrorMessage(e);
    }
    this.setReady();
  };
  protected setReady() {
    this.status = 'ready';
  }
  protected setDownloadUrl(url: string) {
    this.downloadUrl = url;
  }
  download = () => {
    this.close();
  };
  get disabled(): boolean {
    return !this.batchId || this.lockCounter !== 0 || this.totalCount === 0;
  }
  protected async preLoadItem(file: RcFile): Promise<BatchResponse> {
    const body: FileNameType = {
      fileName: file.name,
      type: importType,
    };
    if (this.itemsMap.size === 0 && this.batchId) {
      await this.deleteBatch();
      this.batchId = undefined;
    }
    if (!this.batchId) {
      const res: BatchResponse = await Rest.post('/api/v1/Batch', [body]);
      this.setBatchId(res.batchId);
      return res;
    }
    return await Rest.put(`/api/v1/Batch/${this.batchId}/items`, body);
  }
  protected setBatchId(id: number) {
    this.batchId = id;
  }

  loader: Promise<void> = Promise.resolve();

  async loadItem(file: RcFile): Promise<void> {
    this.downloadUrl = '';
    await this.loader;
    const newLoader = this.loader.then(() => this.processItem(file));
    this.loader = newLoader.catch(e => console.log(e));
    return await newLoader;
  }

  protected async processItem(file: RcFile) {
    const res = await this.preLoadItem(file);
    const { id, sign } = res.units[0];
    this.itemsMap.set(file.uid, id);
    await fetch(sign.url, { method: sign.action.toLowerCase(), body: file });
    interface ImportStatusResponse {
      importKey: string;
      fileName: string;
      statusId: number;
      statusName: string;
      createdDate: string;
      modifiedDate?: string;
    }
    interface LogResponse {
      url: string;
    }
    for (;;) {
      const res: ImportStatusResponse = await Rest.get(`/api/v1/Import/${sign.key}`);
      if (res.statusName === 'Open' || res.statusName === 'InProgress') {
        await delay(1000);
        continue;
      }
      const logRes: LogResponse = await Rest.get(`/api/v1/Import/${sign.key}/logs`);
      this.setItem(file, res.statusName, logRes.url);
      break;
    }
    this.addGood();
  }
  protected addGood() {
    this.goodItems++;
  }
  protected setItem(file: RcFile, status: string, logUrl: string) {
    this.logUrlMap.set(file.uid, logUrl);
    this.logTargetName = file.name;
    if (status === 'Failed') {
      this.importStatus = 'error';
      throw new Error(`Can't import file ${file.name}`);
    }
    this.importStatus = 'success';
  }
  onError(uid: string) {
    this.wrongItems.add(uid);
  }
  onRemove(uid: string) {
    const itemId = this.itemsMap.get(uid);
    if (itemId) {
      Rest.del(`/api/v1/Batch/${this.batchId}/items/${itemId}`).catch(e => drawErrorMessage(e));
      this.itemsMap.delete(uid);
    }
    if (this.wrongItems.has(uid)) {
      this.wrongItems.delete(uid);
    } else {
      this.goodItems--;
    }
  }
  lock() {
    this.lockCounter++;
  }
  unlock() {
    this.lockCounter--;
  }
  getLogUrl(file: UploadFile): string | undefined {
    return this.logUrlMap.get(file.uid);
  }
  get invalidCount(): number {
    return this.wrongItems.size;
  }
  get totalCount(): number {
    return this.invalidCount + this.goodItems;
  }
}

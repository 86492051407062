import * as React from 'react';
import { Space } from 'antd';
import { MessageOutlined } from '@ant-design/icons';

export interface RowWithMYA {
  myaComments: string | null;
  myaLink: string | null;
  myaSubject: string | null;
}

interface PropsColumnMYA {
  row: RowWithMYA;
}

export const ColumnMYA: React.FC<PropsColumnMYA> = ({ row }) => {
  const title = row.myaComments || row.myaSubject;
  return (
    <Space>
      {title && <MessageOutlined title={title} />}
      {row.myaLink && (
        <a href={row.myaLink} target='_blank' rel='noreferrer'>
          PDF
        </a>
      )}
    </Space>
  );
};

import * as React from 'react';
import { Modal, Button } from 'antd';
import { ImportType, TableUploader } from './TableUploader';

interface AbstractTableStore {
  load(params: { page?: number }): void;
}

interface PropsTableUploaderModal {
  visible: boolean;
  title: string;
  importType: ImportType;
  onClose(): void;
  store: AbstractTableStore | null;
}

export const TableUploaderModal: React.FC<PropsTableUploaderModal> = ({
  visible,
  title,
  importType,
  onClose,
  store,
}) => (
  <Modal
    visible={visible}
    title={title}
    onCancel={onClose}
    maskClosable={false}
    footer={[
      <Button key='close' onClick={onClose}>
        Close
      </Button>,
    ]}
  >
    <TableUploader
      importType={importType}
      onSuccess={() => {
        store?.load({ page: 0 });
      }}
    />
  </Modal>
);

import * as React from 'react';
import { observer } from 'mobx-react';
import { FormStore } from '../../common/FormStore';
import { DemandEditData } from './DemandPage.types';
import { ModalForm } from '../../components/ModalForm';
import { Form, Input } from 'antd';
import { DemandField } from './demand-field';
import { Rule } from 'antd/es/form';
import { formLayout, requiredRule } from '../../common/constants';
import { EmsSelect } from '../../components/ems-select/ems-select';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../store/features/session/session-slice';
import { ProjectSelectBsh } from '../../components/ProjectsSelect/ProjectSelectBsh';

interface PropsDemandEdit {
  formStore: FormStore<DemandEditData>;
}

const emsPartNoRules: Rule[] = [{ max: 100, message: 'Can not be longer than 100 characters' }];
const emsRules: Rule[] = [requiredRule];

export const DemandEdit: React.FC<PropsDemandEdit> = observer(({ formStore }) => {
  const onSave = (data: Partial<DemandEditData>) => {
    return formStore.save(data);
  };
  const value = formStore.data;
  const { groups } = useSelector(selectUserInfo);
  const isSupr = groups.includes('SUPUSR');
  const isPrpur = groups.includes('PRPUR');
  const canChangeEMS = groups.includes('SUPUSR' || 'PRPUR');
  const onInternalChange = (newEmsValue: string) => {
    var dr = formStore.data;
    dr.emsCode = newEmsValue;
    value.emsName = newEmsValue;
    formStore.setData(dr);
  };
  return (
    <ModalForm
      title='Edit component demand'
      name='demandEdit'
      layout={formLayout}
      initialData={formStore.data}
      visible={formStore.visible}
      saving={formStore.saving}
      onClose={formStore.close}
      onSave={onSave}
    >
      <Form.Item label='EMS' name='emsCode' rules={emsRules}>
        {canChangeEMS && <EmsSelect onChange={onInternalChange} val={value?.emsName} />}
        {value?.emsName}
      </Form.Item>
      <Form.Item label='BSH Part No'>{value?.bshPartNo}</Form.Item>
      <Form.Item label='Previous BSH Part No' name='prevBshPartNo'>
        <Input disabled={!isSupr && !isPrpur} value={value?.prevBshPartNo ?? ''} maxLength={30} />
      </Form.Item>
      <Form.Item label='Designation'>{value?.designation}</Form.Item>
      <Form.Item label='Project(s)' name='projects'>
        <ProjectSelectBsh bshpartno={value.bshPartNo ?? ''} />
      </Form.Item>
      <Form.Item label='EMS Part No' name='emsPartNo' rules={emsPartNoRules}>
        <Input />
      </Form.Item>
      <DemandField />
    </ModalForm>
  );
});

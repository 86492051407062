import { action, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { Rest } from '../../api/Rest';
import { LOARow } from './LOAPage.types';

interface PdfUrlResponse {
  url: string;
}

export class LOAExportStore extends LoaderStore {
  addLineStatus: 'none' | 'active' | 'save' = 'none';

  constructor() {
    super();
    makeObservable(this, {
      addLineStatus: observable,
      startAddLine: action,
      closeAddLine: action,
      createNewLine: action,
    });
  }

  get isAddLine(): boolean {
    return this.addLineStatus !== 'none';
  }

  get isAddLineSaving(): boolean {
    return this.addLineStatus === 'save';
  }

  startAddLine() {
    this.addLineStatus = 'active';
  }

  async createNewLine(values: Partial<LOARow>) {
    this.addLineStatus = 'save';
    try {
      const response = await Rest.post<PdfUrlResponse, typeof values>('/api/v1/Loa', values);
        const pdfUrl = response.url;
      if (pdfUrl) {
        const a = document.createElement('a');
        a.href = pdfUrl;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        console.error('PDF URL is not valid. Response:', response);
      }
    } catch (e) {
      drawErrorMessage(e, "Can't generate PDF!");
    } finally {
      this.startAddLine();
    }
  }

  closeAddLine() {
    this.addLineStatus = 'none';
  }
}

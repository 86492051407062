import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table, Layout, Button, Affix } from 'antd';
import { LoaderBox } from '../../components/LoaderBox';
import { SDRow } from './ShareDistribution.types';
import { ShareDistributionPageStore } from './ShareDistributionPageStore';
import { SD2Table } from './SD2Table';
import { CurrentCampaignTitle } from '../../components/CurrentCampaignTitle';
import { floatInputFormatter, numberFormatter } from '../../utils';
import { FilterOutlined } from '@ant-design/icons';
import { ApnFilter } from '../../components/ApnFilter';
import { PermissionCheck } from '../../components/PermissionCheck';
import { UserActions } from '../../store/utils/state-types';
import { rowClasses } from '../../common/apn';
import { ShareDistributionTools } from './ShareDistributionTools';
import './ShareDistribution.less';
import { editableCellHandler, editableComponents } from '../../components/EditableTable';
import { NestedInputNumber, NestedSelect } from '../../components/EditableTable/nested';
import { Project } from '../../store/utils/state-types';
import { setBundlingId } from '../../api/cached/getBundlings';
import { LoaExport } from '../LOA/LoaExport';
import { LOAExportStore } from '../LOA/LOAExportStore'
import { LOAExportMailStore } from '../LOA/LOAExportMailStore';

const sdPageStore = new ShareDistributionPageStore();
const loaExportStore = new LOAExportStore();
const loaExportMailStore = new LOAExportMailStore();
const rowClassName = ({ isCompleted }: SDRow): string => {
  return (isCompleted ? rowClasses.highlighted : '') + ' ' + rowClasses.editable;
};
const parseCompletePermissions = (actions: UserActions) => {
  const { complete, uncomplete } = actions;

  return {
    completed: complete && !uncomplete?.allow ? complete : undefined,
    edit: uncomplete && !complete.allow ? uncomplete : undefined,
  };
};

const commodityCell = (rec: SDRow) =>
  `${rec.commodityCode || ''} ${rec.commodityGpmt || ''} ${rec.commodityName || ''}`;

export const ShareDistributionPage: React.FC = observer(() => {
  const { table, bundlingOptions, canEdit } = sdPageStore;
  React.useEffect(() => {
    sdPageStore.init();
  }, []);

  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: SDRow[]) => {
      sdPageStore.setCheckedRows(selectedRows);
    },
    getCheckboxProps: (record: SDRow) => ({
      disabled: false,
      name: String(record.demandId),
    }),
  };

  return (
    <LoaderBox store={sdPageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>
            Share Distribution <CurrentCampaignTitle />
          </h1>
        </Col>
        <Col>
          <ShareDistributionTools store={sdPageStore} storeLOA={loaExportStore} />
          <Button
            onClick={() => sdPageStore.filter.toggle()}
            icon={<FilterOutlined />}
            title='Filters'
            type={sdPageStore.filter.isUsed ? 'primary' : 'default'}
          />
        </Col>
      </Row>
      <Layout>
        <Layout.Content>
          <Table<SDRow>
            size='small'
            rowKey='demandId'
            dataSource={table.data}
            loading={table.isLoading}
            pagination={table.paginator}
            onChange={table.onTableChange}
            rowClassName={rowClassName}
            rowSelection={rowSelection}
            components={editableComponents}
            expandable={{
              expandedRowRender: record => (
                <SD2Table
                  store={table.getSubTable(record)}
                  currencyList={sdPageStore.currencyList}
                  bundlingOptions={sdPageStore.bundlingOptions}
                  canEdit={!record.isCompleted && canEdit}
                />
              ),
              onExpand: (expanded, record) =>
                table.onExpand(expanded, record, sdPageStore.filter, sdPageStore.campaign?.year),
              expandedRowKeys: table.expandedRowKeys,
            }}
          >
            <Table.Column title='BSH Part No' key='bshPartNo' dataIndex='bshPartNo' width='10%' />
            <Table.Column
              title='Designation'
              key='designation'
              dataIndex='designation'
              width='25%'
            />
            <Table.Column title='EMS' key='emsName' dataIndex='emsName' width='5%' />
            <Table.Column title='Commodity' key='commodity' width='25%' render={commodityCell} />
            <Table.Column
              title='Negotiator'
              key='negotiatorNick'
              dataIndex='negotiatorNick'
              width='10%'
            />
            <Table.Column
              title='Demand'
              key='demand'
              dataIndex='demand'
              width='10%'
              align='left'
              render={value => numberFormatter(value)}
              onCell={editableCellHandler<SDRow>({
                store: table,
                keyField: 'demandId',
                isEditable: ({ actions }) => actions.edit?.allow,
                field: 'demand',
                component: NestedInputNumber,
                extraProps: {
                  min: 0,
                  formatter: floatInputFormatter,
                },
              })}
            />
            <Table.Column
              title='Bundling'
              key='bundling'
              dataIndex='bundlingName'
              width='9%'
              onCell={editableCellHandler<SDRow>({
                store: table,
                keyField: 'demandId',
                isEditable: ({ actions }) => actions.edit?.allow,
                field: 'bundlingName',
                component: NestedSelect,
                preSave: setBundlingId,
                extraProps: {
                  options: bundlingOptions,
                  allowClear: true,
                },
              })}
            />
            <Table.Column
              title='Demand last year'
              key='prevDemand'
              dataIndex='prevDemand'
              width='10%'
              align='right'
              render={value => numberFormatter(value)}
            />

            <Table.Column
              title='Projects'
              dataIndex='projects'
              key='projects'
              width='6.5%'
              ellipsis
              render={(value: Project[]) => (value ? value.map(({ name }) => name).join(', ') : '')}
            />

            <Table.Column
              title=''
              key='actions'
              width='12%'
              align='center'
              render={({ actions, demandId }: SDRow) => {
                const { completed, edit } = parseCompletePermissions(actions);

                return (
                  <>
                    <PermissionCheck
                      permission={completed}
                      type='link'
                      onClick={() => sdPageStore.onComplete(demandId, true)}
                    >
                      <Button>Complete SD</Button>
                    </PermissionCheck>
                    <PermissionCheck
                      permission={edit}
                      type='link'
                      onClick={() => sdPageStore.onComplete(demandId, false)}
                    >
                      <Button>Edit SD</Button>
                    </PermissionCheck>
                  </>
                );
              }}
            />
          </Table>
        </Layout.Content>
        <Affix>
          <Layout.Sider
            collapsible
            collapsedWidth={0}
            trigger={null}
            collapsed={!sdPageStore.filter.isOpen}
            width={300}
          >
            <ApnFilter store={sdPageStore.filter} />
          </Layout.Sider>
        </Affix>
          </Layout>
          <LoaExport store={loaExportStore} storeMail={loaExportMailStore} /> 
    </LoaderBox>
  );
});

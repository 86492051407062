import { Rule } from 'antd/es/form';

export const formLayout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
export const formFooterLayout = {
  wrapperCol: { offset: 8, span: 16 },
  style: { textAlign: 'right' as const, marginBottom: 0 },
};
export const requiredRule: Rule = { required: true, message: 'Required' };
export const integerOnlyRule: Rule = { pattern: /^\d*$/, message: 'Integer only' };
export const priceRule: Rule = { pattern: /^\d*(?:\.\d*)?$/, message: 'Numbers only' };

import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Collapse, Form, Input, Select, Space } from 'antd';
import { ComponentPriceListRightFilter } from './ComponentPriceListPage.types';
import { EmsSelect } from '../../components/ems-select/ems-select';
import { ManufacturerSelect } from '../../components/ManufacturerSelect';
import { ComponentPriceListPageStore } from './ComponentPriceListPageStore';
import { getYears } from '../../api/cached/getYears';
import { BundlingSelect } from '../../components/ApnFilter/components/BundlingSelect';
const { Panel } = Collapse;

interface PropsDemandFilterForm {
  store: ComponentPriceListPageStore;
}
interface YearItem {
  yearId: number;
  name: string;
}

export const ComponentPriceListFilterForm: React.FC<PropsDemandFilterForm> = observer(
  ({ store }) => {
    const [form] = Form.useForm<ComponentPriceListRightFilter>();
    const [options, setOptions] = React.useState<YearItem[]>();
    React.useEffect(() => {
      getYears().then(setOptions);
    }, []);
    const onSubmit = (values: ComponentPriceListRightFilter) => {
      console.log('DemandFilterForm>Submit', values);
      store.table.load({ page: 0, ...values });
    };
    const onClear = () => {
      form.resetFields();
      const values = form.getFieldsValue();
      onSubmit(values);
    };
    return (
      <Form<ComponentPriceListRightFilter>
        layout='vertical'
        form={form}
        onFinish={onSubmit}
        className='demand-filter-panel'
      >
        <Collapse defaultActiveKey={['suppliers']}>
          <Panel header='Suppliers' key='suppliers'>
            <Form.Item label='EMS' name='EmsCode'>
              <EmsSelect />
            </Form.Item>
            <ManufacturerSelect name='ManufacturerCode' label='Manufacturer' />
            <Form.Item label='Price Type' name='priceType'>
              <Select
                options={[
                  { value: 'APN', label: 'APN' },
                  { value: 'EMS', label: 'EMS' },
                  { value: 'Offer', label: 'Offer' },
                  { value: 'Estimate', label: 'Estimate' },
                ]}
              />
            </Form.Item>
            <Form.Item label='Year' name='year'>
              <Select allowClear>
                {options &&
                  options.map(rec => (
                    <Select.Option key={rec.yearId} value={rec.name}>
                      {rec.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <BundlingSelect />
            <Form.Item name='IsApnFinalized' valuePropName='checked'>
              <Checkbox>APN finalized?</Checkbox>
            </Form.Item>
          </Panel>
          <Panel header='Parts' key='parts'>
            <Form.Item label='BSH Part No' name='BshPartNo'>
              <Input placeholder='BSH Part No' allowClear />
            </Form.Item>

            <Form.Item label='Order Code' name='OrderCode'>
              <Input placeholder='Order Code' allowClear />
            </Form.Item>

            <Form.Item label='Designation' name='Designation'>
              <Input placeholder='Designation' allowClear />
            </Form.Item>
          </Panel>
          <Panel header='Commodity' key='commodity'>
            <Form.Item label='Commodity Code (e.g. P1111)' name='CommodityCode'>
              <Input placeholder='Commodity Code' allowClear maxLength={5} />
            </Form.Item>

            <Form.Item label='Commodity Name (e.g. Driver IC)' name='CommodityName'>
              <Input placeholder='Commodity Name' allowClear />
            </Form.Item>
            <Form.Item label='GPMT (e.g. ANA)' name='CommodityGpmt'>
              <Input placeholder='GPMT' allowClear />
            </Form.Item>
          </Panel>
        </Collapse>
        <Form.Item>
          <Space>
            <Button type='primary' htmlType='submit' loading={store.table.isLoading}>
              Apply
            </Button>
            <Button onClick={onClear}>Clear filters</Button>
          </Space>
        </Form.Item>
      </Form>
    );
  }
);

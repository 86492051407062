import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'antd';
import { LoaderBox } from '../../components/LoaderBox';
import { BomPageStore } from './BomPageStore';
import { ExportBomModal, msgExportBom } from './ExportBom';
import { TableUploaderModal } from '../../components/TableUploader';
import { ButtonsPageContent } from '../../components/ButtonsPageContent';
import './BomPage.less';

const pageStore = new BomPageStore();

export const BomPage: React.FC = observer(() => {
  const [isImportOpen, setImportOpen] = React.useState(false);
  React.useEffect(() => {
    pageStore.init();
  }, []);

  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>BOM/AVL</h1>
        </Col>
      </Row>
      <ButtonsPageContent
        buttons={[
          {
            text: msgExportBom,
            permission: true,
            onClick: () => pageStore.exportBom.activate(),
          },
          //{ text: 'Import EMS price data', permission: true, onClick: () => setImportOpen(true) },
        ]}
      />
      <ExportBomModal store={pageStore} />
      <TableUploaderModal
        visible={isImportOpen}
        title='Import EMS price data'
        importType='EmsPrice'
        onClose={() => setImportOpen(false)}
        store={null}
      />
    </LoaderBox>
  );
});

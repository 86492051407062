import React, { FunctionComponent, useCallback, useState } from 'react';
import { Button, Divider, Form, Input, Modal } from 'antd';
import { MYARow } from '../MYA.types';
import { OrderCodeTable } from './OrderCodesTable';
import { makeStrParams, Rest } from '../../../api/Rest';
import { OrderCodeFilter, OrderCodeRow } from './OrderCodes.types';
import { drawErrorMessage } from '../../../common/drawErrorMessage';

export interface OrderCodeListProps {
  mya: MYARow | null;
  onFinish: () => void;
}

const loadOrderCodes = (id: number): Promise<OrderCodeRow[]> =>
  Rest.get<{ data: OrderCodeRow[] }>(`/api/v1/Mya/${id}/codes`).then(({ data }) => data);

const addOrderCode = (id: number, code: number): Promise<void> =>
  Rest.put(`/api/v1/Mya/${id}/codes/${code}`);

const removeOrderCode = (id: number, row: OrderCodeRow): Promise<void> =>
  Rest.del(`/api/v1/Mya/${id}/codes/${row.bshPartNo}/${row.orderCode}`);

const searchOrderCodes = (
  id: number,
  { BshPartNo, OrderCode }: OrderCodeFilter
): Promise<OrderCodeRow[]> => {
  const params = makeStrParams({
    ...(BshPartNo ? { BshPartNo } : []),
    ...(OrderCode ? { OrderCode } : []),
  });
  return Rest.get<{ data: OrderCodeRow[] }>(`/api/v1/Manufacturer/${id}/codes`, params).then(
    ({ data }) => data
  );
};

export const OrderCodeList: FunctionComponent<OrderCodeListProps> = ({ onFinish, mya }) => {
  const [form] = Form.useForm();
  const [orderCodes, setOrderCodes] = useState<OrderCodeRow[]>([]);
  const [availableOrderCodes, setAvailableOrderCodes] = useState<OrderCodeRow[]>([]);
  const [loading, setLoading] = useState(false);
  const { manufacturerId, myaId, actions } = mya || {};
  const canEdit = actions?.edit;

  const onSearch = (values: OrderCodeFilter) => {
    if (manufacturerId) {
      searchOrderCodes(manufacturerId, values).then(result => {
        setAvailableOrderCodes(result);
      });
    }
  };

  const onAdd = ({ edmId }: OrderCodeRow) => {
    const isAdded = orderCodes.some(code => code.edmId === edmId);
    if (myaId && !isAdded) {
      addOrderCode(myaId, edmId)
        .then(() => {
          updateOrderCodes();
        })
        .catch(e => drawErrorMessage(e, "Can't add order code"));
    }
  };

  const onRemove = (row: OrderCodeRow) => {
    if (myaId) {
      removeOrderCode(myaId, row)
        .then(() => {
          updateOrderCodes();
        })
        .catch(e => drawErrorMessage(e, "Can't delete order code"));
    }
  };

  const updateOrderCodes = useCallback(() => {
    if (myaId) {
      setLoading(true);
      loadOrderCodes(myaId)
        .then(result => {
          setOrderCodes(result);
        })
        .catch(e => drawErrorMessage(e, "Can't load order codes"))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [myaId]);

  React.useEffect(() => {
    form.resetFields();
    setAvailableOrderCodes([]);
    updateOrderCodes();
  }, [updateOrderCodes, form]);

  return (
    <Modal visible={Boolean(mya)} title='Order Codes' onCancel={onFinish} footer={null}>
      <OrderCodeTable
        loading={loading}
        actionName='Delete'
        onAction={onRemove}
        orderCodes={orderCodes}
        canEdit={canEdit}
      />
      {canEdit && (
        <>
          <Divider orientation='left'>Search</Divider>
          <Form name='order-code-search' layout='inline' onFinish={onSearch} form={form}>
            <Form.Item name='OrderCode'>
              <Input placeholder='by Order code' />
            </Form.Item>
            <Form.Item name='BshPartNo'>
              <Input placeholder='by BSH Part No' />
            </Form.Item>
            <Form.Item>
              <Form.Item noStyle shouldUpdate>
                {() => {
                  const { BshPartNo, OrderCode } = form.getFieldsValue();
                  return (
                    <Button
                      type='primary'
                      htmlType='submit'
                      disabled={(!BshPartNo && !OrderCode) || loading}
                    >
                      Find
                    </Button>
                  );
                }}
              </Form.Item>
            </Form.Item>
          </Form>
          <Divider />
          <OrderCodeTable
            canEdit={canEdit}
            loading={loading}
            orderCodes={availableOrderCodes}
            actionName='Add'
            onAction={onAdd}
          />
        </>
      )}
    </Modal>
  );
};

import React, { FunctionComponent, useState } from 'react';
import { Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Rest } from '../../api/Rest';
import { QuotaIncreasedPriceRow } from './QuotaIncreasedPrice.types';
import { QuotaIncreasedPrice } from './QuotaIncreasedPrice';
import { delay } from '../../utils/delay';

interface QuotaIncreasedPriceProps {
  quotaId: number;
}

const loadSessions = async (id: number) => {
  return Rest.get<{ data: QuotaIncreasedPriceRow[] }>(
    `/api/v1/apn/Quotation/${id}/increasedprice`
  ).then(({ data }) => data);
};

export const QuotaIncreasedPrices: FunctionComponent<QuotaIncreasedPriceProps> = ({ quotaId }) => {
  const [sessions, setSessions] = useState<QuotaIncreasedPriceRow[] | null>(null);
  const [loading, setLoading] = useState(false);
  const handleMouseOver = async () => {
    if (sessions || loading) return;

    setLoading(true);
    await delay(200);
    const result = await loadSessions(quotaId);
    setSessions(result);
    setLoading(false);
  };

  return (
    <Popover
      placement='right'
      content={<QuotaIncreasedPrice loading={loading} sessions={sessions} />}
    >
      <ExclamationCircleOutlined onMouseOver={handleMouseOver} />
    </Popover>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { MatrixBasisQuotaStore } from './MatrixBasisQuotaStore';
import { DQRow } from './MatrixBasisQuota.types';
import './MatrixBasisQuota.less';
import { MatrixBasisQuotaActions } from './MatrixBasisQuotaActions';
import { MatrixBasisQuotaEdit } from './MatrixBasisQuotaEdit';
import { formatConvertedPriceEuro } from '../../../common/apn';
import moment from 'moment';
import { defaultCurrencyStore } from '../DefaultCurrencyStore';

interface PropsDemandQuotaTable {
  store?: MatrixBasisQuotaStore;
}

export const MatrixBasisQuotaTable: React.FC<PropsDemandQuotaTable> = observer(({ store }) => {
  if (!store) return null;
  const { defaultCurrency } = defaultCurrencyStore;

  const rowClassName = () => {
    return store.isLocked ? 'demand-quota-row-locked' : '';
  };
  /*    const localCellHandler = (params: RequestForQuotationCellParams) =>
            editableCellHandler<RequestForQuotationRow>({
                store: table,
                keyField: 'quotaId',
                isEditable: ({ isCompleted }) => canEdit && !isCompleted,
                ...params,
            });*/
  return (
    <>
      <Table<DQRow>
        dataSource={store.data}
        loading={store.isLoading}
        size='small'
        pagination={store.paginator}
        onChange={store.onTableChange}
        rowKey='quotaId'
        rowClassName={rowClassName}
        className='demand-quota-table'
      >
        <Table.Column
          title='Manufacturer'
          dataIndex='manufacturerName'
          key='manufacturerName'
          width='25%'
          ellipsis
        />
        <Table.Column
          title='Order code'
          dataIndex='orderCode'
          key='orderCode'
          width='20%'
          ellipsis
        />
        <Table.Column
          title={'Min Price ' + defaultCurrency}
          key='minPrice'
          dataIndex='minPrice'
          width='7%'
          render={(_, record: DQRow) => {
            if (
              record.priceType === 'EMS' ||
              record.priceType === 'Estimate' ||
              record.priceType === 'Offer'
            ) {
              return 'n/a';
            } else {
              return formatConvertedPriceEuro(record.minPrice);
            }
          }}
        />
        <Table.Column
          title={'Max Price ' + defaultCurrency}
          key='maxPrice'
          dataIndex='maxPrice'
          width='7%'
          render={(_, record: DQRow) => {
            if (
              record.priceType === 'EMS' ||
              record.priceType === 'Estimate' ||
              record.priceType === 'Offer'
            ) {
              return 'n/a';
            } else {
              return formatConvertedPriceEuro(record.maxPrice);
            }
          }}
        />
        <Table.Column
          title={'Avg Price ' + defaultCurrency}
          key='avgPrice'
          dataIndex='avgPrice'
          width='7%'
          render={(_, record: DQRow) => {
            if (
              record.priceType === 'EMS' ||
              record.priceType === 'Estimate' ||
              record.priceType === 'Offer'
            ) {
              return 'n/a';
            } else {
              return formatConvertedPriceEuro(record.avgPrice);
            }
          }}
        />
        <Table.Column
          title={'Weighted Price ' + defaultCurrency}
          key='weightedPrice'
          dataIndex='weightedPrice'
          width='10%'
          render={(_, record: DQRow) => {
            if (record.priceType === 'EMS' || record.priceType === 'Estimate') {
              return 'n/a';
            } else {
              return formatConvertedPriceEuro(record.weightedPrice);
            }
          }}
        />
        <Table.Column title='Comment' dataIndex='comment' key='comment' width='22%' ellipsis />
        <Table.Column
          title='Links'
          dataIndex='myaLink'
          key='myaLink'
          width='13%'
          ellipsis
          render={val =>
            val ? (
              <a href={val} target='_blank' rel='noreferrer'>
                PDF
              </a>
            ) : (
              ''
            )
          }
        />
      </Table>
      <MatrixBasisQuotaEdit store={store} />
    </>
  );
});

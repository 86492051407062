import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Table, Button } from 'antd';
import { ComponentRow } from './ComponentPage.types';
import { ComponentPageStore } from './ComponentPage.store';
import { LoaderBox } from '../../components/LoaderBox';
import { ComponentEdit } from './ComponentEdit';
import { PermissionCheck } from '../../components/PermissionCheck/PermissionCheck';
import { ComponentMenu } from './ComponentMenu';
const { Column } = Table;

const pageStore = new ComponentPageStore();

export const ComponentPage: React.FC = observer(() => {
  const { table, editPermission } = pageStore;
  React.useEffect(() => pageStore.init(), []);
  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>Components</h1>
        </Col>
        <Col>
          <ComponentMenu store={pageStore} />
        </Col>
      </Row>
      <Table<ComponentRow>
        size='small'
        rowKey='bshPartNo'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
      >
        <Column title='BSH Part No' key='bshPartNo' dataIndex='bshPartNo' />
        <Column title='Designation' key='designation' dataIndex='designation' />
        <Column title='Commodity Code' key='commodityCode' dataIndex='commodityCode' />
        <Column title='Commodity Name' key='commodityNameEng' dataIndex='commodityNameEng' />
        {editPermission && (
          <Column
            title='Actions'
            key='actions'
            render={(_value, _record, index) => (
              <PermissionCheck
                permission={editPermission}
                type='link'
                name='edit'
                onClick={() => table.onEditStart(index)}
              >
                <Button>Edit</Button>
              </PermissionCheck>
            )}
          />
        )}
      </Table>
      <ComponentEdit formStore={table.form} />
    </LoaderBox>
  );
});

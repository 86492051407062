import React, { FunctionComponent } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './tools-menu.module.less';

interface ToolsMenuProps {
  disabled?: boolean;
}

export const ToolsMenu: FunctionComponent<ToolsMenuProps> = ({ disabled = false, children }) => {
  return (
    <Dropdown overlay={<Menu className={styles.menu}>{children}</Menu>} disabled={disabled}>
      <Button type='link'>
        Tools <DownOutlined />
      </Button>
    </Dropdown>
  );
};

import { action, makeObservable, observable } from 'mobx';
import { Rest } from '../../api/Rest';

interface ExportStatusResponse {
  fileKey: string;
  templateName: string;
  type: number;
  statusId: number;
  statusName: string;
}

interface DownloadResponse {
  url: string;
}

export type ExportStatus = 'none' | 'wait' | 'error' | 'ready';

export class TableExportStore {
  private readonly getExportKey: () => Promise<string>;
  private readonly onClose: () => void;
  status: ExportStatus = 'none';
  lastError?: Error;
  url?: string;

  constructor(getExportKey: () => Promise<string>, onClose: () => void) {
    this.getExportKey = getExportKey;
    this.onClose = onClose;

    makeObservable(this, {
      status: observable,
      lastError: observable,
      url: observable,
      start: action,
      setStatus: action,
      close: action,
    });
  }

  close() {
    this.setStatus('none');
    this.onClose();
  }

  private get isCancel(): boolean {
    return this.status !== 'wait';
  }

  private pooling(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const iteration = () => {
        if (this.isCancel) {
          resolve();
          return;
        }
        Rest.get<ExportStatusResponse>(url)
          .then(res => {
            if (res.statusName === 'Failed') {
              reject(new Error(res.statusName));
            } else if (res.statusName === 'Completed') {
              resolve();
            } else {
              setTimeout(iteration, 1000);
            }
          })
          .catch(e => resolve(e));
      };
      iteration();
    });
  }

  /**
   * @return url for constracting download link (as href attribute)
   */
  async start(): Promise<string> {
    try {
      this.setStatus('wait');
      const exportKey = await this.getExportKey();
      if (this.isCancel) return '';
      await this.pooling(`/api/v1/export/${exportKey}`);
      if (this.isCancel) return '';
      const { url } = await Rest.get<DownloadResponse>(`/api/v1/export/${exportKey}/download`);
      this.setStatus('ready', { url });
      return url;
    } catch (err) {
      this.setStatus('error', { err });
      return Promise.reject(err);
    }
  }

  setStatus(newStatus: ExportStatus, props: { err?: Error; url?: string } = {}) {
    this.status = newStatus;
    this.lastError = props.err;
    this.url = props.url;
  }
}

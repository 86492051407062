import * as React from 'react';
import { observer } from 'mobx-react';
import { LoaderBox } from '../../components/LoaderBox';
import { ReportsPageStore } from './ReportsPageStore';
import { TableExportModal } from '../../components/TableExport';
import { ButtonsPageContent } from '../../components/ButtonsPageContent';
import './ReportsPage.less';

const pageStore = new ReportsPageStore();
const exportTitle = 'Single Source 2-Tier Suppliers';
const exportWithComponentsTitle = 'Single Source 2-Tier Suppliers with Components';

export const ReportsPage: React.FC = observer(() => {
  const [mode, setMode] = React.useState<'' | 'SingleComponents' | 'PreSuppliers'>('');
  const { actions } = pageStore;
  React.useEffect(() => pageStore.init(), []);
  return (
    <LoaderBox store={pageStore}>
      <div className='reports-page'>
        <h1>Reports</h1>
        <ButtonsPageContent
          buttons={[
            {
              text: exportWithComponentsTitle,
              permission: actions?.exportpresupplierreportwithcomponent,
              onClick: () => setMode('SingleComponents'),
            },
            {
              text: exportTitle,
              permission: actions?.exportpresupplierreport,
              onClick: () => setMode('PreSuppliers'),
            },
          ]}
        />
      </div>
      <TableExportModal
        visible={mode === 'SingleComponents'}
        title={exportWithComponentsTitle}
        onClose={() => setMode('')}
        getExportKey={() => pageStore.getExportKey('PreSupplierReportWithComponent')}
      />
      <TableExportModal
        visible={mode === 'PreSuppliers'}
        title={exportTitle}
        onClose={() => setMode('')}
        getExportKey={() => pageStore.getExportKey('PreSupplierReport')}
      />
    </LoaderBox>
  );
});

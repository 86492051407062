import * as React from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { Rest } from '../api/Rest';
import { icmp } from '../common/icmp';

interface ManufacturerItem {
    manufacturerId: number;
    name: string;
    code: string;
    edmCode: string;
}

interface PropsManufacturerSelect {
    name: string;
    label?: string;
    valueField?: keyof ManufacturerItem;
    rules?: Rule[];
}

export const ManufacturerSelect: React.FC<PropsManufacturerSelect> = ({
    name,
    label = 'Manufacturer',
    valueField = 'code',
    rules,
}) => {
    const [options, setOptions] = React.useState<ManufacturerItem[] | null>(null);
    React.useEffect(() => {
        Rest.get<ManufacturerItem[]>('/api/v1/Manufacturer/filters').then(res => {
            res.sort((a, b) => icmp(a.name, b.name));
            setOptions(res);
        });
    }, []);
    return (
        <Form.Item label={label} name={name} rules={rules}>
            <Select
                allowClear
                loading={options === null}
                showSearch
                filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(a, b) => icmp(a.children, b.children)}
                placeholder="Start typing to find Manufacturer"
            >
                {options &&
                    options.map(it => (
                        <Select.Option key={it.manufacturerId} value={it[valueField]}>
                            {it.name}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
    );
};
import * as React from 'react';
import { observer } from 'mobx-react';
import { MatrixBasisPageStore } from './MatrixBasisPageStore';
import { LoaderBox } from '../../components/LoaderBox';
import { Button, Col, Row, Layout, Affix } from 'antd';
import { CurrentCampaignTitle } from '../../components/CurrentCampaignTitle';
import { FilterOutlined } from '@ant-design/icons';
import { MatrixBasisTable } from './MatrixBasisTable';
import { MatrixBasisTools } from './MatrixBasisTools';
import { MatrixBasisAddNewLine } from './MatrixBasisAddNewLine';
import { MatrixBasisFilterForm } from './MatrixBasisFilterForm';
import { MatrixBasisActions } from './MatrixBasisActions';

const pageStore = new MatrixBasisPageStore();

export const MatrixBasisPage: React.FC = observer(() => {
  React.useEffect(() => {
    pageStore.init();
  }, []);

  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>
            Matrix Basis <CurrentCampaignTitle />
          </h1>
        </Col>
        <Col>
          <MatrixBasisTools store={pageStore} />
          <Button
            onClick={() => pageStore.toggleFilter()}
            icon={<FilterOutlined />}
            title='Filters'
            type={pageStore.isFilterUsed ? 'primary' : 'default'}
          />
        </Col>
      </Row>
      <Layout>
        <Layout.Content>
          <MatrixBasisTable store={pageStore} />
        </Layout.Content>
        <Affix>
          <Layout.Sider
            collapsible
            collapsedWidth={0}
            trigger={null}
            collapsed={!pageStore.isFilterOpen}
            width={300}
          >
            <MatrixBasisFilterForm store={pageStore} />
          </Layout.Sider>
        </Affix>
      </Layout>
      <MatrixBasisAddNewLine store={pageStore} />
    </LoaderBox>
  );
});

/** Cache for /api/v1/Settings/currency */

import { Rest } from '../Rest';
import { LabelValue } from '../../common/types';

export interface CurrencyInfo {
  currencyId: number;
  name: string;
}

interface CurrencyResponse {
  data: CurrencyInfo[];
}

let cache: CurrencyInfo[] | null = null;
let options: LabelValue[] | null = null;

export const getCurrency = async (): Promise<CurrencyInfo[]> => {
  if (!cache) {
    const response = await Rest.get<CurrencyResponse>('/api/v1/Settings/currency');
    cache = response.data;
  }
  return cache;
};

export const getCurrencyOptions = async (): Promise<LabelValue[]> => {
  return options
    ? options
    : await getCurrency().then(list =>
        list.map(({ name, currencyId }) => ({ label: name, value: name, id: currencyId }))
      );
};

import { PaginationMeta, RequestState, RequestStateWithMeta } from './state-types';
import { RequestStatuses } from './request-constants';

export function getRequestInitialState<D>(data: D): RequestState<D> {
  return {
    data,
    status: RequestStatuses.init,
  };
}

export function getRequestInitialStateWithMeta<D, M>(data: D, meta: M): RequestStateWithMeta<D, M> {
  return {
    data,
    meta,
    status: RequestStatuses.init,
  };
}

const defaultPage = 1;
const defaultPageSize = 10;

export function getRequestInitialStateWithPagination<D>(data: D) {
  return getRequestInitialStateWithMeta<D, PaginationMeta>(data, {
    totalCount: 0,
    page: defaultPage,
    pageSize: defaultPageSize,
  });
}

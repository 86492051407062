import React, { FunctionComponent, useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
interface FreeTextProps {
    onChange: (isCorrect: boolean) => void;
    documentType: string | undefined;
    selectedTemplate: string | undefined;

}

export const FreeText: FunctionComponent<FreeTextProps> = ({ onChange, documentType, selectedTemplate }) => {
    const formRef = React.useRef<FormInstance>();
    const maxCharactersRule = { max: 4000, message: 'Free text must be less than 4000 characters' };
    const MAX_CHARACTER_LIMIT = 4000;
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        //  perform any additional logic here if needed
        onChange(!!value);
    };
    useEffect(() => {
        // Re-render the component whenever documentType or selectedTemplate changes
        formRef.current?.resetFields();
    }, [documentType, selectedTemplate]);


    return (
        <>
            {documentType === 'Letter of Authorization' && selectedTemplate === 'Version 1' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2'>
                        <Input onChange={handleChange} autoComplete='off' disabled />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3'>
                        <Input onChange={handleChange} autoComplete='off' disabled />
                    </Form.Item>
                </>
            )}
            {documentType === 'Letter of Authorization' && selectedTemplate === 'Version 2 with 5%' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3'>
                        <Input onChange={handleChange} autoComplete='off' disabled />
                    </Form.Item>
                </>
            )}
            {documentType === 'Letter of Authorization' && selectedTemplate === 'Backup Version' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3'>
                        <Input onChange={handleChange} autoComplete='off' disabled />
                    </Form.Item>
                </>
            )}

            {documentType === 'Agreement on Conditions' && selectedTemplate === 'Version 1' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3'>
                        <Input onChange={handleChange} autoComplete='off' disabled />
                    </Form.Item>
                </>
            )}
            {documentType === 'Agreement on Conditions' && selectedTemplate === 'Version 2 with 5%' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                </>
            )}
            {documentType === 'Agreement on Conditions' && selectedTemplate === 'Backup Version' && (
                <>
                    <Form.Item label='Free text 1' name='freeText1' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 2' name='freeText2' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                    <Form.Item label='Free text 3' name='freeText3' rules={[maxCharactersRule]}>
                        <Input onChange={handleChange} autoComplete='off' />
                    </Form.Item>
                </>
            )}

        </>
    );
};
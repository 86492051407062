import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ComponentPriceListQuotaStore } from './ComponentPriceListQuotaStore';
import { DQRow } from './ComponentPriceListQuota.types';
import './ComponentPriceListQuota.less';
import { ComponentPriceListQuotaActions } from './ComponentPriceListQuotaActions';
import { ComponentPriceListQuotaEdit } from './ComponentPriceListQuotaEdit';
import { formatConvertedPriceEuro } from '../../../common/apn';
import moment from 'moment';
import { defaultCurrencyStore } from '../DefaultCurrencyStore';

interface PropsDemandQuotaTable {
  store?: ComponentPriceListQuotaStore;
}

export const ComponentPriceListQuotaTable: React.FC<PropsDemandQuotaTable> = observer(
  ({ store }) => {
    if (!store) return null;
    const { defaultCurrency } = defaultCurrencyStore;

    const rowClassName = () => {
      return store.isLocked ? 'demand-quota-row-locked' : '';
    };
    /*    const localCellHandler = (params: RequestForQuotationCellParams) =>
            editableCellHandler<RequestForQuotationRow>({
                store: table,
                keyField: 'quotaId',
                isEditable: ({ isCompleted }) => canEdit && !isCompleted,
                ...params,
            });*/
    return (
      <>
        <Table<DQRow>
          dataSource={store.data}
          loading={store.isLoading}
          size='small'
          pagination={store.paginator}
          onChange={store.onTableChange}
          rowKey='quotaId'
          rowClassName={rowClassName}
          className='demand-quota-table'
        >
          <Table.Column
            title='Manufacturer'
            dataIndex='manufacturerName'
            key='manufacturerName'
            width='25%'
            ellipsis
          />
          <Table.Column
            title='Order code'
            dataIndex='orderCode'
            key='orderCode'
            width='20%'
            ellipsis
          />
          <Table.Column
            title='Release status'
            key='releaseStatus'
            width='11%'
            render={({ orderCodeStatusName, updated_on }) => (
              <span style={{ whiteSpace: 'nowrap' }}>
                {orderCodeStatusName ? orderCodeStatusName : ''}{' '}
                {updated_on && orderCodeStatusName ? <br></br> : ''}{' '}
                {updated_on ? moment(updated_on).format('DD.MM.YYYY') : ''}
              </span>
            )}
          />
          <Table.Column
            title='Orig. Price APN/Offer'
            key='origPrice'
            dataIndex='origPrice'
            width='12%'
            render={(_, record: DQRow) => {
              if (record.priceType === 'EMS' || record.priceType === 'Estimate') {
                return 'n/a';
              } else {
                return formatConvertedPriceEuro(record.origPrice);
              }
            }}
          />
          <Table.Column
            title='Orig. Currency'
            key='origCurrency'
            dataIndex='origCurrency'
            width='7%'
          />
          <Table.Column
            title={'Price in ' + defaultCurrency}
            key='price'
            dataIndex='price'
            width='7%'
            render={(_, record: DQRow) => {
              if (record.priceType === 'EMS' || record.priceType === 'Estimate') {
                return 'n/a';
              } else {
                return formatConvertedPriceEuro(record.price);
              }
            }}
          />
          <Table.Column
            title='Quota'
            key='quota'
            dataIndex='quota'
            width='8%'
            render={(_, record: DQRow) => {
              if (record.quota === null || record.quota === '' || record.quota === 'n/a') {
                return record.quota;
              } else {
                return record.quota + ' %';
              }
            }}
          />
          <Table.Column
            title='Distributor'
            dataIndex='distributor'
            key='distributor'
            width='12.5%'
          />
          <Table.Column title='LogOn Addon' dataIndex='logOnAddon' key='logOnAddon' width='10%' />
          <Table.Column
            title='Incoterm Code'
            dataIndex='incotermName'
            key='incotermName'
            width='10%'
          />
          <Table.Column
            title='Incoterm Location'
            dataIndex='incotermDirection'
            key='incotermDirection'
            width='12%'
          />
          <Table.Column title='Package' dataIndex='package' key='package' width='7%' />
          <Table.Column
            title='Lead Time [wks]'
            dataIndex='leadTime'
            key='leadTime'
            width='10%'
            render={(_, record: DQRow) => {
              if (record.priceType === 'EMS' || record.priceType === 'Estimate') {
                return 'n/a';
              } else {
                return record.leadTime;
              }
            }}
          />
          <Table.Column title='Comment' dataIndex='comment' key='comment' width='22%' ellipsis />
          <Table.Column
            title='Links'
            dataIndex='myaLink'
            key='myaLink'
            width='13%'
            ellipsis
            render={val =>
              val ? (
                <a href={val} target='_blank' rel='noreferrer'>
                  PDF
                </a>
              ) : (
                ''
              )
            }
          />

          <Table.Column
            title='Actions'
            key='actions'
            width='13%'
            render={row => <ComponentPriceListQuotaActions row={row} store={store} />}
          />
        </Table>
        <ComponentPriceListQuotaEdit store={store} />
      </>
    );
  }
);

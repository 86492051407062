import * as React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import { ComponentRow } from './ComponentPage.types';
import { ModalForm } from '../../components/ModalForm';
import { FormStore } from '../../common/FormStore';
import { SelectCommodity } from './SelectCommodity';
import { Rule } from 'antd/es/form';

const commodityCodeRules: Rule[] = [
  {
    validator: (_, value) =>
      value === undefined ? Promise.reject('Commodity code is not selected') : Promise.resolve(),
  },
];

interface PropsComponentEdit {
  formStore: FormStore<ComponentRow>;
}

export const ComponentEdit: React.FC<PropsComponentEdit> = observer(({ formStore }) => {
  const { visible, data } = formStore;
  const [commodity, setCommodity] = React.useState<Partial<ComponentRow> | undefined>(undefined);
  React.useEffect(() => {
    if (visible) {
      setCommodity(data);
    }
  }, [visible, data]);

  const onCommodityChange = (fields?: Partial<ComponentRow>) => {
    setCommodity(fields);
  };

  const onSave = (newData: Partial<ComponentRow>) => {
    const dataExt = { ...newData, ...commodity, bshPartNo: data.bshPartNo };
    return formStore.save(dataExt);
  };
  const formDisabled = formStore.saving;

  return (
    <ModalForm
      title='Assign Commodity'
      name='component'
      layout={{ layout: 'vertical' }}
      visible={visible}
      saving={formStore.saving}
      onClose={formStore.close}
      onSave={onSave}
      initialData={data}
    >
      <>
        <Form.Item name='commodityCode' label='Commodity Code' rules={commodityCodeRules}>
          <SelectCommodity
            initialValue={data}
            onChange={onCommodityChange}
            disabled={formDisabled}
          />
        </Form.Item>
      </>
    </ModalForm>
  );
});

import { FnDataLoad, TableStore } from '../../../common/TableStore';
import { SDFilter, SDRow } from '../ShareDistribution.types';
import { SD2Row, SD2Filter } from './SD2Table.types';
import { getRows } from '../../../api/getRows';
import { Rest } from '../../../api/Rest';
import { updateApnRow, updateShareDistributionRow } from '../../../common/apn/updateApnRow';
import { FnFormSave, FormStore } from '../../../common/FormStore';
import { drawErrorMessage } from '../../../common/drawErrorMessage';
import { ApnFilterStore } from '../../../components/ApnFilter/ApnFilterStore';

/* useful stub for test purposes
import { delay } from '../../../utils/delay';
const loadSD2test: FnDataLoad<SD2Row, SD2Filter> = (params) => {
  const list: SD2Row[] = Array.from(new Array(25)).map((_, i) => ({
    quotaId: i+1,
    demandId: 1,
    manufacturerId: 1,
    manufacturerName: `Manuf name ${i}`,
    manufacturerCode: `Manuf code ${i}`,
    orderCode: `order ${i}`,
    bshPartNo: `bsh ${i}`,
    commodityCode: `commodity code ${i}`,
    commodityGpmt: `commodityGpmt${i}`,
    commodityName: `commodityName${i}`,
    demand: i*10,
    price: i*22,
    currencyId: null,
    currencyCode: null,
    convertedPriceEuro: null,
    quota: null,
    comment: null,
    emsCode: null,
    emsComment: null,
    emsName: null,
    negotiatorComment: null,
    manufacturerComment: null,
    bundlingId: null,
    bundlingName: null,
    previousQuotaId: null,
    isCompleted: i % 4 === 0,
    myaComments: null,
    myaSubject: null,
    myaLink: null,
    orderCodeStatusId: null,
    negotiatorId: null,
    negotiatorName: null,
    previousCurrencyId: null,
    pvEuro: null,
    previousQuota: null,
    previousPrice: null,
    previousConvertedPriceEuro: null,
    previousCurrencyCode: null,
    previousIstEuro: null,
    previousMixedPrice: null,
    previousPvEuro: null,
    mpvEuro: null,
    mpvPercent: null,
    orderCodeStatusName: null,
  }));
  const ofs = params.page * params.pageSize;
  const res = list.slice(ofs, ofs + params.pageSize);
  return delay(1000).then(() => ({data: res, total: list.length}));
}
*/

const loadSD2 = (
  parentRow: SDRow,
  parentFilter: ApnFilterStore<SDRow, SDFilter>
): FnDataLoad<SD2Row, SD2Filter> => {
  return srcParams => {
    let components = [];

    if (
      parentFilter.table.filter.QuotaIsZero !== undefined &&
      parentFilter.table.filter.QuotaIsZero != false
    ) {
      components.push('QuotaIsZero');
    }
    if (
      parentFilter.table.filter.QuotaBetweenZeroand100 !== undefined &&
      parentFilter.table.filter.QuotaBetweenZeroand100 != false
    ) {
      components.push('QuotaBetweenZeroand100');
    }
    if (
      parentFilter.table.filter.SumOfQuotaGreaterThan100 !== undefined &&
      parentFilter.table.filter.SumOfQuotaGreaterThan100 != false
    ) {
      components.push('SumOfQuotaGreaterThan100');
    }
    if (
      parentFilter.table.filter.PriceIsZeroAndQuotaGreaterThanZero !== undefined &&
      parentFilter.table.filter.PriceIsZeroAndQuotaGreaterThanZero != false
    ) {
      components.push('PriceIsZeroAndQuotaGreaterThanZero');
    }
    if (
      parentFilter.table.filter.PriceIsZeroAndQuotaIsZero !== undefined &&
      parentFilter.table.filter.PriceIsZeroAndQuotaIsZero != false
    ) {
      components.push('PriceIsZeroAndQuotaIsZero');
    }
    if (
      parentFilter.table.filter.ShowHiddenItemsNegotiator !== undefined &&
      parentFilter.table.filter.ShowHiddenItemsNegotiator != false
    ) {
      components.push('ShowHiddenItemsNegotiator');
    }

    const dstParams = { ...srcParams, demandId: parentRow.demandId, components: components };
    return getRows('/api/v1/apn/Quotation', dstParams);
  };
};

const saveSD2 = (parentRow: SDRow, rowPart: Partial<SD2Row>): Promise<void> => {
  const data = { ...rowPart };
  delete data.quotaId;
  return Rest.patch<void, typeof rowPart>(`/api/v1/apn/Quotation/${rowPart.quotaId}`, data);
};

const saveDistribution = (rowPart: Partial<SD2Row>): Promise<void> => {
  const data = { ...rowPart };
  delete data.quotaId;
  return Rest.patch<void, typeof rowPart>(`/api/v1/apn/Distribution/${rowPart.quotaId}`, {
    ...rowPart,
    demand: rowPart.demand,
    manufacturerCode: rowPart.manufacturerCode,
    quotaId: rowPart.quotaId,
  });
};

const saveDQ: FnFormSave<Partial<SD2Row>> = (oldData, newData) =>
  Rest.patch(`/api/v1/apn/Distribution/${oldData.quotaId}`, newData);

export class SD2TableStore extends TableStore<SD2Row, SD2Filter> {
  currentYear?: number;
  parentRow: SDRow;
  lockProcess = new Set<number>();
  editForm: FormStore<SD2Row>;
  parentfilter: ApnFilterStore<SDRow, SDFilter>;

  constructor(
    parentRow: SDRow,
    parentFilter: ApnFilterStore<SDRow, SDFilter>,
    currentYear?: number
  ) {
    super({ page: 0, pageSize: 10 }, loadSD2(parentRow, parentFilter));
    this.parentRow = parentRow;
    this.parentfilter = parentFilter;
    this.currentYear = currentYear;
    this.paginationMode = 'hideSinglePage';
    this.defaultOptions = { notScrollTop: true };

    const onEditOk = () => {
      this.reload();
    };
    this.editForm = new FormStore<SD2Row>(saveDQ, onEditOk);
  }
  // override method of parent class to prevent of scroll
  init() {
    this.reload({ notScrollTop: true });
  }
  save(rowPart: Partial<SD2Row>): Promise<void> {
    const res =
      'demand' in rowPart
        ? saveDistribution(rowPart).then(() => updateShareDistributionRow(rowPart, this))
        : saveSD2(this.parentRow, rowPart).then(() => updateApnRow(rowPart, this));
    return res;
  }

  saveHideAndShow(rowPart: Partial<SD2Row>): Promise<void> {
    return saveDistribution(rowPart).then(() => updateShareDistributionRow(rowPart, this));
  }

  hide(row: SD2Row) {
    row.hide = true;
    this.saveHideAndShow(row);
    this.reload();
  }

  show(row: SD2Row) {
    row.hide = false;
    this.saveHideAndShow(row);
    this.reload();
  }

  deleteRow(row: SD2Row) {
    this.lock();
    Rest.del(
      `/api/v1/apn/Distribution/${row.demandId}/${row.quotaId}/${row.manufacturerId}/${row.bshPartNo}/${row.orderCode}`
      ).catch(e => drawErrorMessage(e, "Can't delete this record"))
      .then(() => this.reload())
          .finally(() => this.unlock());
  }
}

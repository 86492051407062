import {
  bomPath,
  componentPriceListPath,
  demandCollectionPaths,
  matrixBasisPath,
  overviewPaths,
  reportsPath,
  requestForQuotationPath,
  shareDistribution,
} from '../../routes/routes-paths';

export interface MenuItem {
  name: string;
  title: string;
  to?: string;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    title: 'Overview',
    name: 'Overview',
    children: [
      { title: 'Campaign', to: overviewPaths.campaign, name: 'Campaign' },
      { title: 'EMS', to: overviewPaths.ems, name: 'Ems' },
      { title: 'Components', to: overviewPaths.components, name: 'Components' },
      { title: 'Manufacturers', to: overviewPaths.manufacturers, name: 'Manufacturers' },
      { title: 'EDM Data', to: overviewPaths.edmData, name: 'EdmData' },
      { title: 'Commodity', to: overviewPaths.commodityCodes, name: 'Commodity' },
      { title: 'Multi-Year Agreement', to: overviewPaths.mya, name: 'Mya' },
    ],
  },
  { title: 'Demand Collection', to: demandCollectionPaths.current, name: 'DemandCurrent' },
  {
    title: 'APN',
    name: 'APN',
    children: [
      { title: 'Request for Quotation', to: requestForQuotationPath, name: 'ReqQuotation' },
      { title: 'Share Distribution', to: shareDistribution, name: 'ShareDistribution' },
    ],
  },
  { title: 'Reports', to: reportsPath, name: 'Reports' },
  { title: 'Component Price List', to: componentPriceListPath, name: 'componentPriceList' },
  {
    title: 'BOM',
    name: 'Bom',
    children: [
      { title: 'BOM/AVL', to: bomPath, name: 'Bom' },
      { title: 'Component Price List', to: componentPriceListPath, name: 'BomCpl' },
      { title: 'Matrix Basis', to: matrixBasisPath, name: 'BomCpl' },
    ],
  },
];

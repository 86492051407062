import * as React from 'react';
import { Select } from 'antd';
import { IncotermItem, getIncoterms } from '../../api/cached/getIncoterms';
import { drawErrorMessage } from '../../common/drawErrorMessage';

const { Option } = Select;

interface IncotermSelectProps {
  value?: string;
  onChange?: (emsCode: string | null) => void;
}

export const IncotermSelect: React.FC<IncotermSelectProps> = ({ value, onChange }) => {
  const [incoterm, setIncoterm] = React.useState<IncotermItem[] | null>(null);
  React.useEffect(() => {
    getIncoterms()
      .then(res => setIncoterm(res))
      .catch(e => drawErrorMessage(e));
  }, []);
  const loading = !incoterm;
  const handleChange = (value?: string) => {
    onChange && onChange(value === undefined ? null : value);
  };

  return (
    <Select
      allowClear
      loading={loading}
      showSearch
      placeholder='Start typing to find Incoterm Code'
      value={loading ? undefined : value}
      onChange={handleChange}
      filterOption={(input, option) => {
        return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {incoterm?.map(incoterm => (
        <Option value={incoterm.incotermId} key={incoterm.incotermId}>
          {incoterm.name}
        </Option>
      ))}
    </Select>
  );
};

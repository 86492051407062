/**
 * Utility to transform boolean fields to Component array
 */
type TComponents =
  | 'Without Demand'
  | 'QuotaIsZero'
  | 'QuotaBetweenZeroand100'
  | 'SumOfQuotaGreaterThan100'
  | 'PriceIsZeroAndQuotaGreaterThanZero'
  | 'PriceIsZeroAndQuotaIsZero';

interface ComponentsRaw {
  WithoutDemand?: boolean;
  QuotaIsZero?: boolean;
  QuotaBetweenZeroand100?: boolean;
  SumOfQuotaGreaterThan100?: boolean;
  PriceIsZeroAndQuotaGreaterThanZero?: boolean;
  PriceIsZeroAndQuotaIsZero?: boolean;
}

export type ComponentsExt<T extends ComponentsRaw> = Omit<
  T,
  | 'WithoutDemand'
  | 'QuotaIsZero'
  | 'QuotaBetweenZeroand100'
  | 'SumOfQuotaGreaterThan100'
  | 'PriceIsZeroAndQuotaGreaterThanZero'
  | 'PriceIsZeroAndQuotaIsZero'
> & {
  Components?: TComponents[];
};

export const transformCplComponents = <F extends ComponentsRaw>(src: F): ComponentsExt<F> => {
  const {
    WithoutDemand,
    QuotaIsZero,
    QuotaBetweenZeroand100,
    SumOfQuotaGreaterThan100,
    PriceIsZeroAndQuotaGreaterThanZero,
    PriceIsZeroAndQuotaIsZero,
    ...rest
  } = src;
  const result = rest as ComponentsExt<F>;
  if (WithoutDemand) result.Components = [...(result.Components || []), 'Without Demand'];

  if (QuotaIsZero) result.Components = [...(result.Components || []), 'QuotaIsZero'];
  if (QuotaBetweenZeroand100)
    result.Components = [...(result.Components || []), 'QuotaBetweenZeroand100'];
  if (SumOfQuotaGreaterThan100)
    result.Components = [...(result.Components || []), 'SumOfQuotaGreaterThan100'];
  if (PriceIsZeroAndQuotaGreaterThanZero)
    result.Components = [...(result.Components || []), 'PriceIsZeroAndQuotaGreaterThanZero'];
  if (PriceIsZeroAndQuotaIsZero)
    result.Components = [...(result.Components || []), 'PriceIsZeroAndQuotaIsZero'];

  return result;
};

import { isEmptyValue } from './checks';

const localeFormatter = new Intl.NumberFormat().format;

export const decimalSeparator = (() => {
  return localeFormatter(1.1).substring(1, 2);
})();

export const numberFormatter = (value?: string | number | null): string => {
  if (value === '' || value === undefined || value === null) {
    return '';
  }

  const stringValue = parseInt(value.toString());

  return isNaN(stringValue) ? '' : localeFormatter(stringValue);
};

const localePriceFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 6,
}).format;

export const priceFormatter = (value?: number | null): string => {
  return value === undefined || value === null ? '' : localePriceFormatter(value);
};

const localePercentFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
}).format;

export const percentFormatter = (value?: number | null): string => {
  return value === undefined || value === null ? '' : `${localePercentFormatter(value)}%`;
};

export const dateFormatter = (date: string | null) =>
  date ? new Date(date).toLocaleDateString() : '';

export const floatInputFormatter = (value?: string | number | undefined) => {
  return isEmptyValue(value)
    ? ''
    : String(value)
        .replace(/[^\d,.]/g, '')
        .split(/[.,]/)
        .slice(0, 2)
        .join(decimalSeparator);
};

export const integerInputFormatter = (value?: string | number | undefined) => {
  return isEmptyValue(value) ? '' : String(value).replace(/[^\d]/g, '');
};

import { BaseFilter } from '../../common/TableStore';
import { ActionPermission, Project, UserActions } from '../../api/types';

export interface DemandRow {
  bshPartNo: string | null;
  prevBshPartNo: string | null;
  commodityCode: string | null;
  commodityGpmt: string | null;
  demand: number | null;
  demandId: number;
  designation: string | null;
  emsCode: string | null;
  emsId: number;
  emsName: string;
  emsPartNo: string | null;
  isLocked: boolean;
  isNew: boolean;
  partId: number;
  prevBundlingName: string | null;
  prevDemand: string | null;
  statusId: number;
  year: number;
  projects: Project[] | null;
  actions: UserActions;
}

export interface DemandRightFilter {
  EmsCode: string;
  ManufacturerCode: string;
  WithoutDemand: boolean;
  NewParts: boolean;
  BshPartNo: string;
  EmsPartNo: string;
  OrderCode: string;
  Designation: string;
  CommodityCode: string;
  CommodityName: string;
  CommodityGpmt: string;
  ProjectId: number;
}
export const prepareToEdit = (row: DemandRow): DemandEditData => ({
  actions: row.actions,
  demand: row.demand,
  demandId: row.demandId,
  prevDemand: row.prevDemand,
  year: row.year,
  bshPartNo: row.bshPartNo,
  designation: row.designation,
  emsName: row.emsName,
  partId: row.partId,
  emsPartNo: row.emsPartNo,
  isLocked: row.isLocked,
  prevBundlingName: row.prevBundlingName,
  emsCode: row.emsCode,
  emsId: row.emsId,
  statusId: row.statusId,
  isNew: row.isNew,
  prevBshPartNo: row.prevBshPartNo,
  commodityCode: row.commodityCode,
  commodityGpmt: row.commodityGpmt,
  projects: (row.projects || []).map(({ projectId }) => projectId),
});

export interface DemandEditData {
  bshPartNo: string | null;
  prevBshPartNo: string | null;
  commodityCode: string | null;
  commodityGpmt: string | null;
  demand: number | null;
  demandId: number;
  designation: string | null;
  emsCode: string | null;
  emsId: number;
  emsName: string;
  emsPartNo: string | null;
  isLocked: boolean;
  isNew: boolean;
  partId: number;
  prevBundlingName: string | null;
  prevDemand: string | null;
  statusId: number;
  year: number;
  projects?: number[] | null;
  actions: UserActions;
}
export type DemandPageAction =
  | 'addcomponent'
  | 'bulklock'
  | 'export'
  | 'fieldbundling'
  | 'fieldnegotiator'
  | 'import'
  | 'bulkemschange'
  | 'addpreviouscomponent'
  | 'previousyearprice';

export type DemandPageActionsMap = Record<DemandPageAction, ActionPermission>;

export interface DemandFilter extends BaseFilter<DemandRow>, Partial<DemandRightFilter> {}

const defaultFilter: DemandRightFilter = {
  EmsCode: '',
  ManufacturerCode: '',
  WithoutDemand: false,
  NewParts: false,
  BshPartNo: '',
  EmsPartNo: '',
  OrderCode: '',
  Designation: '',
  CommodityCode: '',
  CommodityName: '',
  CommodityGpmt: '',
  ProjectId: 0,
};

export const demandRightFields = Object.keys(defaultFilter) as (keyof DemandFilter)[];

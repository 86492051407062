import { BaseFilter } from '../../common/TableStore';

export interface ApnFieldsStrict {
  ManufacturerCode: string;
  NegotiatorId: number;
  BundlingId: number;
  WithoutDemand: boolean;
  NewParts: boolean;
  BshPartNo: string;
  OrderCode: string;
  CommodityCode: string;
  CommodityName: string;
  CommodityGpmt: string;
}

export type ApnFields = Partial<ApnFieldsStrict>;

export interface ApnFilterFields<TRow> extends BaseFilter<TRow>, ApnFields {}

export const getFilterFields = (): (keyof ApnFields)[] => {
  const obj: ApnFieldsStrict = {
    ManufacturerCode: '',
    NegotiatorId: 0,
    BundlingId: 0,
    WithoutDemand: false,
    NewParts: false,
    BshPartNo: '',
    OrderCode: '',
    CommodityCode: '',
    CommodityName: '',
    CommodityGpmt: '',
  };
  return Object.keys(obj) as (keyof ApnFields)[];
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ComponentPriceListRow, prepareToEdit } from './ComponentPriceListPage.types';
import { ComponentPriceListPageStore } from './ComponentPriceListPageStore';
import { PermissionCheck } from '../../components/PermissionCheck';
import { ActionPermission, UserActions } from '../../api/types';

interface PropsDemandActions {
  row: ComponentPriceListRow;
  store: ComponentPriceListPageStore;
}

const lockingPermission: ActionPermission = { allow: false, reasons: ['Locking'] };

export const ComponentPriceListActions: React.FC<PropsDemandActions> = observer(
  ({ row, store }) => {
    const { table } = store;
    const onDelete = () => {
      const { destroy } = Modal.confirm({
        title: `Component will be deleted!`,
        icon: <ExclamationCircleOutlined />,
        content: `Press OK to continue`,
        onOk: async () => {
          destroy();
          table.deleteRow(row);
        },
      });
    };
    const { actions } = row;
    const isLocking = table.isLocking(row);

    const onClick = () => {
      table.edit.activate(prepareToEdit(row));
    };
    if (row.priceType !== 'APN') {
      return (
        <>
          <PermissionCheck permission={actions.edit} type='link' onClick={onClick}>
            <Button>edit</Button>
          </PermissionCheck>
          <PermissionCheck permission={actions.delete} type='link' onClick={onDelete}>
            <Button>delete</Button>
          </PermissionCheck>
        </>
      );
    }
    return null;
  }
);

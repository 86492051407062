import { BaseFilter } from '../../common/TableStore';
import { ActionPermission, UserActions } from '../../api/types';

export interface ComponentPriceListRow {
  bshPartNo: string | null;
  commodityCode: string | null;
  commodityName: string | null;
  commodityGpmt: string | null;
  bundlingId: number | null;
  bundlingName: string | null;
  origCurrency: string | null;
  mixedPriceOrigCurrency: string | null;
  maxLeadTime: number | null;
  comment: string | null;
  demand: number | null;
  demandId: number;
  designation: string | null;
  emsCode: string | null;
  emsId: number;
  emsName: string;
  isLocked: boolean;
  isCompleted: boolean;
  isNew: boolean;
  IsAllowDeleteEstimates: boolean;
  partId: number;
  priceType: string;
  quantityBase: number;
  statusId: number;
  year: number;
  actions: UserActions;
  filterCurrencyRate: number;
}

export interface ComponentPriceListRightFilter {
  EmsCode: string;
  BundlingId: number;
  ManufacturerCode: string;
  WithoutDemand: boolean;
  NewParts: boolean;
  BshPartNo: string;
  OrderCode: string;
  Designation: string;
  CommodityCode: string;
  CommodityName: string;
  Currency: string;
  CommodityGpmt: string;
  priceType: string;
  year: number;
}
export const prepareToEdit = (row: ComponentPriceListRow): ComponentPriceListEditData => ({
  actions: row.actions,
  demand: row.demand,
  demandId: row.demandId,
  year: row.year,
  bshPartNo: row.bshPartNo,
  designation: row.designation,
  emsName: row.emsName,
  partId: row.partId,
  bundlingID: row.bundlingId,
  bundlingName: row.bundlingName,
  origCurrency: row.origCurrency,
  mixedPriceOrigCurrency: row.mixedPriceOrigCurrency,
  priceType: row.priceType,
  quantityBase: row.quantityBase,
  maxLeadTime: row.maxLeadTime,
  comment: row.comment,
  isLocked: row.isLocked,
  isCompleted: row.isCompleted,
  IsAllowDeleteEstimates: row.IsAllowDeleteEstimates,
  emsCode: row.emsCode,
  emsId: row.emsId,
  statusId: row.statusId,
  isNew: row.isNew,
  commodityCode: row.commodityCode,
  commodityGpmt: row.commodityGpmt,
});

export interface ComponentPriceListEditData {
  bshPartNo: string | null;
  commodityCode: string | null;
  commodityGpmt: string | null;
  demand: number | null;
  IsAllowDeleteEstimates: boolean;
  demandId: number;
  designation: string | null;
  emsCode: string | null;
  emsId: number;
  emsName: string;
  bundlingID: number | null;
  bundlingName: string | null;
  origCurrency: string | null;
  mixedPriceOrigCurrency: string | null;
  quantityBase: number | null;
  maxLeadTime: number | null;
  comment: string | null;
  priceType: string | null;
  isLocked: boolean;
  isCompleted: boolean;
  isNew: boolean;
  partId: number;
  statusId: number;
  year: number;
  projects?: number[] | null;
  actions: UserActions;
}
export type ComponentPriceListPageAction =
  | 'addcomponentcpl'
  | 'bulklock'
  | 'export'
  | 'fieldbundling'
  | 'fieldnegotiator'
  | 'import'
  | 'bulkemschange';

export type ComponentPriceListPageActionsMap = Record<
  ComponentPriceListPageAction,
  ActionPermission
>;

export interface ComponentPriceListFilter
  extends BaseFilter<ComponentPriceListRow>,
    Partial<ComponentPriceListRightFilter> {}

const defaultFilter: ComponentPriceListRightFilter = {
  EmsCode: '',
  BundlingId: 0,
  ManufacturerCode: '',
  WithoutDemand: false,
  NewParts: false,
  BshPartNo: '',
  OrderCode: '',
  Designation: '',
  CommodityCode: '',
  CommodityName: '',
  CommodityGpmt: '',
  Currency: '',
  priceType: '',
  year: 0,
};

export const ComponentPriceListRightFields = Object.keys(
  defaultFilter
) as (keyof ComponentPriceListFilter)[];

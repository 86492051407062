import * as React from 'react';
import { Select } from 'antd';
import { getBundlings } from '../../../api/cached/getBundlings';

interface BundleItem {
  disable: boolean;
  name: string | undefined;
  defValue: string | undefined;
  onChange?: (value: string) => void;
}

export const BundlingSelectCplAddComponent: React.FC<BundleItem> = ({
  disable,
  name,
  defValue,
  onChange,
}) => {
  const [options, setOptions] = React.useState<BundleItem[] | null>(null);

  React.useEffect(() => {
    getBundlings().then(setOptions);
  }, []);
  return (
    <Select
      disabled={disable}
      onChange={onChange}
      allowClear
      defaultValue={defValue}
      loading={options === null}
      value={name}
    >
      {options &&
        options.map(rec => (
          <Select.Option disabled={disable} key={rec.name ?? undefined} value={rec.name ?? ''}>
            {rec.name}
          </Select.Option>
        ))}
    </Select>
  );
};

import * as React from 'react';
import { Select } from 'antd';
import { LogOnDistriItem, getLogOnDistris } from '../../api/cached/getLogOnDistri';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../store/features/session/session-slice';

const { Option } = Select;

interface LogondistriSelectProps {
  value?: string;
  onChange?: (emsCode: string | null) => void;
}

export const LogOnDistriSelect: React.FC<LogondistriSelectProps> = ({ value, onChange }) => {
  const [logondistri, setLogOnDistri] = React.useState<LogOnDistriItem[] | null>(null);
  const { groups } = useSelector(selectUserInfo);
  const isSupr = groups.includes('SUPUSR');
  const isNgtr = groups.includes('NEGTR');
  React.useEffect(() => {
    getLogOnDistris()
      .then(res => setLogOnDistri(res))
      .catch(e => drawErrorMessage(e));
  }, []);

  const loading = !logondistri;
  const handleChange = (value?: string) => {
    onChange && onChange(value === undefined ? null : value);
  };

  return (
    <Select
      allowClear
      loading={loading}
      showSearch
      placeholder='Start typing to find logon distri'
      value={loading ? undefined : value}
      onChange={handleChange}
      filterOption={(input, option) => {
        return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      disabled={!isSupr && !isNgtr}
    >
      {logondistri?.map(logondistri => (
        <Option value={logondistri.logOnDistriId} key={logondistri.logOnDistriId}>
          {logondistri.name}
        </Option>
      ))}
    </Select>
  );
};

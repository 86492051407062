import { makeAutoObservable } from 'mobx';
import { drawErrorMessage } from './drawErrorMessage';

export type FormStoreStatus = 'none' | 'active' | 'save';

export type FnFormSave<T> = (oldData: T, newData: Partial<T>) => Promise<void>;

export class FormStore<T> {
  status: FormStoreStatus = 'none';
  data: T = {} as T;
  currentData: T = {} as T;
  saveCall: FnFormSave<T>;
  onSaveFinish: () => void; // useful for redraw a screen with new data after close of modal form

  constructor(saveCall: FnFormSave<T>, onFinish?: () => void) {
    this.saveCall = saveCall;
    this.onSaveFinish = onFinish || (() => {});
    makeAutoObservable(this);
  }
  activate(data: T) {
    this.data = data;
    this.currentData = data;
    this.status = 'active';
  }
  get visible(): boolean {
    return this.status !== 'none';
  }
  get saving(): boolean {
    return this.status === 'save';
  }
  close = (): void => {
    this.status = 'none';
  };
  setData(newData: Partial<T>) {
    this.data = { ...this.data, ...newData };
  }
  save = (newData: Partial<T>) => {
    this.status = 'save';
    const oldData = this.data;
    this.setData(newData);
    return this.saveCall(oldData, newData)
      .then(result => {
        this.close();
        if (this.onSaveFinish) {
          this.onSaveFinish();
        }
      })
        .catch(e => {
            if (e.response.status === 409) {
                this.on409Error(e);
                return Promise.reject(e);
            }
        this.onError(e);
        return Promise.reject(e);
      });
  };
  onError(e: Error) {
    this.status = 'active';
    drawErrorMessage(e, 'Error saving data');
    }
    on409Error(e: Error) {
        this.status = 'active';
        drawErrorMessage(e, 'EMS already exists!');
    }
  onValuesChanged(allValues: T) {
    this.currentData = { ...allValues };
  }
}

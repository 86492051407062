import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';

interface DefineDistributionFieldProps {
    isRequired: boolean;
}

const DefineDistributionField: React.FC<DefineDistributionFieldProps> = ({ isRequired }) => {
    const [form] = Form.useForm();
    const maxCharactersRule = { max: 500, message: 'Defined distribution must be less than 500 characters' };
    const DDRule: Rule[] = [{required: true, message: 'Defined distribution is required' }, maxCharactersRule];

    useEffect(() => {
        if (!isRequired) {
            // Reset field value, rules, and disable the field when isRequired becomes false
            form.setFieldsValue({ defineDistri: '' }); // Reset field value
            form.setFields([{ name: 'definedDistribution', value: '' }]); // Reset field value
            form.setFields([{ name: 'definedDistribution', errors: [] }]); // Clear any validation errors
        }
    }, [form, isRequired]);

    return (
        <Form.Item
            key={isRequired ? 'defineDistri-required' : 'definedDistribution'}
            label='Defined distribution'
            name='definedDistribution'
            rules={isRequired? DDRule: undefined }
        >
            <Input autoComplete='off' disabled={!isRequired} />
        </Form.Item>
    );
};

export default DefineDistributionField;
import * as React from 'react';
import { observer } from 'mobx-react';
import { DemandQuotaStore } from './DemandQuotaStore';
import { ModalForm } from '../../../components/ModalForm';
import { Form, Input } from 'antd';
import { IncotermSelect } from '../../../components/IncotermSelect';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../store/features/session/session-slice';
import { LogOnDistriSelect } from '../../../components/logondistriSelect/logondistriSelect';

interface PropsDemandQuotaEdit {
  store: DemandQuotaStore;
}

export const DemandQuotaEdit: React.FC<PropsDemandQuotaEdit> = observer(({ store }) => {
  const { editForm } = store;
  const { groups } = useSelector(selectUserInfo);
  const isSupr = groups.includes('SUPUSR');
  const isPrpur = groups.includes('PRPUR');

  return (
    <ModalForm
      title='Edit Order Code information'
      name='quota'
      visible={editForm.visible}
      saving={editForm.saving}
      layout={{ layout: 'vertical' }}
      onClose={editForm.close}
      onSave={editForm.save}
      initialData={editForm.data}
    >
      <Form.Item name='incotermId' label='Incoterm Code'>
        <IncotermSelect />
      </Form.Item>
      <Form.Item name='incotermDirection' label='Incoterm Location'>
        <Input autoComplete='off' maxLength={100} />
      </Form.Item>
      <Form.Item label='Previous Order Code' name='prevOrderCode'>
        <Input
          disabled={!isSupr && !isPrpur}
          value={editForm.data.prevOrderCode ?? ''}
          maxLength={30}
        />
      </Form.Item>
      <Form.Item name='distributor' label='Distributor'>
        <Input autoComplete='off' maxLength={100} />
      </Form.Item>
      <Form.Item name='comment' label='Comments'>
        <TextArea maxLength={200} />
      </Form.Item>
      <Form.Item name='logondistriId' label='LogOn Distri'>
        <LogOnDistriSelect />
      </Form.Item>
    </ModalForm>
  );
});

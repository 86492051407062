import { FnDataLoad, TableStore } from '../../../common/TableStore';
import { DQFilter, DQRow } from './DemandQuota.types';
import { Rest } from '../../../api/Rest';
import { FnFormSave, FormStore } from '../../../common/FormStore';

const loadDQ = (demandId: number): FnDataLoad<DQRow, DQFilter> => {
  interface Response {
    data: DQRow[];
  }
  return params =>
    Rest.get<Response>(`/api/v1/demand/${demandId}/Quota`).then(response => ({
      data: response.data,
      total: response.data.length,
    }));
};

const saveDQ: FnFormSave<DQRow> = (oldData, newData) =>
  Rest.patch(`/api/v1/Quota/${oldData.quotaId}`, newData);

export class DemandQuotaStore extends TableStore<DQRow, DQFilter> {
  demandId: number;
  isLocked: boolean;
  editForm: FormStore<DQRow>;

  constructor(demandId: number, isLocked: boolean) {
    super({ page: 0, pageSize: 0 }, loadDQ(demandId));
    this.demandId = demandId;
    this.isLocked = isLocked;
    const onEditOk = () => {
      this.reload();
    };
    this.editForm = new FormStore<DQRow>(saveDQ, onEditOk);
  }
  // override to prevent scroll
  init() {
    this.reload({ notScrollTop: true });
  }
}

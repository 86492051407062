import React, { FunctionComponent } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import styles from './currency-list.module.less';

interface ToolsMenuProps {
  disabled?: boolean;
  caption?: string;
}

export const CurrencyList: FunctionComponent<ToolsMenuProps> = ({
  disabled = false,
  children,
  caption,
}) => {
  return (
    <Dropdown overlay={<Menu className={styles.menu}>{children}</Menu>} disabled={disabled}>
      <Button type='link'>
        {caption} <DownOutlined />
      </Button>
    </Dropdown>
  );
};

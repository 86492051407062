import { LoaderStore } from '../../common/LoaderStore';
import { ExportBomStore } from './ExportBom';

export class BomPageStore extends LoaderStore {
  exportBom: ExportBomStore;
  constructor() {
    super();
    this.exportBom = new ExportBomStore();
  }
  init() {
    this.onReady();
  }
}

import * as React from 'react';
import { observer } from 'mobx-react';
import { MatrixBasisPageStore } from './MatrixBasisPageStore';
import { Button, Divider, Form, Modal, Select, Space } from 'antd';
import { formFooterLayout, formLayout, requiredRule } from '../../common/constants';
import { Rule } from 'antd/es/form';
import { MatrixBasisRow } from './MatrixBasisPage.types';
import { useEffect } from 'react';
import { BshPartNoField } from '../DemandPage/demand-add-line/bsh-part-no-field';
import { BundlingSelectCplAddComponent } from '../../components/ApnFilter/components/BundlingSelectCplAddComponent';
import { CurrentCampaignInfo, getCurrentCampaign } from '../../api/cached/getCurrentCampaign';
import { getYears } from '../../api/cached/getYears';
import { EmsCplSelect } from '../../components/ems-select/ems-cpl-select';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { MatrixBasisActions } from './MatrixBasisActions';

interface PropsCplAddNewLine {
  store: MatrixBasisPageStore;
}

interface YearItem {
  yearId: number;
  name: string;
}

let emsCache: string | undefined = undefined;

const priceTypeRules: Rule[] = [requiredRule];

const initialValues = { emsCode: emsCache };

type Fields = Pick<MatrixBasisRow, 'emsCode' | 'bshPartNo' | 'year' | 'priceType'>;

export const MatrixBasisAddNewLine: React.FC<PropsCplAddNewLine> = observer(({ store }) => {
  const [form] = Form.useForm();
  const [isBshPartNo, setIsBshPartNo] = React.useState(false);
  const [isEmsRequired, setIsEmsRequired] = React.useState(false);
  const [isEmsDisabled, setIsEmsDisabled] = React.useState(false);
  const [defaultBundlingName, setdefaultBundlingName] = React.useState('BSH');
  const [defaultEms, setdefaultEms] = React.useState(emsCache);
  const [isBundlingDisbaled, setIsBundlingDisbaled] = React.useState(false);

  const emsRules: Rule[] = [{ required: isEmsRequired }];
  const bundlingRules: Rule[] = [requiredRule];
  const yearRules: Rule[] = [requiredRule];

  const isVisible = store.isAddLine;
  const onSubmit = (values: Fields) => {
    if (emsCache === undefined && (values.emsCode != null || values.emsCode != undefined)) {
      emsCache = values.emsCode ?? undefined;
    }
    if (values.emsCode === undefined) {
      values.emsCode = emsCache ?? null;
    }

    store.createNewLine(values);
    if (emsCache != undefined && emsCache != null) {
      emsCache = values.emsCode ?? undefined;
    }
  };

  const onSelectPriceType = (value: string) => {
    switch (value) {
      case 'Ems':
        setIsEmsRequired(true);
        setIsEmsDisabled(false);
        setdefaultBundlingName('EMS');
        setdefaultEms('');
        setIsBundlingDisbaled(true);
        break;
      case 'Offer':
        setIsEmsRequired(false);
        setIsEmsDisabled(true);
        setdefaultEms('ALL');
        setIsBundlingDisbaled(false);
        break;
      case 'Estimate':
        setIsEmsRequired(false);
        setIsEmsDisabled(true);
        setdefaultEms('ALL');
        setIsBundlingDisbaled(false);
        break;
    }
  };
  const [options, setOptions] = React.useState<YearItem[]>();
  const [info, setInfo] = React.useState<CurrentCampaignInfo | null>(null);

  React.useEffect(() => {
    getYears().then(setOptions);
  }, []);
  React.useEffect(() => {
    getCurrentCampaign().then(setInfo);
  }, []);
  const onCancel = () => store.closeAddLine();

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
    }
  }, [form, isVisible]);

  return (
    <Modal
      destroyOnClose={true}
      visible={isVisible}
      title='Add new component'
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form<Fields> {...formLayout} onFinish={onSubmit} form={form} initialValues={initialValues}>
        <Form.Item label='Price Type' name='priceType' rules={priceTypeRules}>
          <Select
            onSelect={onSelectPriceType}
            style={{ width: 120 }}
            options={[
              { value: 'Ems', label: 'EMS' },
              { value: 'Offer', label: 'Offer' },
              { value: 'Estimate', label: 'Estimate' },
            ]}
          />
        </Form.Item>
        <Form.Item label='Year' initialValue={info?.year.toString()} name='year' rules={yearRules}>
          <Select allowClear defaultValue={info?.year.toString()} loading={options === null}>
            {options &&
              options.map(rec => (
                <Select.Option key={rec.yearId} value={rec.name}>
                  {rec.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label='EMS' name='emsCode' rules={emsRules}>
          <EmsCplSelect
            onChange={setdefaultEms}
            vall={defaultEms}
            val={defaultEms}
            disabled={isEmsDisabled}
          />
        </Form.Item>
        <BshPartNoField isActive={true} onChange={setIsBshPartNo} />
        <Form.Item
          initialValue={defaultBundlingName}
          label='Bundling'
          name={'BundlingName'}
          rules={bundlingRules}
        >
          <BundlingSelectCplAddComponent
            disable={isBundlingDisbaled}
            name={defaultBundlingName}
            onChange={setdefaultBundlingName}
            defValue={defaultBundlingName}
          />
        </Form.Item>
        <Divider />

        <Form.Item {...formFooterLayout}>
          <Space>
            <Form.Item noStyle shouldUpdate>
              {() => {
                const errors = form.getFieldsError();
                const isTouched = form.isFieldTouched(['bshPartNo']);
                const hasErrors = errors.some(({ errors }) => errors.length > 0);
                const isDisabled = !isBshPartNo || !isTouched || hasErrors;

                return (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isDisabled}
                    loading={store.isAddLineSaving}
                  >
                    Add
                  </Button>
                );
              }}
            </Form.Item>

            <Button type='default' htmlType='reset' onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
});

import { MenuItem } from '../components/header-menu/constants';
import { ActionItem } from '../api/types';

export const findFirstItem = (
  menuItems: MenuItem[],
  actions: ActionItem[]
): MenuItem | undefined => {
  const availableItems = new Set(actions.map(({ name }) => name));
  const findItem = (list: MenuItem[]): MenuItem | undefined => {
    for (const it of list) {
      if (it.children) {
        const res = findItem(it.children);
        if (res) return res;
      }
      if (availableItems.has(it.name)) return it;
    }
  };
  return findItem(menuItems);
};

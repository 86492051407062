import { LoaderStore } from '../../common/LoaderStore';
import { TableStore, FnDataLoad } from '../../common/TableStore';
import { MYARow, MYAFilter, MYAPageActionsDict } from './MYA.types';
import { getRows } from '../../api/getRows';
import { ApnFilterStore } from '../../components/ApnFilter/ApnFilterStore';
import { Rest } from '../../api/Rest';
import { FnFormSave, FormStore } from '../../common/FormStore';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { action, makeObservable, observable } from 'mobx';
import { updatePageActions } from '../../common/updatePageActions';

const loadMYA: FnDataLoad<MYARow, MYAFilter> = filter =>
  getRows<MYARow, MYAFilter>('/api/v1/Mya', filter);

const getMYAById = (id: number) => Rest.get<MYARow>(`/api/v1/Mya/${id}`);

const checkDate = <T>(row: T, field: keyof T) => {
  const value = row[field] as any;
  if (value && typeof value === 'object' && 'format' in value) {
    row[field] = value.format('DD/MM/YYYY');
  }
};
const checkEmptyStr = <T>(row: T, field: keyof T) => {
  if (row[field] === undefined) {
    // @ts-ignore
    row[field] = '';
  }
};

const transformData = (newData: Partial<MYARow>) => {
  checkDate(newData, 'startDate');
  checkDate(newData, 'endDate');
  checkEmptyStr(newData, 'comments');
  checkEmptyStr(newData, 'link');
};

const createNewRow = (newData: Partial<MYARow>): Promise<number> => {
  transformData(newData);
  (Object.keys(newData) as (keyof MYARow)[]).forEach(key => {
    if (newData[key] === null) {
      delete newData[key];
    }
  });
  return Rest.post('/api/v1/Mya', newData);
};

const editRow: FnFormSave<MYARow> = (oldData, newData) => {
  const { myaId } = oldData;
  transformData(newData);
  return Rest.patch(`/api/v1/Mya/${myaId}`, newData);
};

class ShareDistributionTableStore extends TableStore<MYARow, MYAFilter> {
  constructor() {
    super({ page: 0, pageSize: 10 }, loadMYA);
    makeObservable(this, {
      deleteRow: action,
    });
  }
  deleteRow(row: MYARow) {
    Rest.del(`/api/v1/Mya/${row.myaId}`).then(() => {
      // always open first page after delete any row
      this.load({ page: 0 });
    });
  }
}

export class MYAPageStore extends LoaderStore {
  table: ShareDistributionTableStore;
  filter: ApnFilterStore<MYARow, MYAFilter>;
  actions?: MYAPageActionsDict;
  edit: FormStore<MYARow>;
  addNew: FormStore<MYARow>;
  orderCodesMya: MYARow | null = null;
  createdRowId: number | null = null;

  constructor() {
    super();
    makeObservable(this, {
      orderCodesMya: observable,
      setOrderCodesMya: action,
    });
    this.table = new ShareDistributionTableStore();
    this.filter = new ApnFilterStore<MYARow, MYAFilter>(this.table);
    this.edit = new FormStore<MYARow>(editRow, () => this.updateRow());
    this.addNew = new FormStore<MYARow>(
      (oldData, newData) =>
        createNewRow(newData).then(id => {
          this.createdRowId = id;
        }),
      () => {
        this.table.load({ page: 0 });
        if (this.createdRowId)
          getMYAById(this.createdRowId).then(result => {
            this.setOrderCodesMya(result);
            this.createdRowId = null;
          });
      }
    );
  }

  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      updatePageActions('Mya', this)
        .then(() => this.onLoad())
        .catch(e => this.onError(e));
    } else {
      this.table.reload();
      updatePageActions('Mya', this);
    }
  }

  setActions(actions: MYAPageActionsDict) {
    this.actions = actions;
  }

  onLoad() {
    this.onReady();
    this.table.init();
  }

  setOrderCodesMya(mya: MYARow | null) {
    this.orderCodesMya = mya;
  }

  updateRow() {
    const { data } = this.edit;
    const { myaId } = data;
    this.table.modifyRowExt('myaId', data);
    Rest.get<MYARow>(`/api/v1/Mya/${myaId}`)
      .then(response => this.table.modifyRowExt('myaId', response))
      .catch(e => drawErrorMessage(e, 'Cant read data'));
  }
}

import { LoaderStore } from '../../common/LoaderStore';
import { FnDataLoad, RemoteResponse, TableStore } from '../../common/TableStore';
import {
  CampaignActions,
  CampaignFilter,
  CampaignRow,
  CampaignRowAction,
  NewCampaignFields,
} from './CampaignPage.types';
import { Rest } from '../../api/Rest';
import { ActionPermission } from '../../api/types';
import { action, makeObservable, observable } from 'mobx';
import { updatePageActions } from '../../common/updatePageActions';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { clearCurrentCampaign } from '../../api/cached/getCurrentCampaign';
import { FnFormSave, FormStore } from '../../common/FormStore';

const loadData: FnDataLoad<CampaignRow, CampaignFilter> = () =>
  Rest.get<CampaignRow[]>('/api/v1/Campaign').then(
    data => ({ data, total: data.length } as RemoteResponse<CampaignRow>)
  );

class CampaignTableStore extends TableStore<CampaignRow, CampaignFilter> {
  activeAction: CampaignRowAction | null = null;

  constructor() {
    super({ page: 0, pageSize: 0 }, loadData);
    makeObservable(this, {
      activeAction: observable,
      onAction: action,
      onActionEnd: action,
    });
  }
  onAction(row: CampaignRow, stage: CampaignRowAction) {
    if (this.activeAction) {
      return Promise.reject(); // Prevent the start of a new action until the current one is completed.
    }
    this.activeAction = stage;
    const stageMap: Record<CampaignRowAction, string> = {
      apn: 'Apn',
      open: 'Opened',
      exportapn: 'Download APN',
    };
    return Rest.put('/api/v1/Campaign', { stage: stageMap[stage] })
      .then(() => this.onActionEnd())
      .catch(e => this.onActionEnd(e));
  }
  onActionEnd(e?: Error) {
    this.activeAction = null;
    if (e) {
      drawErrorMessage(e);
    } else {
      this.reload();
    }
  }
}

const onNewCampaign: FnFormSave<NewCampaignFields> = (oldData, newData) =>
  Rest.post('/api/v1/Campaign', newData);

export class CampaignPageStore extends LoaderStore {
  table: CampaignTableStore;
  actions?: CampaignActions;
  currentCampaign?: CampaignRow;
  newCampaign: FormStore<NewCampaignFields>;

  get campaignPermission(): ActionPermission | undefined {
    return this.actions?.startcampaign;
  }

  constructor() {
    super();
    this.table = new CampaignTableStore();
    this.newCampaign = new FormStore<NewCampaignFields>(onNewCampaign, () => this.update());
    makeObservable(this, {
      actions: observable,
      setActions: action,
    });
  }

  init() {
    this.update();
  }

  update() {
    clearCurrentCampaign();
    this.onWait();
    const requests = [
      updatePageActions('Campaign', this),
      Rest.get<CampaignRow>('/api/v1/Campaign/current').then(result => {
        this.currentCampaign = result;
      }),
    ];
    Promise.all(requests)
      .then(() => {
        this.onReady();
        this.table.reload();
      })
      .catch(e => this.onError(e));
  }
  setActions(actions: CampaignActions) {
    this.actions = actions;
  }
  startAction(row: CampaignRow, stage: CampaignRowAction) {
    this.table.onAction(row, stage).then(() => this.update());
  }
  exportKeyMaker(row: CampaignRow) {
    const { year } = row;
    return (): Promise<string> =>
      Rest.postExt(`/api/v1/Campaign/${year}/export`, { responseType: 'text' });
  }

  previousYearExportKeyMaker(row: CampaignRow) {
    const { year } = row;
    return (): Promise<string> =>
      Rest.postExt(`/api/v1/Campaign/${year}/export-previous-year`, { responseType: 'text' });
  }
}

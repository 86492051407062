import { Session } from './session-types';
import { RootState } from '../../root-reducer';
import { Auth } from 'aws-amplify';
import { createRequestSlice } from '../../utils/request-slice';
import { AppThunk } from '../../index';
import { auth } from '../../../api';

const sessionSlice = createRequestSlice({
  name: 'session',
  initialDataState: {} as Session,
  reducers: {},
});

export const fetchSession = (): AppThunk => async dispatch => {
  let canSignIn = true;
  try {
    dispatch(sessionSlice.actions.request());
    const session = await Auth.currentSession();
    canSignIn = false;
    const idToken = session.getIdToken();

      const groups = idToken.payload['custom:roles'].replace(/\\"/g, '"').replace(/"/g, '').replace("\\", "").replace("[", "").replace("]", "").split(",") as string[];
    const email = idToken.payload['email'] as string;
    if (groups) {
      auth.setToken(idToken.getJwtToken());
    }

    dispatch(sessionSlice.actions.requestSuccess({ email, groups }));
  } catch (err) {
    dispatch(sessionSlice.actions.requestError());
    if (canSignIn) {
      await Auth.federatedSignIn();
    }
  }
};

export const selectUserInfo = (state: RootState) => {
  return state.session.data;
};

export const selectSessionStatus = (state: RootState) => {
  return state.session.status;
};

export default sessionSlice.reducer;

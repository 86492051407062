import API from '@aws-amplify/api';
import { MAIN_API } from './init-aws-amplify';
import { Auth } from 'aws-amplify';
import { auth } from './auth-service';

type ParamValue = string | string[];

interface ApiInit {
  queryStringParameters?: Record<string, ParamValue>;
}

export const makeStrParams = <T extends object>(
  src?: T
): Record<string, ParamValue> | undefined => {
  if (!src) {
    return undefined;
  }
  const toStr = (value: any): ParamValue =>
    Array.isArray(value) ? value.map(v => String(v)) : String(value);
  const keys = Object.keys(src) as (keyof T)[];
  const result = keys.reduce((acc, key) => {
    const v = src[key];
    if (v === undefined) return acc;
    return { ...acc, [key]: key === 'page' ? String(+v + 1) : toStr(v) };
  }, {} as Record<string, ParamValue>);
  return result;
};

export interface RequestConfig<TBody> {
  queryStringParameters?: Record<string, ParamValue>;
  body?: TBody;
  responseType?: 'json' | 'text';
}

const sessionCheck = <T>(e: Error, call: () => Promise<T>): Promise<T> => {
  // Unfortunately, there is no definite sign of session fading.
  // It looks the same as breaking the connection.
  if (e.message === 'Network Error') {
    return Auth.currentSession().then(session => {
      const idToken = session.getIdToken();
      auth.setToken(idToken.getJwtToken());
      console.warn('The session has been refreshed');
      return call();
    });
  }
  return Promise.reject(e);
};

const callWithSessionCheck = <T>(call: () => Promise<T>): Promise<T> =>
  call().catch(e => sessionCheck(e, call));

export class Rest {
  static get<TResponse>(shortUrl: string, params?: Record<string, ParamValue>): Promise<TResponse> {
    const url = shortUrl;
    const myInit: ApiInit = {};
    if (params) {
      myInit.queryStringParameters = params;
    }
    return callWithSessionCheck(() => API.get(MAIN_API, url, myInit) as Promise<TResponse>);
  }
  static post<TResponse, TBody = void>(shortUrl: string, body?: TBody): Promise<TResponse> {
    return callWithSessionCheck(() => API.post(MAIN_API, shortUrl, { body }) as Promise<TResponse>);
  }
  static postExt<TResponse, TBody = void>(
    shortUrl: string,
    config: RequestConfig<TBody>
  ): Promise<TResponse> {
    return callWithSessionCheck(() => API.post(MAIN_API, shortUrl, config) as Promise<TResponse>);
  }
  static patch<TResponse, TBody = void>(shortUrl: string, body?: TBody): Promise<TResponse> {
    return callWithSessionCheck(
      () => API.patch(MAIN_API, shortUrl, { body }) as Promise<TResponse>
    );
  }
  static put<TResponse, TBody>(shortUrl: string, body?: TBody): Promise<TResponse> {
    return callWithSessionCheck(() => API.put(MAIN_API, shortUrl, { body }));
  }
  static del(shortUrl: string): Promise<void> {
    return callWithSessionCheck(() => API.del(MAIN_API, shortUrl, {}));
  }
}

import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { Rule } from 'antd/es/form';
import { part as partApi } from '../../../api';
import { requiredRule } from '../../../common/constants';
import { ProjectSelectBsh } from '../../../components/ProjectsSelect/ProjectSelectBsh';

interface DemandFieldProps {
  delay?: number;
  minCharsToSearch?: number;
  isActive?: boolean;
  onChange: (isCorrect: boolean) => void;
}

let currentValue = '';
const rules: Rule[] = [requiredRule];
const notFound = 'BSH Part No. not found';
const required = 'Required';
/*
 * Should be space, because empty string adds unnecessary indentation
 * for Form.Item component
 */
const defaultMessage = ' ';

export const BshPartNoProjectsField: FunctionComponent<DemandFieldProps> = ({
  delay = 500,
  minCharsToSearch = 3,
  isActive,
  onChange,
}) => {
  const [designation, setDesignation] = useState('');
  const [validateStatus, setValidateStatus] = useState<'error' | 'success' | 'validating'>(
    'success'
  );
  const [message, setMessage] = useState(defaultMessage);

  // reset field state
  useEffect(() => {
    setValidateStatus('success');
    setDesignation('');
  }, [isActive]);

  const setError = (error: string) => {
    setValidateStatus('error');
    setMessage(error);
  };

  const handleChange = useCallback(
    async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      onChange(false);
      setDesignation('');

      if (value.length < minCharsToSearch) {
        const error = value ? notFound : required;
        setError(error);
        return;
      }

      currentValue = value;
      setValidateStatus('validating');
      setMessage('Loading...');

      setTimeout(async () => {
        if (value !== currentValue) {
          return;
        }

        const part = await partApi.getByPartNo(value);

        if (part) {
          setDesignation(part.designation);
          setValidateStatus('success');
          setMessage(defaultMessage);
          onChange(true);
        } else {
          setError(notFound);
        }
      }, delay);
    },
    [delay, minCharsToSearch, onChange]
  );

  return (
    <>
      <Form.Item
        help={message}
        label='BSH Part No'
        name='bshPartNo'
        validateStatus={validateStatus}
        rules={rules}
      >
        <Input onChange={handleChange} autoComplete='off' />
      </Form.Item>
      <Form.Item label='Designation'>{designation}</Form.Item>
      <Tooltip title={'Projects'}>
        <Form.Item label='Project(s)' name='projects'>
          <ProjectSelectBsh bshpartno={currentValue} />
        </Form.Item>
      </Tooltip>
    </>
  );
};

import React, { FunctionComponent, useState } from 'react';
import { Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Rest } from '../../api/Rest';
import { PreviousSessionRow } from './PreviousSessions.types';
import { PreviousSession } from './PreviousSession';
import { delay } from '../../utils/delay';

interface PreviousSessionsProps {
  quotaId: number;
}

const loadSessions = async (id: number) => {
  return Rest.get<{ data: PreviousSessionRow[] }>(`/api/v1/apn/Quotation/${id}/sessions`).then(
    ({ data }) => data
  );
};

export const PreviousSessions: FunctionComponent<PreviousSessionsProps> = ({ quotaId }) => {
  const [sessions, setSessions] = useState<PreviousSessionRow[] | null>(null);
  const [loading, setLoading] = useState(false);
  const handleMouseOver = async () => {
    if (sessions || loading) return;

    setLoading(true);
    await delay(200);
    const result = await loadSessions(quotaId);
    setSessions(result);
    setLoading(false);
  };

  return (
    <Popover placement='left' content={<PreviousSession loading={loading} sessions={sessions} />}>
      <ExclamationCircleOutlined onMouseOver={handleMouseOver} />
    </Popover>
  );
};

import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import { dateFormatter, numberFormatter, priceFormatter } from '../../utils';
import { QuotaIncreasedPriceRow } from './QuotaIncreasedPrice.types';
import './QuotaIncreasedPrice.less';

interface QuotaIncreaseProps {
  loading: boolean;
  sessions: QuotaIncreasedPriceRow[] | null;
}

export const QuotaIncreasedPrice: FunctionComponent<QuotaIncreaseProps> = ({
  loading,
  sessions,
}) => {
  if (loading) {
    return (
      <div className='quota-increased-price-loading'>
        <Spin size='small' tip='Loading' />
      </div>
    );
  }

  if (!sessions || sessions.length === 0 || sessions[0].logOnDistri == null) {
    return <div>No increased price</div>;
  }

  return (
    <div className='quota-increased-price-container'>
      {sessions?.map(({ currencyId, logOnDistri, name, currentPrice, quotaId }) => {
        return (
          <div className='quota-increased-price' key={quotaId}>
            <span className='quota-increased-price-item'>
              {logOnDistri} {priceFormatter(currentPrice)} {name}
            </span>
          </div>
        );
      })}
    </div>
  );
};

import * as React from 'react';
import { Table } from 'antd';
import { EmsPageStore } from './EmsPageStore';
import { observer } from 'mobx-react';
import { EmsRow } from './EmsPage.types';
import { booleanSorter, dateFormatter, dateSorter, stringSorter } from '../../utils';
import { Project, SapVendor } from '../../api/types';
import { EmsRowActions } from './EmsRowActions';

interface PropsEmsTable {
  store: EmsPageStore;
}

export const EmsTable: React.FC<PropsEmsTable> = observer(({ store }) => {
  const { table } = store;
  const rowSelection = {
    type: 'checkbox' as const,
    selectedRowKeys: table.checkedRows.map(({ code }) => code),
    getCheckboxProps: (record: EmsRow) => ({
      name: String(record.code),
      disabled: false,
    }),
    onChange: (_selectedRowKeys: React.Key[], selectedRows: EmsRow[]) => {
      table.setCheckedRows(selectedRows);
    },
  };
  return (
    <Table<EmsRow>
      size='small'
      rowKey='code'
      dataSource={table.data}
      loading={table.isLoading}
      pagination={table.paginator}
      rowSelection={rowSelection}
    >
      <Table.Column
        title='EMS Name'
        dataIndex='name'
        key='name'
        width='15%'
        sorter={(a: EmsRow, b: EmsRow) => stringSorter(a.name, b.name)}
      />
      <Table.Column title='EMS Code' dataIndex='code' key='code' width={100} />
      <Table.Column
        title='Status'
        dataIndex='emsOpened'
        key='emsOpened'
        width={100}
        sorter={(a: EmsRow, b: EmsRow) => booleanSorter(a.emsOpened, b.emsOpened)}
        render={(emsOpened: boolean) => (emsOpened ? 'Opened' : 'Closed')}
      />
      <Table.Column
        title='Contacts'
        dataIndex='email'
        key='email'
        width='15%'
        ellipsis
        render={(email: string[]) => email.join('; ')}
      />
      <Table.Column
        title='Projects'
        dataIndex='projects'
        key='projects'
        width='15%'
        ellipsis
        render={(value: Project[]) => (value ? value.map(({ name }) => name).join('; ') : '')}
      />
      <Table.Column
        title='SAP Vendors'
        dataIndex='sapVendors'
        key='sapVendors'
        width='15%'
        ellipsis
        render={(value: SapVendor[]) => (value ? value.map(({ name }) => name).join('; ') : '')}
      />
      <Table.Column title='Address' dataIndex='address' key='address' width='15%' ellipsis />
      <Table.Column
        title='Phone Number'
        dataIndex='phoneNumber'
        key='phoneNumber'
        width='15%'
        ellipsis
      />
      <Table.Column
        title='Contact Name'
        dataIndex='contactName'
        key='contactName'
        width='15%'
        ellipsis
      />
      <Table.Column
        title='BSH CKA Name'
        dataIndex='bshCkaName'
        key='bshCkaName'
        width='15%'
        ellipsis
      />
      <Table.Column
        title='Start Date'
        dataIndex='startDate'
        key='startDate'
        width='10%'
        render={(startDate: string) => dateFormatter(startDate)}
      />
      <Table.Column
        title='End Date'
        dataIndex='endDate'
        key='endDate'
        width='10%'
        sorter={(a: EmsRow, b: EmsRow) => dateSorter(a.endDate, b.endDate)}
        render={(endDate: string) => dateFormatter(endDate)}
      />
      <Table.Column
        title='Actions'
        key='actions'
        width={100}
        render={(row: EmsRow) => <EmsRowActions row={row} store={store} />}
      />
    </Table>
  );
});

import * as React from 'react';
import { Form, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { Rest } from '../api/Rest';
import { icmp } from '../common/icmp';

interface ManufacturerItem {
    manufacturerId: number;
    name: string;
    code: string;
    edmCode: string;
    key: string;
}

interface PropsManufacturerSelectLoA {
    name: string;
    label?: string;
    valueField?: keyof ManufacturerItem;
    rules?: Rule[];
    onChange?: (manufacturer: string) => void;
    disabled?: boolean;
    selectedYear?: string;
    loading?: boolean; // Add loading prop
}

export const ManufacturerSelectLoA: React.FC<PropsManufacturerSelectLoA> = ({
  name,
  label = 'Manufacturer',
  valueField = 'code',
    rules, disabled, selectedYear, loading , onChange
}) => {
    const [options, setOptions] = React.useState<ManufacturerItem[] | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false); // Add state for loading

    React.useEffect(() => {
        if (!disabled && selectedYear) {
            setIsLoading(true);
            const yearNumber = Number(selectedYear);
            Rest.get<ManufacturerItem[]>(`/api/v1/Manufacturer/filtersloa/${yearNumber}`).then(res => {
                res.sort((a, b) => icmp(a.name, b.name));
                setOptions(res);
                setIsLoading(false);
            });
        }
    }, [disabled, selectedYear]); 
  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        allowClear
              loading={loading} 
              showSearch
              disabled={ disabled}
              onChange={(value, option:any) => {
                  if (onChange) {
                      if (value === null || value === undefined) {
                          onChange('');
                      } else {
                          onChange(option.key);
                      }
                  }
              }}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
              filterSort={(a, b) => icmp(a.children, b.children)}
              placeholder="Start typing to find Manufacturer"
      >
        {options &&
                  options.map(it => (
                      <Select.Option key={it.code} value={it.name}>
              {it.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

import { Rest } from '../Rest';
import { LabelValue } from '../../common/types';

interface YearItem {
  yearId: number;
  name: string;
}

let cache: YearItem[] | null = null;

export const getYears = async (): Promise<YearItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: YearItem[] }>('/api/v1/Settings/cpl-years');
    cache = response.data;
  }
  return cache;
};

export const getBomYears = async (): Promise<YearItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: YearItem[] }>('/api/v1/Settings/cpl-years');
    cache = response.data;
  }
  return cache.filter(s => s.yearId !== 1 && s.yearId !== 2);
};

export const getCplYearOptions = async (): Promise<LabelValue[]> =>
  (await getYears()).map(({ yearId, name }) => ({
    id: yearId,
    label: name,
    value: name,
  }));

interface BundlingRow {
  bundlingId: number | null;
  bundlingName: string | null;
}

// helper for setting of 'bundlingId' field after editing of 'bundlingName'
export const setBundlingId = <T extends BundlingRow>(src: Partial<T>): Partial<BundlingRow> => {
  if (!src.bundlingName) {
    return { bundlingId: null, ...src };
  }
  if (!cache) return src;
  const bundling = cache.find(({ name }) => name === src.bundlingName);
  return bundling ? { bundlingId: bundling.yearId, ...src } : src;
};

import React, { FunctionComponent } from 'react';
import { ActionPermission } from '../../api/types';
import { Tooltip } from 'antd';

interface PermissionCheckProps {
  permission?: ActionPermission;
  disabled?: boolean;
  [key: string]: any;
}

export const PermissionCheck: FunctionComponent<PermissionCheckProps> = ({
  permission,
  children,
  disabled,
  ...props
}) => {
  if (!permission) {
    return null;
  }

  const child = React.Children.only(children);
  const { allow, reasons } = permission;
  const message = reasons.length ? reasons.map((reason, key) => <div key={key}>{reason}</div>) : '';

  if (React.isValidElement(child)) {
    const item = React.cloneElement(child, {
      style: allow ? {} : { pointerEvents: 'none' },
      disabled: !allow || disabled,
      ...props,
    });

    return (
      <Tooltip placement='left' title={message}>
        {/* the span wrapper is required for the tooltip to work correctly on a disabled component */}
        <span>{item}</span>
      </Tooltip>
    );
  }

  return null;
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { LOAExportStore } from '../LOA/LOAExportStore';
import { LOAExportMailStore } from '../LOA/LOAExportMailStore'
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Space } from 'antd';
import { formFooterLayout, formLayout } from '../../common/constants';
import { EmsSelectLoA } from '../../components/ems-select/ems-loa-select';
import { useEffect, useState } from 'react';
import { Rule } from 'antd/es/form';
import { getYears } from '../../api/cached/getYears';
import { getCurrentCampaign } from '../../api/cached/getCurrentCampaign';
import { DocumentType } from './LOA-add-line/DocumentType';
import { ManufacturerSelectLoA } from '../../components/ManufacturerSelectLoA';
import { TemplateSelect } from './LOA-add-line/TemplateSelect';
import { FreeText } from './LOA-add-line/FreeText';
import DefineDistributionField from './LOA-add-line/DefineDistributionField';
import DeliveryScheduleField from './LOA-add-line/DeliveryScheduleField';
import { Moment } from 'moment';
import { Rest } from '../../api/Rest';
import { EmsRow } from '../EmsPage/EmsPage.types';
import { drawErrorMessage } from '../../common/drawErrorMessage';

interface PropsLoaExport {
    store: LOAExportStore;
    storeMail: LOAExportMailStore
}
interface YearItem {
  yearId: number;
  name: string;
}
interface FormValues {
  defineDistri: string;
  deliverySchedule: string;
}

interface Fields {
  emsCode: string | null;
  emsName: string | null;
  // Add other fields as needed
  ft1: string;
  ft2: string;
  ft3: string;
}

let emsCache: string | undefined = undefined;
const initialValues = {
  documenttype: 'Letter of Authorization',
  paymentCondition: 'To be agreed between Pre-Supplier and Supplier',
  deliveryCondition: 'To be agreed between Pre-Supplier and Supplier',
  startDate: null,
  endDate: null,
};

const templateIdMap: Record<string, Record<string, number>> = {
  'Letter of Authorization': {
    'Version 1': 1,
    'Version 2 with 5%': 2,
    'Backup Version': 4,
  },
  'Agreement on Conditions': {
    'Version 1': 3,
    'Version 2 with 5%': 5,
    'Backup Version': 6,
  },
};


export const LoaExport: React.FC<PropsLoaExport> = observer(({ store, storeMail }) => {
    const [form] = Form.useForm();
    const [documentType, setDocumentType] = React.useState<string | undefined>();
    const [selectedTemplate, setSelectedTemplate] = React.useState<string>('Version 1');
    const [isPaymentConditionFilled, setIsPaymentConditionFilled] = React.useState(false);
    const [freeTextValues, setFreeTextValues] = React.useState<string[]>([]);
    const isVisible = store.isAddLine;
    const [isEmsDisabled, setIsEmsDisabled] = React.useState<boolean>(false);
    const [selectedManufacturer, setSelectedManufacturer] = React.useState('');
    const [emsCache1, setEmsCache] = React.useState(null);
    const [isManufacturerSelected, setIsManufacturerSelected] = React.useState(false);
    const [emsOptions, setEmsOptions] = React.useState<EmsRow[] | null>(null);
    const [isYearSelected, setIsYearSelected] = React.useState<boolean>(false);
    const [selectedYear, setSelectedYear] = React.useState('');
    const [isLoadingYears, setIsLoadingYears] = React.useState<boolean>(false);
    const [startDate, setStartDate] = useState<Moment | null>(null);
    const [endDate, setEndDate] = useState<Moment | null>(null);
    const [endDateError, setEndDateError] = useState<string | null>(null);
    const maxCharactersRulePay = { max: 500, message: 'Payment condition must be less than 500 characters' };
    const maxCharactersRuleDel = { max: 500, message: 'Delivery condition must be less than 500 characters' };
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [isSendingMail, setIsSendingMail] = useState<boolean>(false);

  const getEmsOptions = (manufacturerValue: string, selectedYear: string) => {
    const yearNumber = Number(selectedYear);
    Rest.get<{ data: EmsRow[] }>(`/api/v1/Ems/filtersloa/${yearNumber}/${manufacturerValue}`)
      .then(res => setEmsOptions(res.data))
      .catch(e => drawErrorMessage(e));
    return () => {};
  };

    const onSubmit = (values: Fields, action: string) => {
        const updatedValues = {
            ...values,
            templateId: templateIdMap[documentType!][selectedTemplate], // Get template ID from the map
            emsName: isEmsDisabled ? 'ALL' : values.emsName,
        };

        if (action === 'generate') {
            store.createNewLine(updatedValues);
        } else if (action === 'sendMail') {
            // Add the send mail functionality here
            sendMailFunction(updatedValues);
        }

        /*form.resetFields(); // Clear all form fields including dropdown values
        setIsEmsDisabled(true); // Disable EMS selection
        setEmsOptions(null); // Clear EMS options
        setSelectedManufacturer('');
        setIsYearSelected(false);
        setIsManufacturerSelected(false);*/
    };

    const sendMailFunction = (values: Fields) => {
        // Implement the send mail functionality here

        console.log('Sending mail with values:', values);
        storeMail.createNewLine(values);
    };

    const yearRules: Rule[] = [{ required: true, message: 'Year is required' }];
    const manufacturerRules: Rule[] = [{ required: true, message: 'Manufacturer is required' }];
    const emsRules: Rule[] = [{ required: true, message: 'EMS is required' }];
    const PaymentRules: Rule[] = [{ required: true, message: 'Payment condition is required' }, maxCharactersRulePay];

    const devieryConditionRules: Rule[] = [{ required: true, message: 'Delivery condition is required' }, maxCharactersRuleDel];

    const onCancel = () => {
        form.resetFields(); // Clear all form fields including dropdown values
        setIsEmsDisabled(true); // Disable EMS selection
        setEmsOptions(null); // Clear EMS options
        setSelectedManufacturer('');
        setIsYearSelected(false);
        store.closeAddLine();
        setIsManufacturerSelected(false);
    };

  const [options, setOptions] = React.useState<YearItem[]>();
  React.useEffect(() => {
    setIsLoadingYears(false); // Set loading state to true

    const fetchYears = async () => {
      // Fetch years from the API
      const allYears = await getYears();

      // Get the current year
        const nCurYear = await getCurrentCampaign();

            // Filter out the current year and the previous year
            const filteredYears = allYears.filter(year => {
                const yearNumber = parseInt(year.name);
                return yearNumber === nCurYear.year || yearNumber === nCurYear.year - 1;
            });
            setOptions(filteredYears);
        };
        fetchYears();
    }, []);
    useEffect(() => {
        if (!isVisible) {
            form.resetFields();
        }
    }, [form, isVisible]);

    const handleYearChange = (value: string) => {
        if (value) {
            setIsYearSelected(true);
            setSelectedYear(value);
            setIsManufacturerSelected(false);
        } else {
            setIsYearSelected(false);
            setIsManufacturerSelected(false);
            setSelectedManufacturer('');
            setEmsOptions(null);
            setIsEmsDisabled(true);
        }
        form.resetFields(['manufacturerName', 'emsName']);
    }

  const handleSelectDocumentType = (value: string) => {
    setDocumentType(value);
    form.resetFields(['freeText2', 'freeText3', 'freeText1']);
    switch (value) {
      case 'Letter of Authorization':
        form.resetFields(['deliverySchedule']);

        break;
      case 'Agreement on Conditions':
        form.resetFields(['definedDistribution']);
        break;
      default:
    }
    setIsEmsDisabled(value === 'Agreement on Conditions');
    if (value === 'Agreement on Conditions') {
      form.resetFields(['emsName']);
    }
  };

    function freeTextChanges(isCorrect: boolean): void {
        // Placeholder: No implementation for now
    }

    const handleSelectTemplate = (value: string) => {
        setSelectedTemplate(value); // Update selected template
        switch (value) {
            case 'Version 1':
                if (documentType === 'Letter of Authorization') {
                    form.resetFields(['freeText2', 'freeText3']);
                } else {
                    form.resetFields(['freeText3']);
                }
                break;
            case 'Version 2 with 5%':
                if (documentType === 'Letter of Authorization') {
                    form.resetFields(['freeText3']);
                } else {
                    form.resetFields(['freeText3']);
                }
                break;
            case 'Backup Version':
                if (documentType === 'Letter of Authorization') {
                    form.resetFields(['freeText3']);
                } else {
                    form.resetFields(['freeText3']);
                }
                break;
            default:
        }
    };

    const handleFreeTextValuesChange = (values: string[]) => {
        setFreeTextValues(values);
    };

    const validateStartDate = (_rule: any, value: Moment | null) => {
        if (!value || !endDate || value <= endDate) {
            return Promise.resolve();
        }
        return Promise.reject('Start date cannot be greater than end date');
    };

    const handleStartDateChange = (value: Moment | null) => {
        setStartDate(value);
        form.validateFields(['endDate']);
    };

    const validateEndDate = (_rule: any, value: Moment | null) => {
        if (!value || !startDate || value >= startDate) {
            setEndDateError(null);
            return Promise.resolve();
        }
        setEndDateError('End date must be after start date');
        return Promise.reject('End date must be after start date');
    };

    const handleEndDateChange = (value: Moment | null) => {
        setEndDate(value);
        form.validateFields(['startDate']);
    };

    const handleManufacturerChange = (value: any) => {
        if (value) {
            setSelectedManufacturer(value);
            setIsManufacturerSelected(true);
            getEmsOptions(value, selectedYear);
        } else {
            setIsManufacturerSelected(false);
        }
        form.resetFields(['emsName']);
    };

    const isDefineDistriRequired = documentType === 'Letter of Authorization';
    const isDeliveryScheduleRequired = documentType === 'Agreement on Conditions';

    // Wrapper function for onSubmit
    const handleFormSubmit = (values: Fields) => {
        onSubmit(values, 'generate'); // Default action is generate
    };

    return (
        <Modal
            destroyOnClose={true}
            visible={isVisible}
            title='Generate LoA'
            footer={null}
            onCancel={onCancel}
            maskClosable={false}
            bodyStyle={{ maxHeight: '72vh', overflowY: 'auto' }}
        >
            <Form<Fields>  {...formLayout} onFinish={handleFormSubmit} form={form} initialValues={initialValues}>
                <Form.Item label='Year' name='year' rules={yearRules}>
                    <Select allowClear placeholder="Select Year" onChange={handleYearChange}>
                        {options &&
                            options.map(rec => (
                                <Select.Option key={rec.yearId} value={rec.name}>
                                    {rec.name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
                <ManufacturerSelectLoA name='manufacturerName' loading={isLoadingYears} label='Manufacturer' rules={manufacturerRules} onChange={handleManufacturerChange} disabled={!isYearSelected} selectedYear={selectedYear} />
                <Form.Item
                    label='EMS'
                    name='emsName'
                    rules={!isDeliveryScheduleRequired ? [{ required: true, message: 'EMS is required' }] : undefined}
                >
                    <Select
                        showSearch
                        allowClear
                        disabled={!isManufacturerSelected || isDeliveryScheduleRequired}
                        placeholder='Start typing to find EMS'
                        value={emsCache} >
                        {emsOptions?.map(ems => (
                            <Select.Option value={ems['name']} key={ems.name}>
                                {ems.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <DocumentType onSelectDocumentType={handleSelectDocumentType} />
                <TemplateSelect onSelectTemplate={handleSelectTemplate} />
                <Form.Item label='Payment condition' name='paymentCondition' rules={PaymentRules}>
                    <Input defaultValue="To be agreed between Pre-Supplier and Supplier" autoComplete='off' />
                </Form.Item>
                <Form.Item name='startDate' label='Validity start' rules={[{ required: true, message: 'Start date is required' }, { validator: validateStartDate }]}>
                    <DatePicker onChange={handleStartDateChange} />
                </Form.Item>
                <Form.Item name='endDate' label='Validity end' rules={[{ required: true, message: 'End date is required' }, { validator: validateEndDate }]}>
                    <DatePicker onChange={handleEndDateChange} />
                </Form.Item>
                <Form.Item label='Delivery condition' name='deliveryCondition' rules={devieryConditionRules} >
                    <Input defaultValue="To be agreed between Pre-Supplier and Supplier" autoComplete='off' />
                </Form.Item>
                <DefineDistributionField isRequired={isDefineDistriRequired} />
                <DeliveryScheduleField isRequired={isDeliveryScheduleRequired} />

        <FreeText
          onChange={freeTextChanges}
          documentType={documentType}
          selectedTemplate={selectedTemplate}
        />
        <Divider />

                <Form.Item {...formFooterLayout}>
                    <Space>
                        <Button type='default' htmlType='reset' onClick={onCancel}>
                            Cancel
                        </Button>
                        <Form.Item noStyle shouldUpdate>
                            {() => {
                                const isDefineDistributionEnabled = documentType === 'Letter of Authorization';
                                const isDeliveryScheduleEnabled = documentType === 'Agreement on Conditions';
                                const errors = form.getFieldsError();
                                const isManufacturer = form.isFieldTouched(['manufacturerName']);
                                const isEMS = form.isFieldTouched(['emsName']);
                                const isYear = form.isFieldTouched(['year']);
                                const defineDistriValue = form.getFieldValue('definedDistribution');
                                const deliveryScheduleValue = form.getFieldValue('deliverySchedule');
                                const hasErrors = errors.some(({ errors }) => errors.length > 0);
                                const isDisabled =
                                    !isManufacturer ||
                                    hasErrors ||
                                    (!isEMS && !isDeliveryScheduleRequired) ||
                                    !isYear ||
                                    !selectedTemplate ||
                                    (isDefineDistributionEnabled && !defineDistriValue) ||
                                    (isDeliveryScheduleEnabled && !deliveryScheduleValue);
                                return (
                                    <Space>
                                        <Button
                                            type="primary"
                                            htmlType="button" // Change to button to prevent form submission
                                            onClick={() => onSubmit(form.getFieldsValue(), 'sendMail')}
                                            disabled={isDisabled}
                                        //loading={storeMail.isAddLineSaving}
                                        >
                                            Send mail
                                        </Button>
                                        <Button
                                            type="primary"
                                            htmlType="button" // Change to button to prevent form submission
                                            onClick={() => onSubmit(form.getFieldsValue(), 'generate')}
                                            disabled={isDisabled}
                                            loading={store.isAddLineSaving}
                                        >
                                            Generate
                                        </Button>
                                    </Space>
                                );
                            }}
                        </Form.Item>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
});

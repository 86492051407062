/** Download (export) of exel table */
import * as React from 'react';
import './TableExport.less';
import { Alert, Spin } from 'antd';
import { observer } from 'mobx-react';
import { TableExportStore } from './TableExportStore';
import { formatErrorMessage } from '../../api/utils/error-message-formatter';

interface PropsTableExport {
  store: TableExportStore;
}

export const TableExport: React.FC<PropsTableExport> = observer(({ store }) => {
  const { status } = store;
  return (
    <div className='table-export-box'>
      {status === 'wait' && (
        <>
          <span className='table-export-wait-msg'>Data preparation in progress...</span>
          <Spin />
        </>
      )}
      {status === 'ready' && (
        <a href={store.url} target='_blank' rel='noreferrer' onClick={() => store.close()}>
          Click here to download the file
        </a>
      )}
      {status === 'error' && (
        <Alert
          showIcon
          message='Error'
          description={formatErrorMessage(store.lastError!)}
          type='error'
        />
      )}
    </div>
  );
});

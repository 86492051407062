import { LoaderStore } from '../common/LoaderStore';
import { Rest } from '../api/Rest';
import { ActionItem } from '../api/types';
import { MenuItem, menuItems } from '../components/header-menu/constants';
import { findFirstItem } from './findFirstItem';

class MainFrameStore extends LoaderStore {
  actions: ActionItem[] = [];

  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([
        Rest.get<ActionItem[]>('/api/v1/Settings/actions').then(res => (this.actions = res)),
      ])
        .then(() => this.onReady())
        .catch(e => this.onError(e));
    }
  }

  get homeMenuItem(): MenuItem | undefined {
    return findFirstItem(menuItems, this.actions);
  }

  get menuPermissions(): Record<string, boolean> {
    return this.actions.reduce(
      (result, { name, permission }) => ({
        ...result,
        [name]: permission.allow,
      }),
      {}
    );
  }
}

export const appStore = new MainFrameStore();

import { TwoWayAdapter } from '../store/utils/adapter-types';
import { Quota } from '../store/features/quota/quota-types';

class QuotaAdapter implements TwoWayAdapter<{ data: Quota[] }, Quota[]> {
  forward(item: { data: Quota[] }) {
    return item.data;
  }
}

export const quotaAdapter = new QuotaAdapter();

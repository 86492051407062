import React, { FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import {
  fetchSession,
  selectSessionStatus,
  selectUserInfo,
} from '../../store/features/session/session-slice';
import { useAppDispatch } from '../../hooks/dispatch';
import { Spinner } from '../spinner/spinner';
import { Forbidden } from '../Forbidden/Forbidden';

Auth.configure({
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    region: process.env.REACT_APP_OAUTH_REGION,
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: process.env.REACT_APP_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT,
    responseType: 'code',
  },
});

export const BootstrapApp: FunctionComponent = props => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchSession());
  }, [dispatch]);

  const { loaded } = useSelector(selectSessionStatus);
  const { groups } = useSelector(selectUserInfo);

  if (loaded && !groups) {
    return <Forbidden />;
  }

  return <>{loaded ? props.children : <Spinner fillParent />}</>;
};

import * as React from 'react';
import { MYAPageStore } from './MYAPageStore';
import { MYARow } from './MYA.types';
import { observer } from 'mobx-react';
import { PermissionCheck } from '../../components/PermissionCheck';
import { Button, Modal, Space } from 'antd';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface PropsMYAActions {
  row: MYARow;
  store: MYAPageStore;
}

export const MYAActions: React.FC<PropsMYAActions> = observer(({ row, store }) => {
  const onEdit = () => {
    const data = { ...row };
    data.startDate = moment(data.startDate);
    data.endDate = moment(data.endDate);
    store.edit.activate(data);
  };
  const onDelete = () => {
    const { destroy } = Modal.confirm({
      title: `MYA will be deleted`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: async () => {
        destroy();
        store.table.deleteRow(row);
      },
    });
  };
  return (
    <Space>
      <PermissionCheck permission={row.actions.edit} type='link' onClick={onEdit}>
        <Button>Edit</Button>
      </PermissionCheck>
      <PermissionCheck permission={row.actions.delete} type='link' onClick={onDelete}>
        <Button>Delete</Button>
      </PermissionCheck>
    </Space>
  );
});

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal, notification, Space } from 'antd';
import { useState } from 'react';
import { observer } from 'mobx-react';
import React, { ReactElement, useCallback } from 'react';
import { Rest } from '../../../api/Rest';
import { EmsSelect } from '../../../components/ems-select/ems-select';
import { DemandRow } from '../DemandPage.types';
import { DemandPageStore } from '../DemandPageStore';

interface PropsBulkEMSChange {
  store: DemandPageStore;
  visible: boolean;
  onClose(): void;
}

const initialValues = { emsCode: '' };

export const BulkEMSChange: React.FC<PropsBulkEMSChange> = observer(
  ({ store, visible, onClose }) => {
    const title = 'Change EMS for selected';
    const [uploadBulkEmsChangeResult, setuploadBulkEmsChangeResult] = React.useState<boolean>(true);
    const [bulkEMSChangeMsg, setbulkEMSChangeMsg] = React.useState<string[]>([]);
    const handleCancel = () => {
      onClose();
        setbulkEMSChangeMsg([]);
        setDisable(false);
        handleClick3();
        setHidden(false);
    };
        const [disable, setDisable] = React.useState(false);
        const [hidden, setHidden] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const callApi = (emsCode: string) => {
      interface Body {
        emsCode: string;
        demands: DemandRow[];
      }
      const body: Body = {
        emsCode,
        demands: store.table.checkedRows,
      };

      Rest.post<string[], Body>('/api/v1/Demand/bulk/changeems', body)
        .then(res => {
          setuploadBulkEmsChangeResult(true);
          setbulkEMSChangeMsg(res);
        })
        .finally();
        };
        const [buttonText, setButtonText] = useState('Ok');

        function handleClick() {
            setButtonText('Please wait...');
        }
        function handleClick2() {
            setHidden(true);
        }
        function handleClick3() {
            setButtonText('Ok');
        }
    const onInternalChange = (newValue: string) => {
      initialValues.emsCode = newValue;
    };

    function jsxJoin(array: DemandRow[]): string {
      var result = '';
      array.map(({ bshPartNo }) => {
        result += `${bshPartNo}\n `;
      });

      return result;
    }
        
        const onConfirm = () => {
          
            handleClick();
      const { destroy } = Modal.confirm({
        title: 'Are you sure?',
        icon: <ExclamationCircleOutlined />,
        content: `EMS will be changed to new EMS ${
          initialValues.emsCode
        } for below BSH Parts:${jsxJoin(store.table.checkedRows)}`,
        onOk: async () => {
          destroy();
            callApi(initialValues.emsCode);
            setDisable(true);
        
        },
        okText: 'Yes',
      });
            setLoading(true);
    };

        function generateResponseMessage(list: string[], success: boolean): string {
      var res: string = '';
      list
        .filter(a => a.includes(success ? 'Success' : 'already exists'))
        .map(part => {
            res += `\r${part}\n`;
        });
            if (loading === true) {
                setLoading(false);
                handleClick2();
            }
            return res;
           
    }
        
    return (
      <>
        {uploadBulkEmsChangeResult && (
          <Modal
            visible={visible}
            //onOk={handleOk}
            onCancel={handleCancel}
            title={title}
            maskClosable={false}
            destroyOnClose
            footer={[
              <Space>
                    <Button type='primary' loading={loading} hidden={hidden} disabled={disable} onClick={() => onConfirm()}>
                        {buttonText}
                </Button>
                <Button key='close' onClick={handleCancel}>
                  Close
                </Button>
              </Space>,
            ]}
          >
            {bulkEMSChangeMsg.length == 0 && (
              <Form initialValues={initialValues}>
                <Form.Item label='Select EMS: ' name='emsCode'>
                  <EmsSelect onChange={onInternalChange} />
                </Form.Item>
              </Form>
            )}

            {bulkEMSChangeMsg.length > 0 && (
                        <>                                   
                <Alert
                                closable
                                style={{ whiteSpace: 'pre-line', lineHeight: '14px'}}
                  message='Success'
                  description={generateResponseMessage(bulkEMSChangeMsg, true)}
                  type='success'
                  showIcon
                />
                            <Alert
                                style={{ whiteSpace: 'pre-line', lineHeight: '14px' }}
                                closable
                                message='Error'
                                description={generateResponseMessage(bulkEMSChangeMsg, false)}
                                type='error'
                                showIcon
                />
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
);

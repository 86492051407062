import * as React from 'react';
import './EdmDataPage.less';
import { TableUploaderModal } from '../../components/TableUploader';
import { ButtonsPageContent } from '../../components/ButtonsPageContent';

export const EdmDataPage: React.FC = () => {
  const [modal, setModal] = React.useState<'' | 'EdmPart' | 'EdmPreSupplier'>('');
  const closeModal = () => setModal('');
  return (
    <div className='edm-data-page'>
      <h1>EDM Data</h1>
      <ButtonsPageContent
        buttons={[
          { text: 'Import EDM components', permission: true, onClick: () => setModal('EdmPart') },
          {
            text: 'Import EDM pre-supplier',
            permission: true,
            onClick: () => setModal('EdmPreSupplier'),
          },
        ]}
      />

      {modal === 'EdmPart' && (
        <TableUploaderModal
          visible
          title='Import EDM components'
          importType='EdmPart'
          onClose={closeModal}
          store={null}
        />
      )}
      {modal === 'EdmPreSupplier' && (
        <TableUploaderModal
          visible
          title='Import EDM pre-supplier'
          importType='EdmPreSupplier'
          onClose={closeModal}
          store={null}
        />
      )}
    </div>
  );
};

/** Cache for Current /api/v1/Campaign/current */
import { Rest } from '../Rest';

export interface CurrentCampaignInfo {
  year: number;
  statusId: number;
  statusName?: string;
  emsCount: number;
}

let cache: CurrentCampaignInfo | null = null;

export const getCurrentCampaign = async (): Promise<CurrentCampaignInfo> => {
  if (!cache) {
    cache = await Rest.get<CurrentCampaignInfo>('/api/v1/Campaign/current');
  }
  return cache;
};

export const clearCurrentCampaign = () => {
  cache = null;
};

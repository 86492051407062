import * as React from 'react';
import { observer } from 'mobx-react';
import { DatePicker, Form } from 'antd';
import { EmsPageStore } from './EmsPageStore';
import { ModalForm } from '../../components/ModalForm';
import { Rule } from 'antd/es/form';
import { requiredRule } from '../../common/constants';
import moment, { Moment } from 'moment';

interface PropsEmsSetEndDate {
  store: EmsPageStore;
}

const endDateRules: Rule[] = [requiredRule];

// End date can’t be less than tomorrow
const disabledDate = (current: Moment) => current < moment();

export const EmsSetEndDate: React.FC<PropsEmsSetEndDate> = observer(({ store }) => {
  const { setEndDate } = store;
  return (
    <ModalForm
      title='Set End Date'
      name='setEndDate'
      visible={setEndDate.visible}
      saving={setEndDate.saving}
      layout={{ labelCol: { span: 8 }, wrapperCol: { span: 16 } }}
      onClose={setEndDate.close}
      onSave={setEndDate.save}
      initialData={setEndDate.data}
    >
      <Form.Item label='End date' name='endDate' rules={endDateRules}>
        <DatePicker allowClear={false} showToday={false} disabledDate={disabledDate} />
      </Form.Item>
    </ModalForm>
  );
});

import * as React from 'react';

export const nestedKeyboardHandler = (onCancel: () => void, onFinish: () => void) => {
  return (e: React.KeyboardEvent) => {
    if (e.code === 'Escape') {
      onCancel();
    } else if (e.code === 'Enter') {
      onFinish();
    }
  };
};

import { ActionItem, ActionPermission } from '../api/types';

export const addNoItemsPermission = (
  hasItems: boolean,
  permission?: ActionPermission,
  message = 'No items selected'
) => {
  if (!permission || hasItems) {
    return permission;
  }

  return {
    allow: false,
    reasons: [message, ...permission.reasons],
  };
};

export const findPermission = (
  actions: ActionItem[],
  name: string
): ActionPermission | undefined => {
  return actions.find(currentAction => currentAction?.name === name)?.permission;
};

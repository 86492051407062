import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Table, Button } from 'antd';
import { LoaderBox } from '../../components/LoaderBox';
import { CommodityRow } from './CommodityPage.types';
import { CommodityPageStore } from './CommodityPageStore';
import { EditCommodity } from './EditCommodity';
import { PermissionCheck } from '../../components/PermissionCheck/PermissionCheck';
import { CommodityMenu } from './CommodityMenu';

const { Column } = Table;

const pageStore = new CommodityPageStore();

export const CommodityPage: React.FC = observer(() => {
  const { table } = pageStore;
  const { form } = table;
  React.useEffect(() => pageStore.init(), []);
  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>Commodity</h1>
        </Col>
        <Col>
          <CommodityMenu store={pageStore} />
        </Col>
      </Row>
      <Table<CommodityRow>
        size='small'
        rowKey='commodityId'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
      >
        <Column title='Code' key='code' dataIndex='code' />
        <Column title='GPMT' key='gpmt' dataIndex='gpmt' />
        <Column title='Commodity Name' key='nameEng' dataIndex='nameEng' />
        <Column title='Assigned Negotiator' key='negotiatorNick' dataIndex='negotiatorNick' />
        <Column
          title='Action'
          key='Action'
          render={(_value, _record, index) => (
            <PermissionCheck
              permission={pageStore.canEdit}
              type='link'
              name='edit'
              onClick={() => table.onEditStart(index)}
            >
              <Button>Edit</Button>
            </PermissionCheck>
          )}
        />
      </Table>
      <EditCommodity formStore={form} negotiators={pageStore.negotiators} />
    </LoaderBox>
  );
});

import * as React from 'react';
import { Button } from 'antd';
import { ActionPermission } from '../../api/types';
import { PermissionCheck } from '../PermissionCheck';

import './ButtonsPageContent.less';

interface ButtonItem {
  key?: string;
  text: string;
  permission?: ActionPermission | boolean;
  onClick(): void;
}

interface PropsButtonsPageContent {
  buttons: ButtonItem[];
}

const defaultPermission: ActionPermission = { allow: true, reasons: [] };

const makePermission = (src?: ActionPermission | boolean): ActionPermission | undefined => {
  if (!src) return undefined;
  if (src === true) return defaultPermission;
  return src;
};

export const ButtonsPageContent: React.FC<PropsButtonsPageContent> = ({ buttons }) => (
  <div className='buttons-page-content'>
    {buttons.map(button => (
      <PermissionCheck
        permission={makePermission(button.permission)}
        key={button.key || button.text}
        onClick={button.onClick}
      >
        <Button type='primary' size='large' className='buttons-page-item'>
          {button.text}
        </Button>
      </PermissionCheck>
    ))}
  </div>
);

import { Action, configureStore } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './root-reducer';

export const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

// Motivation:
// https://redux-toolkit.js.org/tutorials/advanced-tutorial#logic-for-fetching-github-repo-details
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

import { Rest } from '../Rest';

export interface IncotermItem {
  incotermId: number;
  name: string;
}

let cache: IncotermItem[];

export const getIncoterms = async (): Promise<IncotermItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: IncotermItem[] }>('/api/v1/Settings/incoterm');
    cache = response.data;
  }
  return cache;
};

import * as React from 'react';
import { Spin, Alert } from 'antd';
import { observer } from 'mobx-react';
import { LoaderStore } from '../../common/LoaderStore';
import './LoaderBox.less';
import { Forbidden } from '../Forbidden/Forbidden';

export interface PropsLoaderBox {
  store: LoaderStore;
  style?: React.CSSProperties;
}
export const LoaderBox: React.FC<PropsLoaderBox> = observer(({ store, style, children }) => (
  <div className={`loader-box loader-box-${store.loaderStatus}`} style={style}>
    {store.loaderStatus === 'wait' && <Spin size='large' />}
    {store.loaderStatus === 'error' && !store.isForbidden && (
      <Alert message={store.errorMessage || 'Error'} type='error' />
    )}
    {store.loaderStatus === 'error' && store.isForbidden && <Forbidden />}
    {store.loaderStatus === 'ready' && children}
  </div>
));

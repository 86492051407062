import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { DemandPageStore } from './DemandPageStore';
import { DemandRow } from './DemandPage.types';
import { numberFormatter } from '../../utils';
import { Project } from '../../store/utils/state-types';
import { DemandActions } from './DemandActions';
import { LockOutlined } from '@ant-design/icons';
import { DemandEdit } from './DemandEdit';
import { DemandQuotaTable } from './DemandQuota';
import './DemandTable.less';
import { BulkEMSChange } from './bulk-ems-change/bulk-ems-change';

interface PropsDemandTable {
  store: DemandPageStore;
}

const rowClassName = (record: DemandRow): string =>
  [
    ...(record.isLocked ? ['demand-row-locked'] : []),
    ...(record.isNew ? ['demand-row-new'] : []),
  ].join(' ');

export const DemandTable: React.FC<PropsDemandTable> = observer(({ store }) => {
  const { table } = store;
  const rowSelection = store.allowBulk
    ? {
        type: 'checkbox' as 'checkbox',
        selectedRowKeys: table.checkedRows.map(({ demandId }) => demandId),
        getCheckboxProps: (record: DemandRow) => ({
          name: String(record.demandId),
          disabled: false,
        }),
        onChange: (_selectedRowKeys: React.Key[], selectedRows: DemandRow[]) => {
          table.setCheckedRows(selectedRows);
        },
      }
    : undefined;

  return (
    <>
      <Table<DemandRow>
        size='small'
        rowKey='demandId'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
        onChange={table.onTableChange}
        className='demand-table'
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: record => <DemandQuotaTable store={table.quotas[record.demandId]} />,
          onExpand: (expanded, record) => table.onExpand(expanded, record),
          expandedRowKeys: table.expandedRowKeys,
        }}
      >
        <Table.Column
          title='BSH Part No'
          key='bshPartNo'
          dataIndex='bshPartNo'
          width='9%'
          sorter
          ellipsis
          render={(_, record: DemandRow) => {
            if (record.isNew) {
              return <>@ {record.bshPartNo}</>;
            }

            if (record.isLocked) {
              return (
                <>
                  <LockOutlined /> {record.bshPartNo}
                </>
              );
            }

            return record.bshPartNo;
          }}
        />
        <Table.Column
          title='Designation'
          dataIndex='designation'
          key='designation'
          sorter
          ellipsis
          width='7%'
        />
        <Table.Column title='EMS' dataIndex='emsCode' key='emsCode' sorter width='4.5%' />
        <Table.Column
          title='Demand'
          dataIndex='demand'
          key='demand'
          sorter
          width='7%'
          render={value => numberFormatter(value)}
        />
        <Table.Column
          title='EMS Part No'
          dataIndex='emsPartNo'
          key='emsPartNo'
          sorter
          width='7%'
          ellipsis
        />
        <Table.Column
          title='Projects'
          dataIndex='projects'
          key='projects'
          width='6.5%'
          ellipsis
          render={(value: Project[]) => (value ? value.map(({ name }) => name).join(', ') : '')}
        />
        <Table.Column
          title='Code/GPMT'
          key='commodityCode'
          width='8%'
          render={row => `${row.commodityCode || ''} ${row.commodityGpmt || ''}`}
        />
        <Table.Column
          title='Commodity Name'
          dataIndex='commodityName'
          key='commodityName'
          width='10%'
        />
        <Table.Column
          title='Prev demand'
          dataIndex='prevDemand'
          key='prevDemand'
          width='9%'
          render={value => numberFormatter(value)}
        />
        <Table.Column title='Source' dataIndex='sourceName' key='sourceName' width='11.5%' />
        <Table.Column
          title='Actions'
          key='actions'
          width='12%'
          render={row => <DemandActions row={row} store={store} />}
        />
      </Table>
      <DemandEdit formStore={table.edit} />
    </>
  );
});

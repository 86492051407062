import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestState } from '../../utils/state-types';
import { getRequestInitialState } from '../../utils/request-slice-utils';
import { AppThunk } from '../../index';
import { demand } from '../../../api';
import { RootState } from '../../root-reducer';
import { RequestStatuses } from '../../utils/request-constants';
import { Quota } from './quota-types';

const initialState: Record<number, RequestState<Quota[]>> = {};

export const quotaSlice = createSlice({
  name: 'quota',
  initialState,
  reducers: {
    request(state, { payload }: PayloadAction<number>) {
      if (!state[payload]) {
        state[payload] = getRequestInitialState([]);
      }
      state[payload].status = RequestStatuses.request;
    },

    requestSuccess(state, { payload }: PayloadAction<{ demandId: number; data: Quota[] }>) {
      const quotaState = state[payload.demandId];
      quotaState.status = RequestStatuses.requestSuccess;
      quotaState.data = payload.data;
    },

    requestError(state, { payload }: PayloadAction<number>) {
      state[payload].status = RequestStatuses.error;
    },
  },
});

export default quotaSlice.reducer;

export const fetchQuota = (demandId: number): AppThunk => async dispatch => {
  try {
    dispatch(quotaSlice.actions.request(demandId));
    const quota = await demand.getQuota(demandId);
    dispatch(
      quotaSlice.actions.requestSuccess({
        demandId,
        data: quota,
      })
    );
  } catch (err) {
    dispatch(quotaSlice.actions.requestError(demandId));
    console.log(err);
  }
};

export const selectQuota = (demandId: number) => (state: RootState) => {
  return state.quota[demandId];
};

import { ApnRow } from './types';
import { Rest } from '../../api/Rest';

interface ApnStore {
  modifyRowExt(key: keyof ApnRow, row: ApnRow): void;
}

export const updateApnRow = (rowPart: Partial<ApnRow>, store: ApnStore): Promise<void> =>
  Rest.get<ApnRow>(`/api/v1/apn/Quotation/${rowPart.quotaId}`).then(response =>
    store.modifyRowExt('quotaId', response)
  );

export const updateShareDistributionRow = (
  rowPart: Partial<ApnRow>,
  store: ApnStore
): Promise<void> =>
  Rest.get<ApnRow>(`/api/v1/apn/Distribution/${rowPart.quotaId}`).then(response =>
    store.modifyRowExt('quotaId', response)
  );

import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { ComponentPriceListPageStore } from './ComponentPriceListPageStore';
import { ComponentPriceListRow } from './ComponentPriceListPage.types';
import { dateSorter, numberFormatter } from '../../utils';
import { ComponentPriceListActions } from './ComponentPriceListActions';
import { LockOutlined } from '@ant-design/icons';
import { ComponentPriceListEdit } from './ComponentPriceListEdit';
import { ComponentPriceListQuotaTable } from './ComponentPriceListQuota';
import './ComponentPriceListTable.less';
import { defaultCurrencyStore } from './DefaultCurrencyStore';

interface PropsDemandTable {
  store: ComponentPriceListPageStore;
}

const rowClassName = (record: ComponentPriceListRow): string =>
  [
    ...(record.isLocked ? ['demand-row-locked'] : []),
    ...(record.isNew ? ['demand-row-new'] : []),
  ].join(' ');
const commodityCell = (rec: ComponentPriceListRow) => {
  if (rec.priceType != 'APN') {
    return 'n/a';
  } else {
    return `${rec.commodityCode || ''} ${rec.commodityGpmt || ''} ${rec.commodityName || ''}`;
  }
};

export const ComponentPriceListTable: React.FC<PropsDemandTable> = observer(({ store }) => {
  const { table, bundlingOptions } = store;
  const { defaultCurrency } = defaultCurrencyStore;
  const rowSelection = store.allowBulk
    ? {
        type: 'checkbox' as 'checkbox',
        selectedRowKeys: table.checkedRows.map(({ demandId }) => demandId),
        getCheckboxProps: (record: ComponentPriceListRow) => ({
          name: String(record.demandId),
          disabled: false,
        }),
        onChange: (_selectedRowKeys: React.Key[], selectedRows: ComponentPriceListRow[]) => {
          table.setCheckedRows(selectedRows);
        },
      }
    : undefined;

  return (
    <>
      <Table<ComponentPriceListRow>
        size='small'
        rowKey='demandId'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
        onChange={table.onTableChange}
        className='demand-table'
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: record => (
            <ComponentPriceListQuotaTable store={table.quotas[record.demandId]} />
          ),
          onExpand: (expanded, record) => table.onExpand(expanded, record),
          expandedRowKeys: table.expandedRowKeys,
        }}
      >
        <Table.Column
          title='BSH Part No'
          key='bshPartNo'
          dataIndex='bshPartNo'
          width='10%'
          render={(_, record: ComponentPriceListRow) => {
            if (record.isNew) {
              return <>@ {record.bshPartNo}</>;
            }

            if (record.isLocked) {
              return (
                <>
                  <LockOutlined /> {record.bshPartNo}
                </>
              );
            }

            return record.bshPartNo;
          }}
        />
        <Table.Column
          title='APN finalized'
          dataIndex='isCompleted'
          key='isCompleted'
          width='5%'
          render={(_, record: ComponentPriceListRow) => {
            if (record.priceType !== 'APN') {
              return 'n/a';
            }
            if (record.isCompleted) {
              return 'yes';
            } else {
              return 'no';
            }
          }}
        />
        <Table.Column
          title='Designation'
          dataIndex='designation'
          key='designation'
          sorter
          ellipsis
          width='7%'
        />
        <Table.Column
          title='Demand'
          dataIndex='demand'
          key='demand'
          sorter
          width='7%'
          render={(_, record: ComponentPriceListRow) => {
            if (record.priceType !== 'APN') {
              return 'n/a';
            } else {
              return numberFormatter(record.demand);
            }
          }}
        />

        <Table.Column
          title='Orig. Price EMS/Estimate'
          dataIndex='origCurrency'
          key='origCurrency'
          width='11.5%'
        />
        <Table.Column
          title='Orig. Currency'
          dataIndex='mixedPriceOrigCurrency'
          key='mixedPriceOrigCurrency'
          sorter
          width='7%'
          // render={value => numberFormatter(value)}
        />
        <Table.Column
          title={'Weighted price in ' + defaultCurrency}
          dataIndex='mixedPrice'
          key='mixedPrice'
          width='9.5%'
          render={value => {
            if (value !== undefined) return value.toFixed(6);
          }}
        />
        <Table.Column
          title='Price Type'
          dataIndex='priceType'
          key='priceType'
          sorter
          width='7.5%'
        />
        <Table.Column
          title='Bundling'
          key='bundling'
          dataIndex='bundlingName'
          width='9%'
          // onCell={editableCellHandler<ComponentPriceListRow>({
          //     store: table,
          //     keyField: 'demandId',
          //     isEditable: ({ actions }) => actions.edit?.allow,
          //     field: 'bundlingName',
          //     component: NestedSelect,
          //     preSave: setBundlingId,
          //     extraProps: {
          //         options: bundlingOptions,
          //         allowClear: true,
          //     },
          // })}
        />
        {/*
          <Table.Column title='Year' dataIndex='year' key='year' width='7%' filters={table.data.map(({ year }) => ({ text: year, value: year }))} onFilter={(value, record:ComponentPriceListRow) => record.year === value}  />
*/}
        <Table.Column title='Year' dataIndex='year' key='year' width='7%' />
        <Table.Column
          title='EMS'
          dataIndex='emsCode'
          key='emsCode'
          width='5%'
          render={(_, record: ComponentPriceListRow) => {
            if (record.priceType === 'Estimate' || record.priceType === 'Offer') {
              return 'All';
            } else {
              return record.emsCode;
            }
          }}
        />
        <Table.Column
          title='Quantity Base'
          dataIndex='quantityBase'
          key='quantityBase'
          width='8%'
          render={(_, record: ComponentPriceListRow) => {
            if (record.priceType === 'APN' || record.priceType === 'EMS') {
              return 'n/a';
            } else {
              return numberFormatter(record.quantityBase);
            }
          }}
        />
        <Table.Column
          title='Commodity'
          key='commodity'
          width='10%'
          render={commodityCell}
          ellipsis
        />
        <Table.Column
          title='Max Lead Time[wks]'
          dataIndex='maxLeadTime'
          key='maxLeadTime'
          width='10%'
        />
        <Table.Column title='Comment' dataIndex='comment' key='comment' width='10%' />
        <Table.Column title='Links' dataIndex='links' key='links' width='10%' />
        <Table.Column
          title='Actions'
          key='actions'
          width='12%'
          render={row => <ComponentPriceListActions row={row} store={store} />}
        />
      </Table>
      <ComponentPriceListEdit formStore={table.edit} />
    </>
  );
});

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { RequestStatuses } from './request-constants';
import { RequestState } from './state-types';
import { getRequestInitialState } from './request-slice-utils';

export const createRequestSlice = <T, Reducers extends SliceCaseReducers<RequestState<T>>>({
  name = '',
  initialDataState,
  reducers,
}: {
  name: string;
  initialDataState: T;
  reducers: ValidateSliceCaseReducers<RequestState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState: getRequestInitialState(initialDataState),
    reducers: {
      request(state) {
        state.status = RequestStatuses.request;
      },

      requestSuccess(state: RequestState<T>, { payload }: PayloadAction<T>) {
        state.status = RequestStatuses.requestSuccess;
        state.data = payload;
      },

      requestError(state) {
        state.status = RequestStatuses.error;
      },
      ...reducers,
    },
  });
};

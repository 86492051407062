import * as React from 'react';
import { Modal } from 'antd';
import { EmsPageStore } from './EmsPageStore';
import { observer } from 'mobx-react';
import { TableUploaderModal } from '../../components/TableUploader';
import { addNoItemsPermission } from '../../utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { TableExportModal } from '../../components/TableExport';
import { Rest } from '../../api/Rest';

const msgImport = 'Import EMS data';
const msgExport = 'Export EMS data';
type Mode = 'importEms' | 'exportEms';
interface PropsEmsTools {
  store: EmsPageStore;
}

export const EmsTools: React.FC<PropsEmsTools> = observer(({ store }) => {
  const { table, actions } = store;
  const selectedCount = table.checkedRows.length;
  const [importOpen, setImportOpen] = React.useState(false);
  const [mode, setMode] = React.useState<'' | Mode>('');
  const getExportKey = (): Promise<string> =>
    Rest.postExt('/api/v1/Ems/export', { responseType: 'text' });
  const clear = () => setMode('');
  const onOpenDemands = () => {
    const { destroy } = Modal.confirm({
      title: `Demand Collection will be opened for selected EMS`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: () => {
        destroy();
        table.openDemands();
      },
    });
  };
  const onEndDates = () => store.setEndDate.activate({});
  const menuItems: ToolsMenuItem[] = [
    {
      text: `Open demand for ${selectedCount} selected`,
      permission: addNoItemsPermission(selectedCount > 0, actions.opendemandcollection),
      onClick: onOpenDemands,
    },
    {
      text: `Set End date for ${selectedCount} selected`,
      permission: addNoItemsPermission(selectedCount > 0, actions.setenddate),
      onClick: onEndDates,
    },
    {
      permission: actions.import,
      onClick: () => setImportOpen(true),
      text: msgImport,
    },
    {
      text: msgExport,
      permission: store.actions?.exportemslist, //TODO: change to export perm
      onClick: () => setMode('exportEms'),
    },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={importOpen}
        title={msgImport}
        importType='EmsList'
        onClose={() => setImportOpen(false)}
        store={table}
      />
      <TableExportModal
        visible={mode === 'exportEms'}
        title={msgExport}
        onClose={clear}
        getExportKey={getExportKey}
      />
    </>
  );
});

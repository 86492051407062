import { action, computed, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { FnDataLoad, RemoteResponse, TableStore } from '../../common/TableStore';
import {
  DemandEditData,
  DemandFilter,
  DemandPageActionsMap,
  demandRightFields,
  DemandRow,
} from './DemandPage.types';
import { getRows } from '../../api/getRows';
import { makeStrParams, Rest } from '../../api/Rest';
import { FnFormSave, FormStore } from '../../common/FormStore';
import { transformComponents } from '../../common/apn/transformComponents';
import { DemandQuotaStore } from './DemandQuota';
import { updatePageActions } from '../../common/updatePageActions';
import { drawErrorMessage } from '../../common/drawErrorMessage';

const loadDemand: FnDataLoad<DemandRow, DemandFilter> = filter =>
  getRows('/api/v1/Demand', transformComponents(filter));

const editSave: FnFormSave<DemandRow> = (oldData, newData) =>
  Rest.patch(`/api/v1/Demand/edit/${oldData.demandId}`, newData);

const editSave2: FnFormSave<DemandEditData> = (oldData, newData) =>
  Rest.patch(`/api/v1/Demand/edit/${oldData.demandId}`, newData);

export class DemandTableStore extends TableStore<DemandRow, DemandFilter> {
  checkedRows = [] as DemandRow[];
  lockProcess = new Set<number>();
  editForm: FormStore<DemandRow>;
  edit: FormStore<DemandEditData>;
  quotas: Record<number, DemandQuotaStore> = {};

  constructor() {
    super({ page: 0, pageSize: 10 }, loadDemand);
    const onEditFinish = () => {
      this.modifyRowExt('demandId', this.editForm.data);
    };

    const onEditFinish2 = () => {
      this.reloadRow(this.edit.data.demandId);
    };

    this.editForm = new FormStore<DemandRow>(editSave, onEditFinish);
    this.edit = new FormStore<DemandEditData>(editSave2, onEditFinish2);
    makeObservable(this, {
      checkedRows: observable,
      lockProcess: observable,
      quotas: observable,
      setCheckedRows: action,
      canLock: computed,
      canUnlock: computed,
      lockChecked: action,
      toggleLock: action,
      onLockingFinish: action,
      deleteRow: action,
      onExpand: action,
      expandedRowKeys: computed,
      reloadRow: action,
    });
  }
  onData(response: RemoteResponse<DemandRow>, newFilter: DemandFilter) {
    super.onData(response, newFilter);
    this.checkedRows = [];
    this.lockProcess.clear();
    this.quotas = {};
  }
  setCheckedRows(checkedRows: DemandRow[]) {
    this.checkedRows = checkedRows;
  }
  get canLock(): boolean {
    return !!this.checkedRows.find(row => !row.isLocked);
  }
  get canUnlock(): boolean {
    return !!this.checkedRows.find(row => row.isLocked);
  }

  lockChecked(isLock: boolean, reload: () => void) {
    interface Body {
      isLock: boolean;
      demands: number[];
    }
    const body = {
      isLock,
      demands: this.checkedRows.map(({ demandId }) => demandId),
    };
    this.lock();
    Rest.post<void, Body>('/api/v1/Demand/bulk/lock', body)
      .then(() => {
        this.onGroupLockSuccess();
        reload();
      })
      .finally(() => this.unlock());
  }
  onGroupLockSuccess() {
    this.setCheckedRows([]);
  }
  toggleLock(row: DemandRow) {
    this.lockProcess.add(row.demandId);
    const isLocked = !row.isLocked;
    const body = { isLocked };
    const { demandId } = row;
    Rest.patch<void, typeof body>(`/api/v1/Demand/${row.demandId}`, body)
      .then(() => this.onSingleLockSuccess(demandId, isLocked))
      .finally(() => this.onLockingFinish(demandId))
      .then(() => this.reloadRow(demandId));
  }
  reloadRow(demandId: number) {
    this.lock();
    Rest.get<DemandRow>(`/api/v1/Demand/${demandId}`)
      .then(response => {
        this.modifyRowExt('demandId', response);
      })
      .finally(() => this.unlock());
  }
  onSingleLockSuccess(demandId: number, isLocked: boolean) {
    const index = this.data.findIndex(row => row.demandId === demandId);
    if (index >= 0) {
      this.modifyRow(index, { isLocked });
    }
  }
  onLockingFinish(demandId: number) {
    this.lockProcess.delete(demandId);
  }
  isLocking(row: DemandRow): boolean {
    return this.lockProcess.has(row.demandId);
  }
  deleteRow(row: DemandRow) {
    this.lock();
    Rest.del(`/api/v1/Demand/${row.demandId}`)
      .catch(e => drawErrorMessage(e, "Can't delete this record"))
      .then(() => this.reload())
      .finally(() => this.unlock());
  }

  onExpand(isExpand: boolean, row: DemandRow) {
    const { demandId, isLocked } = row;
    if (!isExpand) {
      delete this.quotas[demandId];
    } else {
      const qStore = new DemandQuotaStore(demandId, isLocked);
      this.quotas[demandId] = qStore;
      qStore.init();
    }
  }
  get expandedRowKeys(): number[] {
    return Object.keys(this.quotas).map(id => +id);
  }
}

export class DemandPageStore extends LoaderStore {
  table: DemandTableStore;
  isFilterOpen: boolean = false;
  addLineStatus: 'none' | 'active' | 'save' = 'none';
  addPreviousYearLineStatus: 'none' | 'active' | 'save' = 'none';
  permissions?: DemandPageActionsMap;

  constructor() {
    super();
    this.table = new DemandTableStore();
    makeObservable(this, {
      permissions: observable,
      isFilterOpen: observable,
      addLineStatus: observable,
      isAddLine: computed,
      addPreviousYearLineStatus: observable,
      isPreviousYearAddLine: computed,
      toggleFilter: action,
      isFilterUsed: computed,
      startAddLine: action,
      closeAddLine: action,
      startPreviousYearAddLine: action,
      closePreviousYearAddLine: action,
      createNewLine: action,
      onCreateNewLine: action,
      createPreviousYearNewLine: action,
      onCreatePreviousYearNewLine: action,
      setActions: action,
    });
  }
  setActions(actions: DemandPageActionsMap) {
    this.permissions = actions;
  }
  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([updatePageActions('Demand', this)])
        .then(() => {
          this.onReady();
          this.table.init();
        })
        .catch(e => this.onError(e));
    } else {
      this.table.reload();
      updatePageActions('Demand', this);
    }
  }
  toggleFilter() {
    this.isFilterOpen = !this.isFilterOpen;
  }
  get isFilterUsed(): boolean {
    const { filter } = this.table;
    return !!demandRightFields.find(key => filter[key] !== undefined && filter[key] !== false);
  }
  get allowBulk(): boolean {
    return !!this.permissions?.bulklock;
  }

  get isAddLine(): boolean {
    return this.addLineStatus !== 'none';
  }
  get isAddLineSaving(): boolean {
    return this.addLineStatus === 'save';
  }
  startAddLine() {
    this.addLineStatus = 'active';
  }
  get isPreviousYearAddLine(): boolean {
    return this.addPreviousYearLineStatus !== 'none';
  }
  get isPreviousYearAddLineSaving(): boolean {
    return this.addPreviousYearLineStatus === 'save';
  }
  startPreviousYearAddLine() {
    this.addPreviousYearLineStatus = 'active';
  }

  createNewLine(values: Partial<DemandRow>) {
    this.addLineStatus = 'save';
    Rest.post<string, typeof values>('/api/v1/Demand', values)
      .then(response => {
        return Rest.get<DemandRow>(`/api/v1/Demand/${response}`);
      })
      .then(response => {
        this.onCreateNewLine(response);
      })
      .catch(e => {
        drawErrorMessage(e, "Can't add new component");
        this.startAddLine();
      });
  }

  createPreviousYearNewLine(values: Partial<DemandRow>) {
    this.addPreviousYearLineStatus = 'save';
    Rest.post<string, typeof values>('/api/v1/Demand/previousyear', values)
      .then(response => {
        return Rest.get<DemandRow>(`/api/v1/Demand/previousyear/${response}`);
      })
      .then(response => {
        this.onCreatePreviousYearNewLine(response);
      })
      .catch(e => {
        drawErrorMessage(e, "Can't add new component");
        this.startPreviousYearAddLine();
      });
  }
  onCreateNewLine(row: DemandRow) {
    this.closeAddLine();
    this.table.data = [...this.table.data, row];
  }
  onCreatePreviousYearNewLine(row: DemandRow) {
    this.closePreviousYearAddLine();
    //this.table.data = [...this.table.data, row];
  }
  closeAddLine() {
    this.addLineStatus = 'none';
  }
  closePreviousYearAddLine() {
    this.addPreviousYearLineStatus = 'none';
  }
  getExportKey = (): Promise<string> => {
    const pureFilter = {} as Partial<DemandFilter>;
    // @ts-ignore
    demandRightFields.forEach(key => (pureFilter[key] = this.table.filter[key]));
    const filter = transformComponents(pureFilter);
    const params = makeStrParams(filter);
    return Rest.postExt<string, void>('/api/v1/Demand/export', {
      queryStringParameters: params,
      responseType: 'text',
    });
  };
}

import React, { FunctionComponent } from 'react';
import { Button, Modal, Space } from 'antd';
import { SD2TableStore } from '../SD2Table/SD2TableStore';
import { SD2Row } from '../SD2Table/SD2Table.types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { PermissionCheck } from '../../../components/PermissionCheck';
import { observer } from 'mobx-react';

interface SharedDistributionActionsProps {
  row: SD2Row;
  store: SD2TableStore;
}
export const SharedDistributionActions: React.FC<SharedDistributionActionsProps> = observer(
  ({ row, store }) => {
    const onDelete = () => {
      const { destroy } = Modal.confirm({
        title: `Order Code will be deleted`,
        icon: <ExclamationCircleOutlined />,
        content: `Press OK to continue`,
        onOk: async () => {
          destroy();
          store.deleteRow(row);
        },
      });
    };
    const { actions } = row;

    const onEdit = () => {
      store.editForm.activate(row);
    };

    const onHide = () => {
      store.hide(row);
      store.reload();
    };

    const onShow = () => {
      store.show(row);
      store.reload();
    };

    return (
      <>
        <PermissionCheck permission={store.parentRow.actions.edit} type='link' onClick={onEdit}>
          <Button>edit</Button>
        </PermissionCheck>

        <PermissionCheck permission={store.parentRow.actions.delete} type='link' onClick={onDelete}>
          <Button>delete</Button>
        </PermissionCheck>

        {row.hide && (
          <PermissionCheck permission={store.parentRow.actions.edit} type='link' onClick={onShow}>
            <Button>unhide</Button>
          </PermissionCheck>
        )}

        {!row.hide && (
          <PermissionCheck permission={store.parentRow.actions.edit} type='link' onClick={onHide}>
            <Button>hide</Button>
          </PermissionCheck>
        )}
      </>
    );
  }
);

import { makeObservable, observable, action, computed } from 'mobx';
import { TablePaginationConfig } from 'antd/es/table';
import { LoaderStatus } from '../LoaderStore';
import { RemoteResponse, BaseFilter, SortOrder } from './TableStore.types';
import { SorterResult } from 'antd/es/table/interface';
import { drawErrorMessage } from '../drawErrorMessage';

export type FnDataLoad<TRow, TFilter extends BaseFilter<TRow>> = (
  filter: TFilter
) => Promise<RemoteResponse<TRow>>;

type TSorter<TRow> = SorterResult<TRow> | SorterResult<TRow>[];

export interface TableStoreLoadOptions {
  notScrollTop?: boolean; // default = false
}

export class TableStore<TRow, TFilter extends BaseFilter<TRow>> {
  status: LoaderStatus = 'none';
  data: TRow[] = [];
  total = 0;
  filter: TFilter;
  apiCall: FnDataLoad<TRow, TFilter>;
  lockCounter = 0;
  paginationMode: 'auto' | 'hideSinglePage' = 'auto';
  defaultOptions?: TableStoreLoadOptions;

  constructor(defaultFilter: TFilter, apiCall: FnDataLoad<TRow, TFilter>) {
    this.filter = defaultFilter;
    this.apiCall = apiCall;
    makeObservable(this, {
      status: observable,
      data: observable,
      total: observable,
      filter: observable,
      lockCounter: observable,
      init: action,
      load: action,
      isLoading: computed,
      onData: action,
      onError: action,
      paginator: computed,
      modifyRow: action,
      onSort: action,
      lock: action,
      unlock: action,
    });
  }

  init() {
    if (this.status === 'none') {
      this.load({});
    }
  }
  load(params: Partial<TFilter>, options?: TableStoreLoadOptions) {
    if (!options) {
      options = this.defaultOptions;
    }
    if (!options?.notScrollTop) {
      window.scrollTo(0, 0);
    }
    const newFilter = { ...this.filter, ...params };

    // remove undefined items of filter
    Object.keys(newFilter).forEach(key => {
      if (newFilter[key as keyof TFilter] === undefined) {
        delete newFilter[key as keyof TFilter];
      }
    });
    this.status = 'wait';
    return this.apiCall(newFilter)
      .then(response => this.onData(response, newFilter))
      .catch(error => this.onError(error));
  }
  reload(options?: TableStoreLoadOptions) {
    return this.load(this.filter, options);
  }
  modifyRow(index: number, row: Partial<TRow>) {
    const newRows = [...this.data];
    newRows[index] = { ...newRows[index], ...row };
    this.data = newRows;
  }
  modifyRowExt(key: keyof TRow, row: TRow) {
    const id = row[key];
    const pos = this.data.findIndex(curRow => curRow[key] === id);
    if (pos >= 0) {
      this.modifyRow(pos, row);
    }
  }
  get isLoading() {
    return this.status === 'wait' || this.lockCounter !== 0;
  }
  lock() {
    this.lockCounter++;
  }
  unlock() {
    this.lockCounter--;
  }
  onData(response: RemoteResponse<TRow>, newFilter: TFilter) {
    this.status = 'ready';
    this.data = response.data;
    this.total = response.total;
    this.filter = newFilter;
  }
  onError(error: Error) {
    this.status = 'ready';
    drawErrorMessage(error, "Can't read table data");
  }
  get paginator(): TablePaginationConfig | false {
    const store = this;
    if (this.paginationMode === 'hideSinglePage' && this.total <= 10) {
      // Cannot use filter.pageSize parameter.
      // Otherwise, you can select 100 items, the paginator will disappear and you will not be able to select less.
      return false;
    }
    return !store.filter.pageSize
      ? false
      : {
          showSizeChanger: true,
          total: this.total,
          current: this.filter.page + 1,
          pageSize: this.filter.pageSize,
          onChange(page, pageSizePossible) {
            const params: Partial<TFilter> = {};
            params.page = page - 1;
            params.pageSize = pageSizePossible;
            store.load(params);
          },
        };
  }
  onSort(sorter: TSorter<TRow>) {
    if ('field' in sorter) {
      this.load({
        sortBy: sorter.field as keyof TRow,
        sortOrder: (sorter.order || '') as SortOrder,
      } as Partial<TFilter>);
      return true;
    }
    return false;
  }
  onTableChange = (
    pagination: TablePaginationConfig,
    filters: unknown,
    sorter: TSorter<TRow>,
    extra: unknown
  ) => {
    this.onSort(sorter);
  };
}

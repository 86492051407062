import { Alert, Button, Modal, Space } from 'antd';
import * as React from 'react';
import { CompleteAllResult } from '../../../api/utils/complete-all-result';
import { ShareDistributionPageStore } from '../ShareDistributionPageStore';
import { useState } from 'react';

export interface PropsCompleteAllModal {
    visible: boolean;
    title: string;
    onClose(): void;
    store: ShareDistributionPageStore;
}

export const CompleteAllModal: React.FC<PropsCompleteAllModal> = ({
    visible,
    title,
    onClose,
    store,
}) => {
    React.useEffect(() => {
        makeTextFile(store.completeAllResponse);
    }, [store.completeAllResponse]);
    const [options, setOptions] = React.useState<boolean>(false);
    const [downloadErrorLink, setDownloadErrorLink] = React.useState<string>('');
    const [downloadSuccessLink, setDownloadSuccessLink] = React.useState<string>('');
    const makeTextFile = (completeAllRes: CompleteAllResult[]) => {
        var successList = completeAllRes.filter(a => a.success === true).map(a => a.message);
        var errorList = completeAllRes.filter(a => a.success === false).map(a => a.message);

        const dataSuccess = new Blob([successList.join('\n')], { type: 'text/plain' });
        const dataError = new Blob([errorList.join('\n')], { type: 'text/plain' });

        if (downloadSuccessLink !== '') window.URL.revokeObjectURL(downloadSuccessLink);
        setDownloadSuccessLink(window.URL.createObjectURL(dataSuccess));

        if (downloadErrorLink !== '') window.URL.revokeObjectURL(downloadErrorLink);
        setDownloadErrorLink(window.URL.createObjectURL(dataError));
    };
    const [disable, setDisable] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [buttonText, setButtonText] = useState('Ok');
    const [cancelText, setCancelText] = useState('Cancel');
    const [titleText, setTitleText] = useState(title);
    function handleClick() {
        setButtonText('Please wait...');
        setCancelText('Cancel');
    }
    function handleClick2() {
        setButtonText('Done');
        setLoading(false);
        setCancelText('Close');
        setHidden(true);
        setTitleText('Complete SD for all');
    }
    function handleClick3() {
        setButtonText('Ok');
        setCancelText('Cancel');
        setHidden(false);
    }
    const onConfirm = async () => {
        handleClick();
        setDisable(true);
        setLoading(true);
        await store.markCompleteAll();
        makeTextFile(store.completeAllResponse);
        setOptions(true);
        handleClick2();
    };

    const onDownloadLogs = async () => {
        makeTextFile(store.completeAllResponse);
        setOptions(true);
    };

    return (
        <>
            <Modal
                visible={visible}
                title={titleText}
                width={440}

                onCancel={() => {
                    onClose();
                    setDisable(false);
                    handleClick3();
                    setHidden(false);
                    setTitleText('Share Distribution will be completed for all');
                }}
                maskClosable={false}
                destroyOnClose
                className='ant-modal-confirm-body'
                footer={[
                    <Button key='confirm' type='primary' hidden={hidden} loading={loading} onClick={() => { onConfirm(); }} disabled={disable}>
                        {buttonText}
                    </Button>,
                    <Button
                        key='close'
                        onClick={() => {
                            onClose();
                            setOptions(false);
                            makeTextFile([])
                            setDisable(false);
                            setLoading(false);
                            handleClick3();
                        }}
                    >
                        {cancelText}
                    </Button>,
                ]}
            >
                {options === false && (
                    <>
                        <div className="ant-modal-body">
                            <div className="ant-modal-confirm-body-wrapper">
                        <div className="ant-modal-confirm-body">
                        
                                    <span role="img" style={{ color: 'orange' }} aria-label="exclamation-circle" className="anticon anticon-exclamation-circle ant-modal-confirm-warning"><svg viewBox="64 64 896 896" focusable="false" data-icon="exclamation-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path></svg></span>
                                    <div className="ant-modal-confirm-content">Press OK to continue</div>
                        </div>
                            </div>
                        </div>
                        
                    </>
                )}

                {options && (
                    <>
                        <Alert
                            message='Success'
                            description={
                                <div>
                                    <a
                                        onClick={onDownloadLogs}
                                        download='successList.txt'
                                        href={downloadSuccessLink}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Click here to download a log file with successful results
                                    </a>
                                </div>
                            }
                            type='success'
                            showIcon
                        />
                        <Alert
                            message='Failed'
                            description={
                                <div>
                                    <a
                                        onClick={onDownloadLogs}
                                        download='errorList.txt'
                                        href={downloadErrorLink}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        Click here to download a log file with failed results
                                    </a>
                                </div>
                            }
                            type='error'
                            showIcon
                        />
                    </>
                )}
            </Modal>
        </>
    );
};

import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/es/form';

interface DeliveryScheduleFieldProps {
    isRequired: boolean;
}

const DeliveryScheduleField: React.FC<DeliveryScheduleFieldProps> = ({ isRequired }) => {
    const [form] = Form.useForm();
    const maxCharactersRule = { max: 500, message: 'Delivery schedule must be less than 500 characters' };
    const DSRule: Rule[] = [{ required: true, message: 'Delivery schedule is required' }, maxCharactersRule];


    useEffect(() => {
        if (!isRequired) {
            // Reset field value, rules, and disable the field when isRequired becomes false
            form.setFieldsValue({ deliverySchedule: '' }); // Reset field value
            form.setFields([{ name: 'deliverySchedule', value: '' }]); // Reset field value
            form.setFields([{ name: 'deliverySchedule', errors: [] }]); // Clear any validation errors
        }
    }, [form, isRequired]);

    return (
        <Form.Item
            key={isRequired ? 'deliverySchedule-required' : 'deliverySchedule'}
            label='Delivery schedule'
            name='deliverySchedule'
            rules={isRequired ? DSRule: undefined}
        >
            <Input autoComplete='off' disabled={!isRequired} />
        </Form.Item>
    );
};

export default DeliveryScheduleField;
import * as React from 'react';
import { Form, Select } from 'antd';
import { Rest } from '../../api/Rest';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { Project } from '../../api/types';

export const ProjectSelectSingle: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [list, setList] = React.useState([] as Project[]);
  const [search, setSearch] = React.useState('');
  const load = React.useCallback((name: string) => {
    setLoading(true);
    Rest.get<{ data: Project[] }>('/api/v1/Settings/projects', { Name: name })
      .then(response => setList(response.data))
      .catch(e => {
        drawErrorMessage(e);
        setList([]);
      })
      .finally(() => setLoading(false));
  }, []);
  React.useEffect(() => load(''), [load]);
  const onSearch = (namePart: string) => {
    setSearch(namePart);
    load(namePart);
  };
  return (
    <Form.Item label='Project' name='ProjectId'>
      <Select
        placeholder='Start typing to find project'
        allowClear
        showSearch
        filterOption={false}
        loading={loading}
        onSearch={onSearch}
        searchValue={search}
      >
        {list.map(({ projectId, name }) => (
          <Select.Option key={projectId} value={projectId}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { MatrixBasisQuotaStore } from './MatrixBasisQuotaStore';
import { ModalForm } from '../../../components/ModalForm';
import { Form, Input } from 'antd';
import { IncotermSelect } from '../../../components/IncotermSelect';
import TextArea from 'antd/es/input/TextArea';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../store/features/session/session-slice';
import { CurrencySelectCplEdit } from '../../../components/CurrencySelectCplEdit';
import { Rule } from 'antd/es/form';
import { integerOnlyRule, priceRule } from '../../../common/constants';

interface PropsDemandQuotaEdit {
  store: MatrixBasisQuotaStore;
}

export const MatrixBasisQuotaEdit: React.FC<PropsDemandQuotaEdit> = observer(({ store }) => {
  const { editForm } = store;
  const { groups } = useSelector(selectUserInfo);
  const isSupr = groups.includes('SUPUSR');
  const isPrpur = groups.includes('PRPUR');
  const origPriceRule: Rule[] = [priceRule];
  const intOnlyRule: Rule[] = [integerOnlyRule];

  return (
    <ModalForm
      title='Edit Order Code information'
      name='quota'
      visible={editForm.visible}
      saving={editForm.saving}
      layout={{ layout: 'vertical' }}
      onClose={editForm.close}
      onSave={editForm.save}
      initialData={editForm.data}
    >
      <Form.Item label='Orig. Price APN/Offer' name='origPrice' rules={origPriceRule}>
        <Input disabled={editForm.data.priceType !== 'Offer'} maxLength={30} />
      </Form.Item>
      <Form.Item initialValue={'EUR'} label='Orig. Currency' name={'origCurrency'}>
        <CurrencySelectCplEdit
          disabled={editForm.data.priceType !== 'Offer'}
          bundlingId={editForm.data.origCurrency}
          name={editForm.data?.origCurrency}
        />
      </Form.Item>
      <Form.Item name='incotermId' label='Incoterm Code'>
        <IncotermSelect />
      </Form.Item>
      <Form.Item name='incotermDirection' label='Incoterm Location'>
        <Input autoComplete='off' maxLength={100} />
      </Form.Item>
      <Form.Item label='Lead Time[wks]' name='leadTime'>
        <Input disabled={!isSupr && !isPrpur} maxLength={30} />
      </Form.Item>
      <Form.Item name='distributor' label='Distributor'>
        <Input autoComplete='off' maxLength={100} />
      </Form.Item>
      <Form.Item name='comment' label='Comments'>
        <TextArea maxLength={200} />
      </Form.Item>
    </ModalForm>
  );
});

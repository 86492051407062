import API from '@aws-amplify/api';
import { PaginationParams, ResponseWithPagination } from '../store/utils/state-types';
import { Demand, DemandModel } from '../store/features/demand-list/demand-list-types';
import { Quota } from '../store/features/quota/quota-types';
import { quotaAdapter } from './adapter-quota';
import { MAIN_API } from './init-aws-amplify';

export interface DemandEditPayload {
  demandId: number;
  body: Partial<DemandModel>;
}

export interface DemandAddPayload {
  body: Partial<DemandModel>;
}

export type ComponentsType = 'Without Demand' | 'New Parts';

export interface DemandFilterParams {
  EmsCode?: string;
  ManufacturerCode?: string;
  BshPartNo?: string;
  EmsPartNo?: string;
  OrderCode?: string;
  Components?: ComponentsType[];
  CommodityCode?: string;
  CommodityGpmt?: string;
  CommodityName?: string;
}

export const demand = {
  getList: (
    params: PaginationParams & DemandFilterParams
  ): Promise<ResponseWithPagination<Demand[]>> => {
    return API.get(MAIN_API, '/api/v1/Demand', {
      queryStringParameters: params,
    }) as Promise<ResponseWithPagination<Demand[]>>;
  },
  getById: (demandId: number): Promise<Demand> => {
    return API.get(MAIN_API, `/api/v1/Demand/${demandId}`, {}) as Promise<Demand>;
  },
  add: ({ body }: DemandAddPayload): Promise<void> => {
    return API.post(MAIN_API, `/api/v1/Demand`, { body }) as Promise<void>;
  },
  updateById: ({ demandId, body }: DemandEditPayload): Promise<void> => {
    return API.patch(MAIN_API, `/api/v1/Demand/${demandId}`, { body }) as Promise<void>;
  },
  deleteById: ({ demandId }: DemandEditPayload): Promise<void> => {
    return API.del(MAIN_API, `/api/v1/Demand/${demandId}`, {}) as Promise<void>;
  },
  getQuota: (demandId: number): Promise<Quota[]> => {
    return (API.get(MAIN_API, `/api/v1/Demand/${demandId}/Quota`, {}) as Promise<{
      data: Quota[];
    }>).then(quotaAdapter.forward);
  },
};

import * as React from 'react';
import { Rule } from 'antd/lib/form';
import { Spin } from 'antd';
import { EditableContext } from './EditableRow';
import { CellStatus, PropsNested } from './nested';
import { drawErrorMessage } from '../../common/drawErrorMessage';

interface EditableCellProps<Item> {
  children: React.ReactNode;
  NestedComponent?: React.FC<PropsNested> | null;
  extraProps?: Object;
  rules?: Rule[];
  dataIndex: keyof Item;
  keyIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => Promise<void>;
}

export const EditableCell = <Item,>(props: EditableCellProps<Item>): React.ReactElement => {
  const {
    children,
    dataIndex,
    keyIndex,
    record,
    handleSave,
    NestedComponent,
    extraProps,
    rules,
    ...restProps
  } = props;
  const [cellStatus, setCellStatus] = React.useState<CellStatus>('view');
  const form = React.useContext(EditableContext)!;

  const toggleEdit = () => {
    setCellStatus('edit');
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      setCellStatus('save');
      const values = await form.validateFields();
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
      if (values[dataIndex] === record[dataIndex]) {
        return; // dont save if not changed
      }
      const savingData = { [keyIndex]: record[keyIndex], ...values };
      await handleSave(savingData);
    } catch (errInfo) {
      drawErrorMessage(errInfo, "Can't save data");
    } finally {
      setCellStatus('view');
    }
  };

  let childNode = children;

  if (NestedComponent) {
    childNode =
      cellStatus !== 'view' ? (
        <div style={{ position: 'relative' }}>
          {cellStatus === 'save' && (
            <Spin size='small' style={{ position: 'absolute', zIndex: 10 }} />
          )}
          <NestedComponent
            name={String(dataIndex)}
            rules={rules}
            status={cellStatus}
            onCancel={() => setCellStatus('view')}
            onFinish={() => save()}
            {...extraProps}
          />
        </div>
      ) : (
        <div className='editable-cell-value-wrap' style={{ paddingRight: 24, overflowWrap:"break-word",overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", wordBreak: "keep-all", maxWidth:"120px" }} onClick={toggleEdit}>
          {children}
        </div>
      );
  } else if (NestedComponent === null) {
    childNode = <span style={{ paddingLeft: 11 }}>{children}</span>;
  }

  return <td {...restProps}>{childNode}</td>;
};

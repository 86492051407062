import * as React from 'react';
import { Alert, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { Rest } from '../../api/Rest';
import './TableUploader.less';
import { numberFormatter } from '../../utils';
import { formatErrorMessage } from '../../api/utils/error-message-formatter';

interface ImportResponse {
  action: string; // "Put"
  key: string; // "42960b2573844f809a6e3fab87344c8f.xlsx"
  url: string; // "https://https://import-smartpur-dev..."
}

interface ImportStatusResponse {
  importKey: string;
  fileName: string;
  statusId: number;
  statusName: string;
  createdDate: string;
  modifiedDate?: string;
}

interface LogResponse {
  action: string;
  key: string;
  url: string;
}

export interface UploadResult {
  fileName: string;
  fileSize: number;
  statusName: string;
  logUrl: string;
}

export type ImportType =
  | 'Commodity'
  | 'ComponentsCommodity'
  | 'EdmPart'
  | 'EdmPreSupplier'
  | 'EmsDemand'
  | 'EmsList'
  | 'EmsPrice'
  | 'OfferPrice'
  | 'PreEmsPrice'
  | 'PreOfferPrice'
  | 'PartDesignation'
  | 'RfqManufacturer'
  | 'ExchangeRates'
  | 'Manufacturer'
  | 'Components'
  | 'ShareDistribution'
  | 'PreviousYearPrice';

export interface PropsTableUploader {
  importType: ImportType;
  onSuccess?(info: UploadResult): void;
}

const isSuccess = (info: UploadResult) => info.statusName === 'Completed';
const isFailed = (info: UploadResult) => info.statusName === 'Failed';

export const TableUploader: React.FC<PropsTableUploader> = ({ importType, onSuccess }) => {
  const [status, setStatus] = React.useState<'ok' | 'error' | 'upload'>('ok');
  const [uploadResult, setUploadResult] = React.useState<UploadResult | null>(null);
  const [errorMsg, setErrorMsg] = React.useState<string | React.ReactElement[][]>('');

  // Long pooling of uploaded parsing results
  const pooling = (url: string): Promise<ImportStatusResponse> =>
    new Promise((resolve, reject) => {
      const iteration = () => {
        Rest.get<ImportStatusResponse>(url)
          .then(response => {
            if (response.statusName in { Open: 1, InProgress: 1 }) {
              setTimeout(iteration, 1000);
            } else {
              resolve(response);
            }
          })
          .catch(e => reject(e));
      };
      iteration();
    });

  const uploadHandler = async (file: RcFile) => {
    setStatus('upload');
    setUploadResult(null);
    setErrorMsg('');
    try {
      const importResponse = await Rest.post<ImportResponse, { type: string }>(
        `/api/v1/Import/${file.name}`,
        { type: importType }
      );
      const { key } = importResponse;
      await fetch(importResponse.url, { method: 'put', body: file });
      const result = await pooling(`/api/v1/Import/${key}`);
      const logInfo = await Rest.get<LogResponse>(`/api/v1/Import/${result.importKey}/logs`);
      setStatus('ok');
      const info: UploadResult = {
        fileName: file.name,
        fileSize: file.size,
        statusName: result.statusName,
        logUrl: logInfo.url,
      };
      setUploadResult(info);
      if (onSuccess && isSuccess(info)) onSuccess(info);
    } catch (e) {
      setStatus('error');
      setErrorMsg(formatErrorMessage(e));
    }
  };

  const beforeUpload = (file: RcFile) => {
    uploadHandler(file);
    return false;
  };

  const isUpload = status === 'upload';
  let msgWarning =
    "Please upload an xlsx, xls or csv file of predefined format. This operation can't be\n" +
    '        canceled or reverted';
  if (importType == 'OfferPrice' || importType == 'EmsPrice') {
    msgWarning =
      'The existing BSH Part No with "Estimate" price type for the same year will be deleted. This operation can\'t be cancelled or reverted';
  }
  return (
    <>
      <div className='table-uploader-warning'>{msgWarning}</div>
      <div className='table-uploader'>
        <div className='table-uploader-text'>
          {isUpload ? 'Uploading...' : 'Please upload the file'}
        </div>
        <Upload.Dragger
          accept='.xls,.xlsx,.csv'
          showUploadList={false}
          disabled={isUpload}
          beforeUpload={beforeUpload}
        >
          <div className={`table-uploader-in`}>
            {isUpload ? <LoadingOutlined /> : <PlusOutlined />}
          </div>
        </Upload.Dragger>
      </div>
      {errorMsg && (
        <Alert
          showIcon
          closable
          message='Upload error'
          description={errorMsg}
          type='error'
          onClose={() => setErrorMsg('')}
        />
      )}
      {uploadResult && (
        <Alert
          showIcon
          closable
          type={isFailed(uploadResult) ? 'error' : 'success'}
          message={uploadResult.statusName}
          description={
            <div>
              <div>
                File: {uploadResult.fileName} (
                {numberFormatter(Math.round(uploadResult.fileSize / 1024))} K)
              </div>
              <a href={uploadResult.logUrl} target='_blank' rel='noreferrer'>
                Click here to download a log file with import results
              </a>
            </div>
          }
          onClose={() => setUploadResult(null)}
        />
      )}
    </>
  );
};

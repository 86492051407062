import { LoaderStore } from '../../common/LoaderStore';
import { ReportsPageActions } from './ReportsPage.types';
import { Rest } from '../../api/Rest';
import { action, makeObservable, observable } from 'mobx';

export class ReportsPageStore extends LoaderStore {
  actions: ReportsPageActions = {};
  constructor() {
    super();
    makeObservable(this, {
      actions: observable,
      setActions: action,
    });
  }
  init() {
    this.onWait();
    Rest.get<ReportsPageActions>('/api/v1/Report/actions')
      .then(res => {
        this.setActions(res);
        this.onReady();
      })
      .catch(e => this.onError(e));
  }
  setActions(actions: ReportsPageActions) {
    this.actions = actions;
  }
  getExportKey(type: string): Promise<string> {
    return Rest.postExt(`/api/v1/Report/export/${type}`, { responseType: 'text' });
  }
}

import * as React from 'react';
import { Modal, Empty } from 'antd';
import { OfferPriceExportManufacturer } from './OfferPriceExportManufacturer';
import { ApnFields } from '../ApnFilter';
import { LoaderBox } from '../LoaderBox';
import { OfferPriceExportStore } from './OfferPriceExportStore';
import { observer } from 'mobx-react';

interface PropsOfferPriceExport {
  visible: boolean;
  onClose(): void;
  filter: ApnFields;
}

const ApnOfferPriceInner: React.FC<{ filter: ApnFields }> = observer(({ filter }) => {
  const [store] = React.useState(new OfferPriceExportStore(filter));
  const onClose = () => {};
  React.useEffect(() => store.init(), [store]);
  const isEmpty = store.manufList.length === 0;
  return (
    <LoaderBox store={store}>
      {isEmpty ? (
        <Empty />
      ) : (
        store.manufList.map(info => (
          <OfferPriceExportManufacturer key={info.manufacturerId} info={info} onClose={onClose} />
        ))
      )}
    </LoaderBox>
  );
});

export const OfferPriceExport: React.FC<PropsOfferPriceExport> = ({ visible, onClose, filter }) => {
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title='Offer Price file export'
      maskClosable={false}
      destroyOnClose
      footer={null}
    >
      <ApnOfferPriceInner filter={filter} />
    </Modal>
  );
};

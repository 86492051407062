import React from 'react';
import { Form, Select } from 'antd';
import { PropsNested } from './nested.types';
import { nestedKeyboardHandler } from './nestedKeyboardHandler';

interface PropsNestedSelect extends PropsNested {
  options?: { label: string; value: string }[];
  useSearch?: boolean;
  allowClear?: boolean;
}

export const NestedSelect: React.FC<PropsNestedSelect> = props => {
  const { status, name, rules, onCancel, onFinish, options = [], useSearch, allowClear } = props;
  return (
    <Form.Item name={name} rules={rules} style={{ margin: 0 }}>
      <Select
        disabled={status === 'save'}
        onKeyUp={nestedKeyboardHandler(onCancel, onFinish)}
        autoFocus
        onSelect={onFinish}
        onBlur={onFinish}
        defaultOpen
        showSearch={!!useSearch}
        allowClear={allowClear}
      >
        {options.map(({ label, value }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

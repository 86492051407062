import { notification } from 'antd';
import { formatErrorMessage } from '../api/utils/error-message-formatter';

/*
Use drawErrorMessage in function without async result:
const someFunc = (url): void =>
  Rest.get(url).then(...).catch(e => drawErrorMassage(e);
 */

export const drawErrorMessage = (e: Error, title?: string) => {
  notification.error({
    message: title || 'Error',
    description: formatErrorMessage(e),
  });
};

/*
 Use drawErrorExt for error transition:
 const someFunc = (url): Promise<string> =>
   Rest.get<string>(url).catch(e => drawErrorExt<string>(e))
 */

export const drawErrorExt = <T>(e: Error, title?: string): Promise<T> => {
  drawErrorMessage(e, title);
  return Promise.reject(e);
};

export const drawNotification = (message: string) => {
    notification.error({
        message:  'Notification',
        description: message,
    });
};
export const drawNotificationSuccess = (message: string) => {
    notification.success({
        message: 'Notification',
        description: message,
    });
};
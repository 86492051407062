import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, Input, DatePicker } from 'antd';
import { FormInstance, Rule } from 'antd/es/form';
import { ModalForm } from '../../components/ModalForm';
import { MYARow } from './MYA.types';
import { ManufacturerSelect } from '../../components/ManufacturerSelect';
import { FormStore } from '../../common/FormStore';
import { Moment } from 'moment';

interface PropsMYANewModal {
  title: string;
  isCreate: boolean;
  editStore: FormStore<MYARow>;
}

const rulesManufacturer: Rule[] = [{ required: true, message: 'Manufacturer is required' }];
const rulesSubject: Rule[] = [{ required: true, message: 'Document is required' }];
const rulesStartDate: Rule[] = [{ required: true, message: 'Start date is required' }];

const checkInterval = (
  msg: string,
  startDate: Moment | string | null,
  endDate: Moment | string | null
): Promise<void> => {
  if (!startDate || !endDate || startDate < endDate) {
    return Promise.resolve();
  }
  return Promise.reject(Error(msg));
};

export const MYAEditModal: React.FC<PropsMYANewModal> = observer(
  ({ title, isCreate, editStore }) => {
    const formRef = React.useRef<FormInstance<MYARow>>();

    const rulesEndDate: Rule[] = React.useMemo(
      () => [
        { required: true, message: 'End date is required' },
        {
          validator: (_rule, endDate) => {
            const { startDate } = editStore.currentData;
            return checkInterval('Must be bigger than start date', startDate, endDate);
          },
        },
      ],
      [editStore]
    );

    const updateValidators = async () => {
      try {
        formRef.current?.validateFields();
      } catch (e) {}
    };

    return (
      <ModalForm<MYARow>
        title={title}
        name={isCreate ? 'newMYA' : 'editMYA'}
        visible={editStore.visible}
        saving={editStore.saving}
        layout={{ labelCol: { span: 5 }, wrapperCol: { span: 19 } }}
        onClose={editStore.close}
        onSave={editStore.save}
        initialData={editStore.data}
        onValuesChange={(values, allValues) => {
          editStore.onValuesChanged(allValues);
          if ('startDate' in values || 'endDate' in values) {
            updateValidators();
          }
        }}
        getForm={form => {
          formRef.current = form;
        }}
      >
        {isCreate && (
          <ManufacturerSelect
            name='manufacturerId'
            valueField='manufacturerId'
            rules={rulesManufacturer}
          />
        )}
        {!isCreate && (
          <Form.Item label='Manufacturer'>
            <div style={{ padding: '4px 11px' }}>{editStore.data.manufacturerName}</div>
          </Form.Item>
        )}
        <Form.Item name='subject' label='Document' rules={rulesSubject}>
          <Input />
        </Form.Item>
        <Form.Item name='startDate' label='Start date' rules={rulesStartDate}>
          <DatePicker />
        </Form.Item>
        <Form.Item name='endDate' label='End date' rules={rulesEndDate}>
          <DatePicker />
        </Form.Item>
        <Form.Item name='comments' label='Comment'>
          <Input.TextArea rows={2} />
        </Form.Item>
        <Form.Item name='link' label='PDF link'>
          <Input allowClear />
        </Form.Item>
      </ModalForm>
    );
  }
);

export const root = '/';
export const overviewPaths = {
  campaign: '/overview/campaign',
  ems: '/overview/ems',
  components: '/overview/components',
  manufacturers: '/overview/manufacturers',
  edmData: '/overview/edm-data',
  commodityCodes: '/overview/commodity-codes',
  mya: '/overview/multi-year-agreement',
};
export const demandCollectionPaths = {
  current: '/demand-collection/current',
  demandHistory: '/demand-collection/demand-history',
};
export const requestForQuotationPath = '/request-for-quotation';
export const componentPriceListPath = '/component-price-list';
export const matrixBasisPath = '/matrix-basis';
export const shareDistribution = '/share-distribution';
export const reportsPath = '/reports';
export const bomPath = '/bom-avl';

import { DemandFilterState } from './demand-filter-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../root-reducer';
import { ComponentsType, DemandFilterParams } from '../../../api';

const initialState: DemandFilterState = {
  EmsCode: null,
  ManufacturerCode: null,
  withoutDemand: false,
  newParts: false,
  BshPartNo: null,
  EmsPartNo: null,
  OrderCode: null,
  CommodityCode: null,
  CommodityGpmt: null,
  CommodityName: null,
};

export const demandFilterSlice = createSlice({
  name: 'Demand filter',
  initialState,
  reducers: {
    setFilter(state, { payload }: PayloadAction<Partial<DemandFilterState>>) {
      Object.assign(state, payload);
    },
    clearFilters() {
      return initialState;
    },
  },
});

export default demandFilterSlice.reducer;

export const selectDemandFilters = (state: RootState) => {
  return state.demandFilter;
};

export const selectNonNullDemandFilters = (state: RootState): DemandFilterParams => {
  const result: DemandFilterParams = {};

  const { withoutDemand, newParts, ...filters } = state.demandFilter;

  let prop: keyof typeof filters;

  for (prop in filters) {
    if (filters.hasOwnProperty(prop)) {
      const val = filters[prop];

      if (val) {
        result[prop] = val;
      }
    }
  }

  const componentsTypes: ComponentsType[] = [];
  if (withoutDemand) {
    componentsTypes.push('Without Demand' as const);
  }

  if (newParts) {
    componentsTypes.push('New Parts' as const);
  }

  result.Components = componentsTypes;

  return result;
};

export const selectIsDemandFiltersSelected = (state: RootState) => {
  return !!Object.values(state.demandFilter).find(item => !!item);
};

import * as React from 'react';
import { EmsPageStore } from './EmsPageStore';
import { EmsRow } from './EmsPage.types';
import { observer } from 'mobx-react';
import { PermissionCheck } from '../../components/PermissionCheck';
import { Button } from 'antd';
import { prepareToEdit } from './EmsPage.utils';

interface PropsEmsRowActions {
  row: EmsRow;
  store: EmsPageStore;
}

export const EmsRowActions: React.FC<PropsEmsRowActions> = observer(({ row, store }) => {
  const onClick = () => {
    store.edit.activate(prepareToEdit(row));
  };
  return (
    <PermissionCheck permission={store.actions.edit} onClick={onClick}>
      <Button type='link'>edit</Button>
    </PermissionCheck>
  );
});

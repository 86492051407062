import * as React from 'react';
import { Form, Input } from 'antd';
import { PropsNested } from './nested.types';
import { nestedKeyboardHandler } from './nestedKeyboardHandler';

export const NestedInput: React.FC<PropsNested> = props => {
  const { name, status, rules, onCancel, onFinish } = props;

  const inputRef = React.useRef<Input>(null);
  React.useEffect(() => {
    if (status === 'edit') {
      inputRef.current!.focus();
    }
  }, [status, inputRef]);

  return (
    <Form.Item style={{ margin: 0 }} name={name} rules={rules}>
      <Input
        ref={inputRef}
        onKeyUp={nestedKeyboardHandler(onCancel, onFinish)}
        onBlur={onFinish}
        disabled={status === 'save'}
      />
    </Form.Item>
  );
};

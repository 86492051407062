import { Rest } from '../Rest';

export interface LogOnDistriItem {
  logOnDistriId: number;
  name: string;
}

let cache: LogOnDistriItem[];

export const getLogOnDistris = async (): Promise<LogOnDistriItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: LogOnDistriItem[] }>('/api/v1/Settings/logondistri');
    cache = response.data;
  }
  return cache;
};

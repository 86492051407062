import * as React from 'react';
import { Button, Checkbox, Collapse, Form, Input, Space } from 'antd';
import { ApnFilterStore } from './ApnFilterStore';
import { ApnFields, ApnFilterFields } from './ApnFilter.types';
import './ApnFilter.less';
import { NegotiatorSelect } from './components/NegotiatorSelect';
import { BundlingSelect } from './components/BundlingSelect';
import { ManufacturerSelect } from '../ManufacturerSelect';
import { BshPartNoInput } from './components/BshPartNoInput';
import { EmsSelect } from '../ems-select/ems-select';

const { Panel } = Collapse;

interface PropsApnFilter<TRow, TFilter extends ApnFilterFields<TRow>> {
  store: ApnFilterStore<TRow, TFilter>;
}

export const ApnFilter = <TRow, TFilter extends ApnFilterFields<TRow>>(
  props: PropsApnFilter<TRow, TFilter>
) => {
  const { store } = props;
  const { table } = store;
  const [filterForm] = Form.useForm<ApnFields>();
  const onSubmit = (values: ApnFields) => {
    // It is important to indicate the transition to the first page,
    // because after filtering, the total number of items may become less.
    table.load({ ...(values as Partial<TFilter>), page: 0 });
  };
  const onClear = () => {
    filterForm.resetFields();
    filterForm.submit();
  };
  return (
    <Form<ApnFields>
      form={filterForm}
      layout='vertical'
      onFinish={onSubmit}
      className='apn-filter demand-filter-panel'
    >
      <div className='apn-filter-main-part'>
        <Collapse defaultActiveKey={['Supplier']}>
          <Panel key='Supplier' header='Supplier'>
            <Form.Item label='EMS' name='EmsCode'>
              <EmsSelect />
            </Form.Item>
            <ManufacturerSelect name='ManufacturerCode' />
            <NegotiatorSelect />
            <BundlingSelect />
          </Panel>
          <Panel key='Parts' header='Parts'>
            <Form.Item name='WithoutDemand' valuePropName='checked'>
              <Checkbox>Without Demand</Checkbox>
            </Form.Item>
            <Form.Item name='NewParts' valuePropName='checked'>
              <Checkbox>New parts</Checkbox>
            </Form.Item>
            <BshPartNoInput />
            <Form.Item name='OrderCode' label='Order code'>
              <Input allowClear />
            </Form.Item>
            <Form.Item name='DistriPartNo' label='Distri Part No.'>
              <Input allowClear />
            </Form.Item>
            <Form.Item name='LogonDistri' label='Log On Distri'>
              <Input allowClear />
            </Form.Item>
          </Panel>
          <Panel key='Commodity' header='Commodity'>
            <Form.Item name='CommodityCode' label='Commodity Code (e.g. P1111)'>
              <Input placeholder='Commodity Code' allowClear maxLength={5} />
            </Form.Item>
            <Form.Item name='CommodityName' label='Commodity Name (e.g. Driver IC) '>
              <Input placeholder='Commodity Name' allowClear />
            </Form.Item>
            <Form.Item name='CommodityGpmt' label='GPMT (e.g. ANA)'>
              <Input placeholder='GPMT' allowClear />
            </Form.Item>
          </Panel>
          <Panel key='QuotaPrice' header='Price / Quota'>
            <Form.Item name='QuotaIsZero' valuePropName='checked'>
              <Checkbox>Sum of quotas is 0%</Checkbox>
            </Form.Item>
            <Form.Item name='QuotaBetweenZeroand100' valuePropName='checked'>
              <Checkbox>Sum of quotas is &gt; 0% &#38; &lt; 100% </Checkbox>
            </Form.Item>
            <Form.Item name='SumOfQuotaGreaterThan100' valuePropName='checked'>
              <Checkbox>Sum of quotas is &gt; 100%</Checkbox>
            </Form.Item>
            <Form.Item name='PriceIsZeroAndQuotaGreaterThanZero' valuePropName='checked'>
              <Checkbox>Price is 0 &#38; quota &gt; 0% </Checkbox>
            </Form.Item>
            <Form.Item name='PriceIsZeroAndQuotaIsZero' valuePropName='checked'>
              <Checkbox>Price is &gt; 0 &#38; quota is 0%</Checkbox>
            </Form.Item>
          </Panel>
        </Collapse>
      </div>
      <Form.Item className='apn-buttons'>
        <Space>
          <Button size='middle' type='primary' htmlType='submit' loading={table.isLoading}>
            Apply
          </Button>
          <Button size='middle' onClick={onClear} loading={table.isLoading}>
            Clear filters
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { Form, InputNumber, Modal, notification } from 'antd';
import { ModalForm } from '../../../components/ModalForm';
import { CampaignPageStore } from '../CampaignPageStore';
import { formLayout } from '../../../common/constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NewCampaignFields } from '../CampaignPage.types';

const fullWidthStyle = { width: '100%' };

interface PropsNewCampaignModal {
  store: CampaignPageStore;
}

export const NewCampaignModal: React.FC<PropsNewCampaignModal> = observer(({ store }) => {
  const { newCampaign } = store;
  const onSave = (newData: Partial<NewCampaignFields>) => {
    const { destroy } = Modal.confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'New Campaign will be started. Are you sure? Press "Yes" to continue',
      onOk: async () => {
        destroy();
        await newCampaign.save(newData);
        notification.success({
          message: `Campaign ${newData.year} has been started`,
          placement: 'bottomRight',
        });
      },
      okText: 'Yes',
    });
  };

  return (
    <ModalForm
      title='New Campaign'
      name='newCampaign'
      visible={newCampaign.visible}
      saving={newCampaign.saving}
      layout={formLayout}
      onClose={newCampaign.close}
      onSave={onSave}
      initialData={newCampaign.data}
      submitText='Start new Campaign!'
    >
      <Form.Item label='Year' name='year'>
        <InputNumber style={fullWidthStyle} />
      </Form.Item>
    </ModalForm>
  );
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Modal, Select, Tooltip, Upload } from 'antd';
import { BomPageStore } from '../BomPageStore';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import './ExportBom.less';
import { EmsSelect } from '../../../components/ems-select/ems-select';
import { getBomYears, getYears } from '../../../api/cached/getYears';
import { EmsAllSelect } from '../../../components/ems-select/ems-all-select';
import { CurrentCampaignInfo, getCurrentCampaign } from '../../../api/cached/getCurrentCampaign';

export const msgExportBom = 'Create BOM-Matrix';

interface PropsExportBomModal {
  store: BomPageStore;
}
interface YearItem {
  yearId: number;
  name: string;
}

const msgWrongItems =
  "BOM Matrix can't be generated because some BOM files have incorrect data. Remove those files";

export const ExportBomModal: React.FC<PropsExportBomModal> = observer(({ store }) => {
  const [info, setInfo] = React.useState<CurrentCampaignInfo>();
  const [options, setOptions] = React.useState<YearItem[]>();
  const [defaultYear, setdefaultYear] = React.useState(info?.year);
  const [defaultEmsCode, setdefaultEmsCode] = React.useState('all');
  const [defaultCalculationType, setCalculationType] = React.useState('BOM');
  const [defaultPcs, setdefaultPcs] = React.useState('Weighted');
  const { exportBom } = store;

  React.useEffect(() => {
    getBomYears().then(setOptions);
    getCurrentCampaign().then(campaignInfo => {
      setInfo(campaignInfo);
      setdefaultYear(campaignInfo?.year);
    });
  }, []);
  const onInternalChangeYear = (newYearValue: number) => {
    setdefaultYear(newYearValue);
  };
  const onInternalChangeEms = (newEmsValue: string) => {
    setdefaultEmsCode(newEmsValue);
  };
  const onInternalChangePcs = (newPcsValue: string) => {
    setdefaultPcs(newPcsValue);
  };
  const onInternalChangeMatrixType = (newMatrixTypeValue: string) => {
    setCalculationType(newMatrixTypeValue);
  };

  return (
    <Modal
      title={msgExportBom}
      visible={exportBom.visible}
      onCancel={exportBom.close}
      maskClosable={false}
      okText='Download'
      destroyOnClose
      footer={[
        <Button type='default' key='Cancel' onClick={exportBom.close}>
          Cancel
        </Button>,
        exportBom.downloadUrl ? (
          <Button type='primary'>
            <a
              key='Action'
              href={exportBom.downloadUrl}
              target='_blank'
              rel='noreferrer'
              onClick={exportBom.download}
            >
              Download
            </a>
          </Button>
        ) : (
          <Tooltip key='Gen' title={exportBom.invalidCount > 0 ? msgWrongItems : ''}>
            <Button
              type='default'
              key='Action'
              loading={exportBom.saving}
              disabled={exportBom.disabled}
              onClick={() => {
                exportBom.prepareDownload(
                  defaultYear,
                  defaultEmsCode,
                  defaultCalculationType,
                  defaultPcs
                );
              }}
            >
              Generate
            </Button>
          </Tooltip>
        ),
      ]}
    >
      <Form.Item label='Matrix' name='matrixType' wrapperCol={{ sm: { offset: 6 } }}>
        <Select
          onChange={onInternalChangeMatrixType}
          options={[{ value: 'BOM', label: 'BOM' }]}
          defaultValue={defaultCalculationType}
          value={defaultCalculationType}
        />
      </Form.Item>
      <Form.Item
        label='Calculation Year'
        initialValue={defaultYear}
        name='year'
        wrapperCol={{ sm: { offset: 3 } }}
      >
        <Select onChange={onInternalChangeYear} defaultValue={info?.year}>
          {options &&
            options.map(rec => (
              <Select.Option key={rec.yearId} value={rec.name}>
                {rec.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='EMS'
        name='emsCode'
        style={{ paddingLeft: 10 }}
        wrapperCol={{ sm: { offset: 6 } }}
      >
        <EmsAllSelect onChange={onInternalChangeEms} val={defaultEmsCode} />
      </Form.Item>
      <Form.Item label='Price Calculation Schema' name='calculationType'>
        <Select
          onChange={onInternalChangePcs}
          options={[
            { value: 'Min', label: 'Min' },
            { value: 'Max', label: 'Max' },
            { value: 'Avg', label: 'Avg' },
            { value: 'Weighted', label: 'Weighted' },
          ]}
          value={defaultPcs}
          defaultValue={defaultPcs}
        />
      </Form.Item>
      <div className='bom-export-instruction'>
        Please upload project files (xlsx, xls, csv or txt file of predefined format). Once all
        required project files are uploaded click Generate button to generate and download a BOM
        matrix file.
      </div>
      <Upload
        accept='.xls,.xlsx,.csv,.txt'
        multiple
        customRequest={async options => {
          exportBom.lock();
          try {
            await exportBom.loadItem(options.file);
            options.onSuccess({}, options.file);
          } catch (e) {
            options.onError(e);
            exportBom.onError(options.file.uid);
          }
          exportBom.unlock();
          return false;
        }}
        onRemove={file => exportBom.onRemove(file.uid)}
        itemRender={(originNode, file): React.ReactNode => (
          <div className='export-bom-item'>
            <div className='export-bom-item-1'>{originNode}</div>
            {!!exportBom.getLogUrl(file) && (
              <Tooltip title='Click here to view last import log'>
                <a
                  href={exportBom.getLogUrl(file)}
                  target='_blank'
                  rel='noreferrer'
                  className='export-bom-item-2'
                >
                  <DownloadOutlined />
                </a>
              </Tooltip>
            )}
          </div>
        )}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      <div style={{ color: 'white' }}>
        Invalid count: {exportBom.invalidCount}, good count: {exportBom.goodItems}
      </div>
    </Modal>
  );
});

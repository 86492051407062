import React, { FunctionComponent } from 'react';
import { TableUploaderModal } from '../../../components/TableUploader';
import { ToolsMenuExt, ToolsMenuItem } from '../../../components/ToolsMenuExt';
import { observer } from 'mobx-react';
import { CampaignPageStore } from '../CampaignPageStore';
import { NewCampaignModal } from './NewCampaignModal';

interface CampaignMenuProps {
  store: CampaignPageStore;
}

export const CampaignMenu: FunctionComponent<CampaignMenuProps> = observer(({ store }) => {
  const [openUpload, setOpenUpload] = React.useState(false);
  const onUpload = () => setOpenUpload(true);

  const menuItems: ToolsMenuItem[] = [
    {
      text: 'New Campaign',
      permission: store.actions?.startcampaign,
      onClick: () => {
        const curYear = store.currentCampaign?.year || new Date().getFullYear();
        store.newCampaign.activate({ year: curYear + 1 });
      },
    },
    { text: 'Import Exchange Rates', permission: store.actions?.import, onClick: onUpload },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={openUpload}
        title='Import Exchange Rates'
        importType='ExchangeRates'
        onClose={() => setOpenUpload(false)}
        store={store.table}
      />
      <NewCampaignModal store={store} />
    </>
  );
});

import { ApnRow } from './types';
import { LabelValue } from '../types';
import { priceFormatter } from '../../utils';

export const rowClasses = {
  grey: 'greyed-row',
  highlighted: 'row-highlighted',
  editable: 'editable-row',
  lightblue: 'lightblue-row',
};

export const isRowLightBlue = ({ hide }: ApnRow) => hide;

export const isRowGreyed = ({ orderCodeStatusName }: ApnRow) =>
  orderCodeStatusName === 'Disqualified' || orderCodeStatusName === 'Rejected';

export const apnRowClassName = (record: ApnRow): string => {
  const classNames: string[] = [rowClasses.editable];

  if (isRowGreyed(record)) {
    classNames.push(rowClasses.grey);
  }

  if (isRowLightBlue(record)) {
    classNames.push(rowClasses.lightblue);
  }
  return classNames.join(' ');
};

export const getUpdateCurrencyHandler = (currencyList: LabelValue[]) => ({
  currencyCode,
  quotaId,
}: Partial<ApnRow>): Partial<ApnRow> => {
  const currency = currencyList.find(({ value }) => value === currencyCode);

  return {
    ...(currency ? { currencyCode: currency?.label } : {}),
    ...(currency ? { currencyId: currency?.id } : {}),
    quotaId,
  };
};

export const formatConvertedPriceEuro = (value: number | null): string => {
  if (typeof value !== 'number') {
    return '';
  }
  return priceFormatter(value);
};

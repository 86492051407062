import { LoaderStore } from '../../common/LoaderStore';
import { FnDataLoad, TableStore } from '../../common/TableStore';
import { FnFormSave, FormStore } from '../../common/FormStore';
import { CommodityFilter, CommodityRow } from './CommodityPage.types';
import { getRows } from '../../api/getRows';
import { Rest } from '../../api/Rest';
import { ActionItem, ActionPermission, NegotiatorItem } from '../../api/types';
import { findPermission } from '../../utils';
import { getNegotiators } from '../../api/cached/getNegotiators';
import { action, computed, makeObservable, observable } from 'mobx';
import { updatePageActions } from '../../common/updatePageActions';
import { icmp } from '../../common/icmp';

const loadData: FnDataLoad<CommodityRow, CommodityFilter> = (params: CommodityFilter) =>
  getRows('/api/v1/Commodity', params);

const saveData: FnFormSave<CommodityRow> = (oldData, newData) =>
  Rest.patch(`/api/v1/Commodity/${oldData.commodityId}`, {
    ...newData,
    commodityId: oldData.commodityId,
  });

export const getCommodityExportKey = (): Promise<string> =>
  Rest.postExt('/api/v1/Commodity/export', { responseType: 'text' });

class CommodityTableStore extends TableStore<CommodityRow, CommodityFilter> {
  form: FormStore<CommodityRow>;

  constructor() {
    super(
      {
        page: 0,
        pageSize: 10,
      },
      loadData
    );
    this.form = new FormStore(saveData, this.onEditFinish);
  }

  onEditStart = (index: number) => {
    this.form.activate(this.data[index]);
  };
  onEditFinish = (): void => {
    const { commodityId } = this.form.data;
    const rowIndex = this.data.findIndex(row => row.commodityId === commodityId);
    if (rowIndex >= 0) {
      this.modifyRow(rowIndex, this.form.data);
    }
  };
}

export class CommodityPageStore extends LoaderStore {
  table: CommodityTableStore;
  actions: ActionItem[] = [];
  negotiators: NegotiatorItem[] = [];

  constructor() {
    super();
    this.table = new CommodityTableStore();
    makeObservable(this, {
      actions: observable,
      setActions: action,
      canEdit: computed,
    });
  }

  get canEdit(): ActionPermission | undefined {
    return findPermission(this.actions, 'Edit');
  }
  setActions(actions: ActionItem[]) {
    this.actions = actions;
  }

  get importPermission(): ActionPermission | undefined {
    const action = this.actions.find(item => item.name === 'Import');
    return action && action.permission;
  }

  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      const requests = [
        updatePageActions('Commodity', this),
        getNegotiators().then(list => {
          list.sort((a, b) => icmp(a.name, b.name));
          this.negotiators = list;
        }),
      ];
      Promise.all(requests)
        .then(() => {
          this.onReady();
          this.table.init();
        })
        .catch(e => this.onError(e));
    } else {
      Promise.all([this.table.reload(), updatePageActions('Commodity', this)]);
    }
  }
}

import * as React from 'react';
import { observer } from 'mobx-react';
import { MYAPageStore } from './MYAPageStore';
import { MYARowAction } from './MYA.types';
import { MYAEditModal } from './MYAEditModal';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { ActionPermission } from '../../api/types';

interface PropsMYATools {
  store: MYAPageStore;
}

export const MYATools: React.FC<PropsMYATools> = observer(({ store }) => {
  const onAddNew = () => {
    store.addNew.activate({
      myaId: -1,
      manufacturerId: null,
      manufacturerName: null,
      subject: null,
      startDate: null,
      endDate: null,
      comments: null,
      link: null,
      actions: {} as Record<MYARowAction, ActionPermission>,
    });
  };
  const menuItems: ToolsMenuItem[] = [
    { text: 'Add a new MYA', permission: store.actions?.newmya, onClick: onAddNew },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <MYAEditModal title='Add a new MYA' isCreate editStore={store.addNew} />
    </>
  );
});

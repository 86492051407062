import { BaseFilter, TableStore } from './TableStore';
import { Rest } from '../api/Rest';
import { drawErrorExt } from './drawErrorMessage';

interface PageStore<TRow, TFilter extends BaseFilter<TRow>, TActions> {
  table: TableStore<TRow, TFilter>;
  setActions(actions: TActions): void;
}

export const updatePageActions = <TRow, TFilter extends BaseFilter<TRow>, TActions>(
  name: string,
  store: PageStore<TRow, TFilter, TActions>
): Promise<void> => {
  store.table.lock();
  const url = `/api/v1/${name}/actions`;
  return Rest.get<TActions>(url)
    .then(response => store.setActions(response))
    .catch(e => drawErrorExt<void>(e, "Can't load actions"))
    .finally(() => store.table.unlock());
};

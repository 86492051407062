// Helper for get-request with pagination
// Useful for TableStore
import { BaseFilter, RemoteResponse } from '../common/TableStore';
import { Rest, makeStrParams } from './Rest';

const directionMap: Record<string, string> = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const getRows = <TRow, TFilter extends BaseFilter<TRow>>(
  url: string,
  params: TFilter
): Promise<RemoteResponse<TRow>> => {
  interface Response {
    data: TRow[];
    meta: {
      totalCount: number;
    };
  }
  const strParams = makeStrParams(params);
  if (strParams) {
    if (!params.pageSize) {
      delete strParams.pageSize;
    }
    if (strParams.sortBy && strParams.sortOrder) {
      strParams.Column = strParams.sortBy;
      strParams.Direction = directionMap[strParams.sortOrder as string];
      delete strParams.sortOrder;
      delete strParams.sortBy;
    }
  }
  return Rest.get<Response>(url, strParams).then(response => ({
    data: response.data,
    total: response.meta.totalCount,
  }));
};

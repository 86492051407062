import * as React from 'react';
import { ToolsMenu } from './tools-menu';
import { Menu } from 'antd';
import { ActionPermission } from '../api/types';
import { PermissionCheck } from './PermissionCheck';

export interface ToolsMenuItem {
  text: string;
  permission?: ActionPermission | boolean; // use true, if have no permission for item
  onClick(): void;
}

interface PropsToolsMenuExt {
  items: ToolsMenuItem[];
}

export const ToolsMenuExt: React.FC<PropsToolsMenuExt> = ({ items }) => {
  const isVisible = !!items.find(it => it.permission);
  if (!isVisible) {
    return null;
  }
  return (
    <ToolsMenu>
      {items.map(({ text, permission, onClick }) =>
        typeof permission === 'boolean' ? (
          <Menu.Item key={text} onClick={onClick}>
            {text}
          </Menu.Item>
        ) : (
          <PermissionCheck key={text} permission={permission} onClick={onClick}>
            <Menu.Item>{text}</Menu.Item>
          </PermissionCheck>
        )
      )}
    </ToolsMenu>
  );
};

import * as React from 'react';
import { Select } from 'antd';
import { Project } from '../../api/types';
import { Rest } from '../../api/Rest';
import { drawErrorMessage } from '../../common/drawErrorMessage';

const { Option } = Select;

interface ProjectSelectProps {
  disabled?: boolean;
  value?: string;
  onChange?: (emsCode: string) => void;
  bshpartno: string;
}

export const ProjectSelectBsh: React.FC<ProjectSelectProps> = ({
  disabled,
  value,
  onChange,
  bshpartno,
}) => {
  const [projects, setProjects] = React.useState<Project[] | null>(null);
  const loading = projects === null;
  React.useEffect(() => {
    Rest.get<{ data: Project[] }>('/api/v1/Settings/projectsfilter', {
      bshpartno,
      pageSize: '1000',
    })
      .then(res => setProjects(res.data))
      .catch(e => drawErrorMessage(e));
  }, [bshpartno]);
  return (
    <Select
      mode='multiple'
      loading={loading}
      showSearch
      placeholder='Start typing to find Project'
      disabled={disabled}
      value={value}
      onChange={onChange}
      filterOption={(input, option) => {
        return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {projects?.map(project => (
        <Option value={project.projectId} key={project.projectId}>
          {project.name}
        </Option>
      ))}
    </Select>
  );
};

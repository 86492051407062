import * as React from 'react';
import { Button, Modal } from 'antd';
import { TableExport } from './TableExport';
import { TableExportStore } from './TableExportStore';

export interface PropsTableExportModal {
  visible: boolean;
  title: string;
  onClose(): void;
  getExportKey(): Promise<string>;
}

export const TableExportModal: React.FC<PropsTableExportModal> = ({
  visible,
  title,
  onClose,
  getExportKey,
}) => {
  const [store] = React.useState(new TableExportStore(getExportKey, onClose));
  React.useEffect(() => {
    if (visible) {
      store.start().catch(e => {});
    }
  }, [store, visible]);
  const onCancel = () => {
    store.close();
  };
  return (
    <Modal
      visible={visible}
      title={title}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button key='close' onClick={onCancel}>
          Cancel
        </Button>,
      ]}
    >
      <TableExport store={store} />
    </Modal>
  );
};

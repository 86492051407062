import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { MatrixBasisPageStore } from './MatrixBasisPageStore';
import { MatrixBasisRow } from './MatrixBasisPage.types';
import { dateSorter, numberFormatter } from '../../utils';
import { MatrixBasisActions } from './MatrixBasisActions';
import { LockOutlined } from '@ant-design/icons';
import { MatrixBasisEdit } from './MatrixBasisEdit';
import { MatrixBasisQuotaTable } from './MatrixBasisQuota';
import './MatrixBasisTable.less';
import { defaultCurrencyStore } from './DefaultCurrencyStore';

interface PropsDemandTable {
  store: MatrixBasisPageStore;
}

const rowClassName = (record: MatrixBasisRow): string =>
  [
    ...(record.isLocked ? ['demand-row-locked'] : []),
    ...(record.isNew ? ['demand-row-new'] : []),
  ].join(' ');
const commodityCell = (rec: MatrixBasisRow) => {
  if (rec.priceType != 'APN') {
    return 'n/a';
  } else {
    return `${rec.commodityCode || ''} ${rec.commodityGpmt || ''} ${rec.commodityName || ''}`;
  }
};

export const MatrixBasisTable: React.FC<PropsDemandTable> = observer(({ store }) => {
  const { table, bundlingOptions } = store;
  const { defaultCurrency } = defaultCurrencyStore;
  const rowSelection = store.allowBulk
    ? {
        type: 'checkbox' as 'checkbox',
        selectedRowKeys: table.checkedRows.map(({ demandId }) => demandId),
        getCheckboxProps: (record: MatrixBasisRow) => ({
          name: String(record.demandId),
          disabled: false,
        }),
        onChange: (_selectedRowKeys: React.Key[], selectedRows: MatrixBasisRow[]) => {
          table.setCheckedRows(selectedRows);
        },
      }
    : undefined;

  return (
    <>
      <Table<MatrixBasisRow>
        size='small'
        rowKey='demandId'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
        onChange={table.onTableChange}
        className='demand-table'
        rowClassName={rowClassName}
        rowSelection={rowSelection}
        expandable={{
          expandedRowRender: record => (
            <MatrixBasisQuotaTable store={table.quotas[record.demandId]} />
          ),
          onExpand: (expanded, record) => table.onExpand(expanded, record),
          expandedRowKeys: table.expandedRowKeys,
        }}
      >
        <Table.Column
          title='BSH Part No'
          key='bshPartNo'
          dataIndex='bshPartNo'
          width='10%'
          render={(_, record: MatrixBasisRow) => {
            if (record.isNew) {
              return <>@ {record.bshPartNo}</>;
            }

            if (record.isLocked) {
              return (
                <>
                  <LockOutlined /> {record.bshPartNo}
                </>
              );
            }

            return record.bshPartNo;
          }}
        />
        <Table.Column
          title='Designation'
          dataIndex='designation'
          key='designation'
          sorter
          ellipsis
          width='7%'
        />
        <Table.Column
          title='Demand'
          dataIndex='demand'
          key='demand'
          sorter
          width='7%'
          render={(_, record: MatrixBasisRow) => {
            if (record.priceType !== 'APN') {
              return 'n/a';
            } else {
              return numberFormatter(record.demand);
            }
          }}
        />
        <Table.Column
          title={'Min Price ' + defaultCurrency}
          dataIndex='minPrice'
          key='minPrice'
          width='9.5%'
          render={(_, record: MatrixBasisRow) => {
            if (record.priceType === 'Estimate') {
              return 'n/a';
            } else {
              return _.toFixed(6);
            }
          }}
        />
        <Table.Column
          title={'Max Price ' + defaultCurrency}
          dataIndex='maxPrice'
          key='maxPrice'
          width='9.5%'
          render={(_, record: MatrixBasisRow) => {
            if (record.priceType === 'Estimate') {
              return 'n/a';
            } else {
              return _.toFixed(6);
            }
          }}
        />
        <Table.Column
          title={'Avg Price ' + defaultCurrency}
          dataIndex='avgPrice'
          key='avgPrice'
          width='9.5%'
          render={(_, record: MatrixBasisRow) => {
            return _.toFixed(6);
          }}
        />
        <Table.Column
          title={'Weighted Price ' + defaultCurrency}
          dataIndex='weightedPrice'
          key='weightedPrice'
          width='9.5%'
          render={(_, record: MatrixBasisRow) => {
            if (
              record.priceType === 'Estimate' ||
              record.priceType === 'EMS' ||
              record.priceType === 'Offer'
            ) {
              return 'n/a';
            } else {
              return _.toFixed(6);
            }
          }}
        />
        <Table.Column
          title='Price Type'
          dataIndex='priceType'
          key='priceType'
          sorter
          width='7.5%'
        />
        <Table.Column
          title='Bundling'
          key='bundling'
          dataIndex='bundlingName'
          width='9%'
          // onCell={editableCellHandler<MatrixBasisRow>({
          //     store: table,
          //     keyField: 'demandId',
          //     isEditable: ({ actions }) => actions.edit?.allow,
          //     field: 'bundlingName',
          //     component: NestedSelect,
          //     preSave: setBundlingId,
          //     extraProps: {
          //         options: bundlingOptions,
          //         allowClear: true,
          //     },
          // })}
        />
        {/*
          <Table.Column title='Year' dataIndex='year' key='year' width='7%' filters={table.data.map(({ year }) => ({ text: year, value: year }))} onFilter={(value, record:MatrixBasisRow) => record.year === value}  />
*/}
        <Table.Column title='Year' dataIndex='year' key='year' width='7%' />
        <Table.Column
          title='Quantity Base'
          dataIndex='quantityBase'
          key='quantityBase'
          width='8%'
          render={(_, record: MatrixBasisRow) => {
            if (record.priceType === 'APN' || record.priceType === 'EMS') {
              return 'n/a';
            } else {
              return numberFormatter(record.quantityBase);
            }
          }}
        />
        <Table.Column
          title='Commodity'
          key='commodity'
          width='10%'
          render={commodityCell}
          ellipsis
        />
        <Table.Column
          title='Max Lead Time[wks]'
          dataIndex='maxLeadTime'
          key='maxLeadTime'
          width='10%'
        />
        <Table.Column title='Comment' dataIndex='comment' key='comment' width='10%' />
        <Table.Column title='Links' dataIndex='links' key='links' width='10%' />
      </Table>
      <MatrixBasisEdit formStore={table.edit} />
    </>
  );
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { RequestForQuotationPageStore } from './RequestForQuotationPageStore';
import { TableUploaderModal } from '../../components/TableUploader';
import { ApnExport, msgExportMenuItem } from '../../components/ApnExport';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';

interface PropsRFQMenu {
  store: RequestForQuotationPageStore;
}
type Mode = 'importRfq' | 'exportRfq';

const msgImport = 'RFQ Import';

export const RFQMenu: React.FC<PropsRFQMenu> = observer(({ store }) => {
  const [mode, setMode] = React.useState<'' | Mode>('');
  const clear = () => setMode('');
  const menuItems: ToolsMenuItem[] = [
    { text: msgImport, permission: store.actions?.import, onClick: () => setMode('importRfq') },
    {
      text: msgExportMenuItem,
      permission: store.actions?.export,
      onClick: () => setMode('exportRfq'),
    },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={mode === 'importRfq'}
        title={msgImport}
        importType='RfqManufacturer'
        onClose={clear}
        store={store.table}
      />
      <ApnExport visible={mode === 'exportRfq'} onClose={clear} filter={store.table.filter} />
    </>
  );
});

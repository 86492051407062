import React, { FunctionComponent } from 'react';
import { Button, Table } from 'antd';
import { OrderCodeRow } from './OrderCodes.types';
import { ActionPermission } from '../../../api/types';
import { PermissionCheck } from '../../../components/PermissionCheck';

interface OrderCodeTableProps {
  orderCodes: OrderCodeRow[] | null;
  actionName: string;
  loading: boolean;
  onAction: (record: OrderCodeRow) => void;
  canEdit?: ActionPermission;
}

export const OrderCodeTable: FunctionComponent<OrderCodeTableProps> = ({
  orderCodes,
  actionName,
  onAction,
  loading,
  canEdit,
}) => {
  return (
    <Table<OrderCodeRow>
      size='small'
      rowKey='edmId'
      dataSource={orderCodes || []}
      loading={loading}
      pagination={false}
    >
      <Table.Column title='Order code' key='orderCode' dataIndex='orderCode' width='40%' />
      <Table.Column title='BSH Part No' key='bshPartNo' dataIndex='bshPartNo' width='40%' />
      <Table.Column
        title='Action'
        key='action'
        width='20%'
        render={record => (
          <PermissionCheck permission={canEdit} type='link' onClick={() => onAction(record)}>
            <Button>{actionName}</Button>
          </PermissionCheck>
        )}
      />
    </Table>
  );
};

import React, { FunctionComponent } from 'react';
import { Popover } from 'antd';
import { isEmptyValue, percentFormatter, priceFormatter } from '../../utils';
import { PreviousYearInfoRow } from './PreviousYearInfo.types';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface PreviousYearInfoProps {
  data: PreviousYearInfoRow;
  year?: number;
}

const getPreviousYear = (currentYear?: number) => currentYear && currentYear - 1;

const roundPrice = (value: number): string =>
  priceFormatter(typeof value === 'number' ? Math.round(value) : value);

const getPreviousYearInfoFields = (
  currentYear?: number
): { title: string; field: keyof PreviousYearInfoRow; formatter?: (value: number) => string }[] => [
  { title: `Price ${currentYear} EUR w/o curr.`, field: 'convertedPriceEuro' },
  { title: `PV @${currentYear} in EUR`, field: 'pvEuro' },
  { title: `Price ${getPreviousYear(currentYear)} EUR w/curr. IST`, field: 'previousIstEuro' },
  {
    title: `Price ${getPreviousYear(currentYear)} EUR w/o curr.`,
    field: 'previousConvertedPriceEuro',
  },
  { title: `PV @${getPreviousYear(currentYear)} Mixed Price in EUR`, field: 'previousMixedPrice' },
  { title: `PV @${getPreviousYear(currentYear)} in EUR`, field: 'previousPvEuro' },
  { title: 'MPC EUR w/o curr.', field: 'mpvEuro', formatter: roundPrice },
  { title: 'MPC [%] w/o curr', field: 'mpvPercent', formatter: percentFormatter },
];

export const PreviousYearInfo: FunctionComponent<PreviousYearInfoProps> = ({ data, year }) => {
  return (
    <Popover
      placement='left'
      content={
        <div style={{ minWidth: 200 }}>
          {getPreviousYearInfoFields(year).map(({ title, field, formatter = priceFormatter }) => {
            const value = data[field];
            const formatted = isEmptyValue(value) ? '-' : formatter(value as number);
            return (
              <div key={title}>
                <strong>{title}:</strong> {formatted}
              </div>
            );
          })}
        </div>
      }
    >
      <ExclamationCircleOutlined />
    </Popover>
  );
};

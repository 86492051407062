import * as React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { nestedKeyboardHandler } from './nestedKeyboardHandler';
import { PropsNested } from './nested.types';
import { Rule } from 'antd/es/form';
import { decimalSeparator } from '../../../utils';

interface PropsNestedInputNumber extends PropsNested {
  min?: number;
  max?: number;
  precision?: number;
  formatter?: (value?: string | number) => string;
  parser?: (value?: string) => string;
}

const addRule = (rules: Rule[], fn: (value: number) => string): Rule[] => [
  ...rules,
  () => ({
    validator(_, value) {
      const msg = fn(value);
      return msg ? Promise.reject(msg) : Promise.resolve();
    },
  }),
];

const generateRules = (rules: Rule[] = [], min?: number, max?: number) => {
  if (min !== undefined && max !== undefined) {
    return addRule(rules, (value: number) =>
      value < min || value > max ? `Value must be between ${min} and ${max}` : ''
    );
  }

  if (min !== undefined) {
    return addRule(rules, (value: number) =>
      value < min ? `Value can't be less then ${min}` : ''
    );
  }

  if (max !== undefined) {
    return addRule(rules, (value: number) =>
      value > max ? `Value can't be greater then ${max}` : ''
    );
  }

  return rules;
};

export const NestedInputNumber: React.FC<PropsNestedInputNumber> = props => {
  const { name, status, onCancel, onFinish, min, max, precision, parser, formatter } = props;
  const rules = generateRules(props.rules, min, max);
  const inputRef = React.useRef<Input>(null);

  React.useEffect(() => {
    if (status === 'edit') {
      inputRef.current!.focus();
    }
  }, [status, inputRef]);

  return (
    <Form.Item style={{ margin: 0 }} name={name} rules={rules}>
      <InputNumber
        ref={inputRef}
        onKeyUp={nestedKeyboardHandler(onCancel, onFinish)}
        onBlur={onFinish}
        disabled={status === 'save'}
        precision={precision}
        formatter={formatter}
        parser={parser}
        decimalSeparator={decimalSeparator}
      />
    </Form.Item>
  );
};

import * as React from 'react';
import { TableUploaderModal } from '../../components/TableUploader';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { ComponentPageStore } from './ComponentPage.store';
import { observer } from 'mobx-react';
import { TableExportModal } from '../../components/TableExport';

interface PropsComponentMenu {
  store: ComponentPageStore;
}

const msgExport = 'Export Components';

export const ComponentMenu: React.FC<PropsComponentMenu> = observer(({ store }) => {
  const [importOpen, setImportOpen] = React.useState(false);
  const [exportOpen, setExportOpen] = React.useState(false);
  const menuItems: ToolsMenuItem[] = [
    {
      text: 'Update Commodity',
      permission: store.actions?.import,
      onClick: () => setImportOpen(true),
    },
    {
      text: msgExport,
      permission: true,
      onClick: () => setExportOpen(true),
    },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={importOpen}
        title='Update Commodity data'
        importType='ComponentsCommodity'
        onClose={() => setImportOpen(false)}
        store={store.table}
      />
      <TableExportModal
        visible={exportOpen}
        title={msgExport}
        onClose={() => setExportOpen(false)}
        getExportKey={store.getExportKey}
      />
    </>
  );
});

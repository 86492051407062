import * as React from 'react';
import { observer } from 'mobx-react';
import { DemandPageStore } from './DemandPageStore';
import { TableUploaderModal } from '../../components/TableUploader';
import { addNoItemsPermission } from '../../utils';
import { TableExportModal } from '../../components/TableExport';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { BulkEMSChange } from './bulk-ems-change/bulk-ems-change';

interface PropsDemandTools {
  store: DemandPageStore;
}
type Mode = 'importComponents' | 'importDemand'| 'importPreviousYearPrice';

const msgImport = 'Import demand file';
const msgImportPreviousYear = 'Update prices prev. year';
const msgExport = 'Export demand file';
const msgBulkEMS = 'Change EMS for selected';
const msgImportComponents = 'Import components';

export const DemandTools: React.FC<PropsDemandTools> = observer(({ store }) => {
  const [mode, setMode] = React.useState<'' | Mode>('');
  const clear = () => setMode('');
  const { table } = store;
  const { canLock, canUnlock } = table;
  const addComponentPermission = store.permissions?.addcomponent;
  const addPreviousComponentPermission = store.permissions?.addpreviouscomponent;
  const allowBulk = store.permissions?.bulklock;
  const bulkChangeEMSPermission = store.permissions?.bulkemschange;
  const [importActive, setImportActive] = React.useState(false);
  const [exportActive, setExportActive] = React.useState(false);
  const [isBulkChangeEMSModalVisible, setIsBulkChangeEMSModalVisible] = React.useState(false);
  const reload = async () => {
    await table.reload();
  };
  const canLockPermission = addNoItemsPermission(
    canLock,
    allowBulk,
    'Please check a row suitable for lock'
  );
  const canUnlockPermission = addNoItemsPermission(
    canUnlock,
    allowBulk,
    'Please check a row suitable for unlock'
  );
  const canBulkChangeEmskPermission = addNoItemsPermission(
    table.checkedRows.length > 0,
    bulkChangeEMSPermission,
    'Please check a row suitable for bulk ems change'
  );
  const menuItems: ToolsMenuItem[] = [
    {
      text: 'Add component',
      permission: addComponentPermission,
      onClick: () => store.startAddLine(),
    },
      {
          text: 'Add component prev. year',
          permission: addPreviousComponentPermission,
          onClick: () => store.startPreviousYearAddLine(),
      },
      {
          text: msgImportPreviousYear,
          permission: store.permissions?.previousyearprice,
          onClick: () => setMode('importPreviousYearPrice'),
      },
    { text: 'Lock', permission: canLockPermission, onClick: () => table.lockChecked(true, reload) },
    {
      text: 'Unlock',
      permission: canUnlockPermission,
      onClick: () => table.lockChecked(false, reload),
    },
    {
      text: msgImport,
      permission: store.permissions?.import,
      onClick: () => setMode('importDemand'),
    },
    {
      text: msgExport,
      permission: store.permissions?.export,
      onClick: () => setExportActive(true),
      },
      {
          text: msgBulkEMS,
          permission: canBulkChangeEmskPermission,
          onClick: () => setIsBulkChangeEMSModalVisible(true),
      },
      {
          text: msgImportComponents,
          permission: store.permissions?.addcomponent,
          onClick: () => setMode('importComponents'),
      },
  ];

  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={mode === 'importDemand'}
        title={msgImport}
        importType='EmsDemand'
        onClose={clear}
        //onClose={() => setImportActive(false)}
        store={store.table}
      />
        <TableUploaderModal
            visible={mode === 'importPreviousYearPrice'}
            title={msgImportPreviousYear}
            importType='PreviousYearPrice'
            onClose={clear}
            //onClose={() => setImportActive(false)}
            store={store.table} /*FIXME is this needed !?*/
        />
      <TableExportModal
        visible={exportActive}
        title={msgExport}
        onClose={() => setExportActive(false)}
        getExportKey={store.getExportKey}
      />
      <BulkEMSChange
        store={store}
        visible={isBulkChangeEMSModalVisible}
        onClose={() => {setIsBulkChangeEMSModalVisible(false); reload();}}
      />
          <TableUploaderModal
              visible={mode === 'importComponents'}
              title={msgImportComponents}
              importType='Components'
              //  onClose={() => setImportActive(false)}
              onClose={clear}
              store={store.table}
      />
    </>
  );
});

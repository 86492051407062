import React, { FunctionComponent } from 'react';
import { Layout } from 'antd';
import { HeaderMenu } from '../header-menu';
import './default-layout.less';

const { Header, Content, Footer } = Layout;

export const DefaultLayout: FunctionComponent = ({ children }) => {
  return (
    <Layout className='default-layout'>
      <Header className='header'>
        <HeaderMenu />
      </Header>

      <Content className='content-wrapper'>
        <div className='content'>{children}</div>
      </Content>

      <Footer className='footer'>
        <div>© 2020 BSH Hausgeräte GmbH</div>
        <div>A company of the Bosch Group</div>
      </Footer>
    </Layout>
  );
};

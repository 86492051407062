import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DemandRow, prepareToEdit } from './DemandPage.types';
import { DemandPageStore } from './DemandPageStore';
import { PermissionCheck } from '../../components/PermissionCheck';
import { ActionPermission, UserActions } from '../../api/types';

interface PropsDemandActions {
  row: DemandRow;
  store: DemandPageStore;
}

const lockingPermission: ActionPermission = { allow: false, reasons: ['Locking'] };

export const DemandActions: React.FC<PropsDemandActions> = observer(({ row, store }) => {
  const { table } = store;
  const onDelete = () => {
    const { destroy } = Modal.confirm({
      title: `Component will be deleted!`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: async () => {
        destroy();
        table.deleteRow(row);
      },
    });
  };
  const { actions } = row;
  const isLocking = table.isLocking(row);

  const onClick = () => {
    table.edit.activate(prepareToEdit(row));
  };

  return (
    <>
      <PermissionCheck permission={actions.edit} type='link' onClick={onClick}>
        <Button>edit</Button>
      </PermissionCheck>
      {!row.isLocked && (
        <PermissionCheck
          permission={isLocking ? lockingPermission : actions.lock}
          type='link'
          onClick={() => table.toggleLock(row)}
        >
          <Button>lock</Button>
        </PermissionCheck>
      )}
      {row.isLocked && (
        <PermissionCheck
          permission={isLocking ? lockingPermission : actions.unlock}
          type='link'
          onClick={() => table.toggleLock(row)}
        >
          <Button>unlock</Button>
        </PermissionCheck>
      )}
      <PermissionCheck permission={actions.delete} type='link' onClick={onDelete}>
        <Button>delete</Button>
      </PermissionCheck>
    </>
  );
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { FormStore } from '../../common/FormStore';
import { ModalForm } from '../../components/ModalForm';
import { Form, Input } from 'antd';
//import { DemandField } from './demand-field';
import { Rule } from 'antd/es/form';
import { formLayout } from '../../common/constants';
import { SD2Row } from './SD2Table/SD2Table.types';

interface PropsShareDistributionEdit {
  formStore: FormStore<SD2Row>;
}

const orderCodeRules: Rule[] = [{ max: 100, message: 'Can not be longer than 100 characters' }];
const distriPartNoRules: Rule[] = [{ max: 100, message: 'Can not be longer than 100 characters' }];

export const ShareDistributionEdit: React.FC<PropsShareDistributionEdit> = observer(
  ({ formStore }) => {
    const value = formStore.data;
    const onSave = (data: Partial<SD2Row>) => {
      const dataExt = {
        ...formStore.currentData,
        emsOrderCode: data.orderCode,
        distriPartNo: data.distriPartNo,
      };
      return formStore.save(dataExt);
    };

    return (
      <ModalForm
        title='Edit Order Code information'
        name='ShareDistributionEditForm'
        layout={formLayout}
        initialData={formStore.data}
        visible={formStore.visible}
        saving={formStore.saving}
        onClose={formStore.close}
        onSave={onSave}
      >
        <Form.Item label='Manufacturer'>{value?.manufacturerName}</Form.Item>
        <Form.Item label='OrderCode' name='orderCode' rules={orderCodeRules}>
          <Input />
        </Form.Item>
        <Form.Item label='DistriPartNo' name='distriPartNo' rules={distriPartNoRules}>
          <Input />
        </Form.Item>
        <Form.Item label='BshPartNo'>{value?.bshPartNo}</Form.Item>
        <Form.Item label='ReleaseStatus'>{value?.orderCodeStatusName}</Form.Item>
        <Form.Item label='EMS'>{value?.emsName}</Form.Item>
        <Form.Item label='Bundling'>{value?.bundlingName}</Form.Item>
        <Form.Item label='EMS Part No'>{value?.ems_Part_No}</Form.Item>
      </ModalForm>
    );
  }
);

import React from 'react';
import { Form, Select } from 'antd';

interface TemplateSelectProps {
    onSelectTemplate: (value: string) => void;
}

const templateSelectOptions = [
    { value: 'Version 1', label: 'Version 1' },
    { value: 'Version 2 with 5%', label: 'Version 2 with 5%' },
    { value: 'Backup Version', label: 'Version backup' },
];

export const TemplateSelect: React.FC<TemplateSelectProps> = ({ onSelectTemplate }) => {
    const templateRules = [{ required: true, message: 'Please select a template' }];

    return (
        <Form.Item
            label={
                <span>
                <span style={{ color: '#ff4d4f', fontSize: '14px', fontFamily: 'SimSun, sans-serif', marginRight: '4px' }}>
                    *
                </span>
                <span>
                    Template
                </span>
            </span>}

            rules={templateRules}>
            <Select
                onChange={(value) => onSelectTemplate(value.toString())} // Use onChange instead of onSelect
                style={{ width: '100%' }}
                placeholder='Select Template'
                defaultValue={templateSelectOptions[0].value}
                options={templateSelectOptions}
            />
        </Form.Item>
    );
};

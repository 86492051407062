import API from '@aws-amplify/api';

export const MAIN_API = 'Main api';

/**
 * It is necessary to remove the slash, if there is one at the end of the url.
 * @param {string} url
 */
const filterUrl = (url: string) => (url.endsWith('/') ? url.slice(0, -1) : url);

API.configure({
  endpoints: [
    {
      name: MAIN_API,
      endpoint: filterUrl(process.env.REACT_APP_API_URL || ''),
    },
  ],
});

import { Rest } from '../Rest';
import { LabelValue } from '../../common/types';

interface BundlingItem {
  disable: boolean;
  bundlingId: number;
  defValue: string | undefined;
  name: string;
}

let cache: BundlingItem[] | null = null;

export const getBundlings = async (): Promise<BundlingItem[]> => {
  if (!cache) {
    const response = await Rest.get<{ data: BundlingItem[] }>('/api/v1/Settings/bundlings');
    cache = response.data;
  }
  return cache;
};

export const getBundlingOptions = async (): Promise<LabelValue[]> =>
  (await getBundlings()).map(({ bundlingId, name }) => ({
    id: bundlingId,
    label: name,
    value: name,
  }));

interface BundlingRow {
  bundlingId: number | null;
  bundlingName: string | null;
}

// helper for setting of 'bundlingId' field after editing of 'bundlingName'
export const setBundlingId = <T extends BundlingRow>(src: Partial<T>): Partial<BundlingRow> => {
  if (!src.bundlingName) {
    return { bundlingId: null, ...src };
  }
  if (!cache) return src;
  const bundling = cache.find(({ name }) => name === src.bundlingName);
  return bundling ? { bundlingId: bundling.bundlingId, ...src } : src;
};

import { WithUserActions } from '../../utils/state-types';

export enum DemandStatus {
  Init = 'Init',
  EmsProcess = 'EMS Process',
  Verification = 'Verification',
  Close = 'Close',
}

export interface DemandModel {
  bshPartNo: string | null;
  prevBshPartNo: string | null;
  commodityCode: string | null;
  commodityGpmt: string | null;
  demand: number | null;
  demandId: number;
  designation: string | null;
  emsCode: string | null;
  emsId: number;
  emsName: string;
  emsPartNo: string | null;
  isLocked: boolean;
  isNew: boolean;
  partId: number;
  prevBundlingName: string | null;
  prevDemand: string | null;
  statusId: number;
  year: number;
  project?: string;
}

export type Demand = WithUserActions<DemandModel>;

export enum DemandUserActions {
  AddManufacturer = 'addmanufacturer',
  Delete = 'delete',
  Edit = 'edit',
  Lock = 'lock',
  Unlock = 'unlock',
}

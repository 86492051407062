import { FnDataLoad, TableStore } from '../../common/TableStore';
import { getRows } from '../../api/getRows';
import { LoaderStore } from '../../common/LoaderStore';
import {
  RequestForQuotationFilter,
  RequestForQuotationRow,
  RFQPageActionsMap,
} from './RequestForQuotationPage.types';
import { CurrentCampaignInfo, getCurrentCampaign } from '../../api/cached/getCurrentCampaign';
import { Rest } from '../../api/Rest';
import { getCurrencyOptions } from '../../api/cached/getCurrency';
import { LabelValue } from '../../common/types';
import { ApnFilterStore } from '../../components/ApnFilter/ApnFilterStore';
import { ComponentsExt, transformComponents } from '../../common/apn/transformComponents';
import { updateApnRow } from '../../common/apn/updateApnRow';
import { updatePageActions } from '../../common/updatePageActions';
import { action, makeObservable, observable } from 'mobx';
import { getBundlingOptions } from '../../api/cached/getBundlings';

const loadData: FnDataLoad<RequestForQuotationRow, RequestForQuotationFilter> = (
  params: RequestForQuotationFilter
) =>
  getRows<RequestForQuotationRow, ComponentsExt<RequestForQuotationFilter>>(
    '/api/v1/apn/Quotation',
    transformComponents(params)
  );

const saveQuotation = (rowPart: Partial<RequestForQuotationRow>): Promise<void> => {
  const { quotaId, ...data } = rowPart;
  return Rest.patch<void, typeof rowPart>(`/api/v1/apn/Quotation/${quotaId}`, data);
};

const saveDistribution = (
  rowPart: Partial<RequestForQuotationRow>,
  rows: RequestForQuotationRow[]
): Promise<void> => {
  const { quotaId, ...data } = rowPart;
  const srcRow = rows.find(row => row.quotaId === quotaId);
  if (!srcRow) {
    return Promise.reject(Error(`Not found row with quotaId='${quotaId}'`));
  }
  const { demandId } = srcRow;
  return Rest.patch(`/api/v1/apn/Distribution/${demandId}`, data);
};

class RequestForQuotationTableStore extends TableStore<
  RequestForQuotationRow,
  RequestForQuotationFilter
> {
  constructor() {
    super(
      {
        page: 0,
        pageSize: 10,
        sortOrder: 'ascend',
        sortBy: 'manufacturerName',
      },
      loadData
    );
  }

  save(rowPart: Partial<RequestForQuotationRow>): Promise<void> {
    // For editing of "demand" field we need to use Distribution request
    // This assumes that only one field is being edited.
    const res = 'demand' in rowPart ? saveDistribution(rowPart, this.data) : saveQuotation(rowPart);
    return res.then(() => updateApnRow(rowPart, this));
  }
}

export class RequestForQuotationPageStore extends LoaderStore {
  table: RequestForQuotationTableStore;
  filter: ApnFilterStore<RequestForQuotationRow, RequestForQuotationFilter>;
  campaign?: CurrentCampaignInfo;
  currencyList: LabelValue[] = [];
  bundlingList: LabelValue[] = [];
  actions?: RFQPageActionsMap;

  constructor() {
    super();
    this.table = new RequestForQuotationTableStore();
    this.filter = new ApnFilterStore(this.table);
    makeObservable(this, {
      actions: observable,
      setActions: action,
      campaign: observable,
      setCampaign: action,
    });
  }

  init() {
    const loadCampaign = async () => {
      this.setCampaign();
      this.setCampaign(await getCurrentCampaign());
    };
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([
        loadCampaign(),
        getCurrencyOptions().then(currencyList => (this.currencyList = currencyList)),
        getBundlingOptions().then(options => {
          this.bundlingList = options;
        }),
        updatePageActions('apn/Quotation', this),
      ])
        .then(() => this.onLoad())
        .catch(e => this.onError(e));
    } else {
      this.table.reload();
      loadCampaign();
      updatePageActions('apn/Quotation', this);
    }
  }
  setCampaign(campaign?: CurrentCampaignInfo) {
    this.campaign = campaign;
  }
  setActions(actions: RFQPageActionsMap) {
    this.actions = actions;
  }
  onLoad() {
    this.onReady();
    this.table.init();
  }

  get canEdit(): boolean {
    return this.actions?.edit?.allow || false;
  }
}

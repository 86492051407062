import React, { ReactElement } from 'react';
import { ResponseError } from '../init-interceptors';

let keyIndex = 1;

export type ErrorMessageType = string | ReactElement[][];

export const formatErrorMessage = (error: ResponseError | Error): ErrorMessageType => {
  if (!error) return '*Unknown error*';
  if ('response' in error && typeof error.response === 'object') {
    // @ts-ignore
    const { status } = error.response;
    if (status === 403) return 'You are not authorized to perform this operation.';
  }
  const reason = 'response' in error ? error.response?.data?.reason : null;

  if (!reason) return error.message;

  return Object.keys(reason).map(key =>
    reason[key].map(message => <div key={keyIndex++}>{message}.</div>)
  );
};

import * as React from 'react';
import { DQRow } from './DemandQuota.types';
import { DemandQuotaStore } from './DemandQuotaStore';
import { Button } from 'antd';
import { PermissionCheck } from '../../../components/PermissionCheck/PermissionCheck';

interface PropsDemandQuotaActions {
  row: DQRow;
  store: DemandQuotaStore;
}

export const DemandQuotaActions: React.FC<PropsDemandQuotaActions> = ({ row, store }) => {
  return (
    <>
      <PermissionCheck
        permission={row.actions.edit}
        type='link'
        onClick={() => store.editForm.activate(row)}
      >
        <Button>edit</Button>
      </PermissionCheck>
    </>
  );
};

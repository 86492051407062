import * as React from 'react';
import { ShareDistributionPageStore } from './ShareDistributionPageStore';
import { LOAExportStore } from '../LOA/LOAExportStore';
import { observer } from 'mobx-react';
import {
  ApnExport,
  loaExportMenuItem,
  msgExportMenuItem,
  SdExportMenuItem,
  SdImportMenuItem,
  msgImportMenuItem,
  msgCompleteAllItem,
} from '../../components/ApnExport';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { TableExportModal } from '../../components/TableExport';
import { Rest } from '../../api/Rest';
import { TableUploaderModal } from '../../components/TableUploader';
import { CompleteAllModal } from './CompleteAllModal/complete-all-modal';
//import { addNoItemsPermission } from '../../utils';

interface PropsShareDistributionTools {
    store: ShareDistributionPageStore;
    storeLOA: LOAExportStore;
}
type Mode = 'exportRfq' | 'importRfq' | 'exportLoa' | 'importSd' | 'exportSd';

const getExportKeyLoa = (): Promise<string> =>
  Rest.postExt('/api/v1/apn/Quotation/loa/export', { responseType: 'text' });

export const ShareDistributionTools: React.FC<PropsShareDistributionTools> = observer(
    ({ store, storeLOA }) => {
    const [mode, setMode] = React.useState<'' | Mode>('');
    const clear = () => setMode('');
    const [exportActive, setExportActive] = React.useState(false);
    const [importActive, setImportActive] = React.useState(false);
    const [showHiddenItemsNegotiator, setShowHiddenItemsNegotiator] = React.useState(false);
    const [confirmCompleteAll, setconfirmCompleteAll] = React.useState(false);
    const [msgHiddenItems, setMsgHiddenItems] = React.useState('Show Hidden Items');
    const msgImport = 'Import Share Distribution file';
    const msgExport = 'Export Share Distribution file';
    const msgCompleteAll = 'Share Distribution will be completed for all';
    const onShowHiddenItemsNegotiator = () => {
      if (showHiddenItemsNegotiator) {
        setShowHiddenItemsNegotiator(false);
        setMsgHiddenItems('Show Hidden Items');
        store.onLoadHiddenItemsNegotiator(false);
        reload();
      } else {
        setShowHiddenItemsNegotiator(true);
        setMsgHiddenItems('Hide Hidden Items');
        store.onLoadHiddenItemsNegotiator(true);
        reload();
      }
    };
    //const selectedCount = store.checkedRows.length;
    const reload = async () => {
      await store.table.reload();
    };
    const menuItems: ToolsMenuItem[] = [
      {
        text: msgImportMenuItem,
        permission: store.actions?.import,
        onClick: () => setMode('importRfq'),
      },
      {
        text: msgExportMenuItem,
        permission: store.actions?.export,
        onClick: () => setMode('exportRfq'),
      },

      {
          text: loaExportMenuItem,
          permission: store.actions?.exportloapdf,// to do change.
          onClick: () => storeLOA.startAddLine(),
      },
      {
        text: SdImportMenuItem,
        permission: store.actions?.edit,
        onClick: () => setImportActive(true),
      },
      {
        text: SdExportMenuItem,
        permission: store.actions?.export,
        onClick: () => setExportActive(true),
      },
      {
        text: msgCompleteAllItem,
        permission: store.actions?.completeall,
        onClick: () => setconfirmCompleteAll(true),
      },
      {
        text: msgHiddenItems,
        permission: store.actions?.showhiddenitems,
        onClick: () => {
          onShowHiddenItemsNegotiator();
        },
      },
    ];
    return (
      <>
        <ToolsMenuExt items={menuItems} />
        <ApnExport visible={mode === 'exportRfq'} onClose={clear} filter={store.table.filter} />
        <TableUploaderModal
          visible={mode === 'importRfq'}
          title={msgImportMenuItem}
          importType='RfqManufacturer'
          onClose={clear}
          store={store.table}
        />
        <TableExportModal
          visible={mode === 'exportLoa'}
          title={loaExportMenuItem}
          onClose={clear}
          getExportKey={getExportKeyLoa}
        />
        <TableUploaderModal
          visible={importActive}
          title={msgImport}
          importType='ShareDistribution'
          onClose={() => setImportActive(false)}
          store={store.table}
        />
        <TableExportModal
          visible={exportActive}
          title={msgExport}
          onClose={() => setExportActive(false)}
          getExportKey={store.getExportKey}
        />
        <CompleteAllModal
          visible={confirmCompleteAll}
          title={msgCompleteAll}
          onClose={() => {
            setconfirmCompleteAll(false);
            reload();
          }}
          store={store}
        />
      </>
    );
  }
);

import { EmsEditData, EmsRow, EmsSaveBody } from './EmsPage.types';
import moment from 'moment';

const saveDateFmt = 'D/M/YYYY';

export const prepareToEdit = (row: EmsRow): EmsEditData => ({
  code: row.code,
  name: row.name,
  contactName: row.contactName,
  address: row.address,
  bshCkaName: row.bshCkaName,
  phoneNumber: row.phoneNumber,
  emsOpened: row.emsOpened,
  projects: (row.projects || []).map(({ projectId }) => projectId),
  sapVendors: (row.sapVendors || []).map(({ sapVendorId }) => sapVendorId),
  email: (row.email || []).join('; '),
  endDate: row.endDate ? moment(row.endDate) : null,
});

export const filterToSave = (fields: EmsEditData, old: EmsEditData) => {
  const data = {} as Partial<EmsEditData>;
  if (fields.email !== old.email) {
    data.email = fields.email;
  }
  if (fields.address !== old.address) {
    data.address = fields.address;
  }
  if (fields.bshCkaName !== old.bshCkaName) {
    data.bshCkaName = fields.bshCkaName;
  }
  if (fields.phoneNumber !== old.phoneNumber) {
    data.phoneNumber = fields.phoneNumber;
  }
  if (fields.contactName !== old.contactName) {
    data.contactName = fields.contactName;
  }
  if (fields.endDate?.format() !== old.endDate?.format()) {
    data.endDate = fields.endDate;
  }
  if (JSON.stringify(fields.projects) !== JSON.stringify(old.projects)) {
    data.projects = fields.projects;
  }
  if (JSON.stringify(fields.sapVendors) !== JSON.stringify(old.sapVendors)) {
    data.sapVendors = fields.sapVendors;
  }
  return data;
};

export const transformToSave = (fields: Partial<EmsEditData>) => {
  const res: EmsSaveBody = {};
  if ('email' in fields) {
    res.email = fields.email ? fields.email.split(';').map(s => s.trim()) : null;
  }
  if ('endDate' in fields) {
    res.endDate = fields.endDate?.format(saveDateFmt) || null;
  }
  if ('projects' in fields) {
    res.projects = fields.projects || null;
  }
  if ('sapVendors' in fields) {
    res.sapVendors = fields.sapVendors || null;
  }
  if ('address' in fields) {
    res.address = fields.address || null;
  }
  if ('bshCkaName' in fields) {
    res.bshCkaName = fields.bshCkaName || null;
  }
  if ('contactName' in fields) {
    res.contactName = fields.contactName || null;
  }
  if ('phoneNumber' in fields) {
    res.phoneNumber = fields.phoneNumber || null;
  }
  return res;
};

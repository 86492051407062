import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import styles from './spinner.module.less';

export const Spinner: FunctionComponent<{ fillParent?: boolean }> = ({ fillParent = false }) => {
  const spinner = <Spin tip='Loading' />;

  if (!fillParent) return spinner;

  return (
    <div className={styles.wrapper}>
      <div className={styles.loading}>{spinner}</div>
    </div>
  );
};

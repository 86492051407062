import { LoaderStore } from '../../common/LoaderStore';
import { ManufInfo } from './OfferPriceExport.types';
import { action, makeObservable, observable } from 'mobx';
import { makeStrParams, Rest } from '../../api/Rest';
import { ApnFields, getFilterFields } from '../ApnFilter';
import { transformComponents } from '../../common/apn/transformComponents';
import { icmp } from '../../common/icmp';

const buildParams = (filter: ApnFields) => {
  const pureFilter: ApnFields = getFilterFields().reduce((acc, key) => {
    if (key in filter) {
      // @ts-ignore
      acc[key] = filter[key];
    }
    return acc;
  }, {} as ApnFields);
  return makeStrParams(transformComponents(pureFilter));
};

export class OfferPriceExportStore extends LoaderStore {
  filter: ApnFields;
  manufList: ManufInfo[] = [];
  constructor(filter: ApnFields) {
    super();
    this.filter = filter;
    makeObservable(this, {
      manufList: observable,
      init: action,
      onSuccess: action,
    });
  }
  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      Rest.get<{ data: ManufInfo[] }>('/api/v1/Manufacturer/offerprice', buildParams(this.filter))
        .then(response => this.onSuccess(response.data))
        .catch(e => this.onError(e));
    }
  }
  onSuccess(list: ManufInfo[]) {
    list.sort((a, b) => icmp(a.name, b.name));
    this.manufList = list;
    this.onReady();
  }
}

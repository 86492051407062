import { makeObservable, action, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { TableStore, FnDataLoad } from '../../common/TableStore';
import { ComponentRow, ComponentFilter, ComponentActionsMap } from './ComponentPage.types';
import { getRows } from '../../api/getRows';
import { Rest } from '../../api/Rest';
import { FormStore, FnFormSave } from '../../common/FormStore';
import { ActionPermission } from '../../api/types';
import { updatePageActions } from '../../common/updatePageActions';

const loadComponents: FnDataLoad<ComponentRow, ComponentFilter> = (params: ComponentFilter) =>
  getRows('/api/v1/Part', params);

const saveForm: FnFormSave<ComponentRow> = (oldData, newData) => {
  return Rest.patch(`/api/v1/Part/${oldData.bshPartNo}`, newData);
};

export class ComponentTableStore extends TableStore<ComponentRow, ComponentFilter> {
  form: FormStore<ComponentRow>;
  currentIndex: number = 0;

  constructor() {
    super(
      {
        page: 0,
        pageSize: 10,
      },
      loadComponents
    );
    const onRowChanged = () => {
      this.modifyRow(this.currentIndex, this.form.data);
    };
    this.form = new FormStore<ComponentRow>(saveForm, onRowChanged);
    makeObservable(this, {
      onEditStart: action,
    });
  }
  onEditStart(rowIndex: number) {
    this.currentIndex = rowIndex;
    this.form.activate(this.data[rowIndex]);
  }
}

export class ComponentPageStore extends LoaderStore {
  table: ComponentTableStore;
  actions?: ComponentActionsMap;

  constructor() {
    super();
    this.table = new ComponentTableStore();
    makeObservable(this, {
      actions: observable,
      setActions: action,
    });
  }

  get editPermission(): ActionPermission | undefined {
    return this.actions?.edit;
  }
  setActions(actions: ComponentActionsMap) {
    this.actions = actions;
  }

  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      this.update()
        .then(() => this.onReady())
        .catch(e => this.onError(e));
    } else {
      this.update();
    }
  }
  private update = (): Promise<unknown> =>
    Promise.all([updatePageActions('Part', this), this.table.reload()]);

  getExportKey = async (): Promise<string> => {
    const res: { key: string } = await Rest.post('/api/v1/Part/export');
    return res.key;
  };
}

// Internet Explorer 11 support
// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';

// Todo: please remove core-js package after
// https://github.com/facebook/create-react-app/issues/9906 will be resolved.
// This is workaround from
// https://github.com/facebook/create-react-app/issues/9906#issuecomment-717112190
// to make the application work in IE 11
import 'core-js/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';

import { Provider } from 'react-redux';
import { store } from './store';
import { BootstrapApp } from './components/bootstrap-app/bootstrap-app';
import { MainFrame } from './MainFrame';

const render = () => {
  const Routes = require('./routes/routes').default;

  ReactDOM.render(
    <Provider store={store}>
      <BootstrapApp>
        <MainFrame>
          <Routes />
        </MainFrame>
      </BootstrapApp>
    </Provider>,
    document.getElementById('root')
  );
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./routes/routes', render);
}

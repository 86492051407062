import { Select } from 'antd';
import * as React from 'react';
import { EmsRow } from '../../pages/EmsPage/EmsPage.types';
import { Rest } from '../../api/Rest';
import { drawErrorMessage } from '../../common/drawErrorMessage';

const { Option } = Select;

interface EmsSelectProps {
  val?: string;
  onChange?: (emsCode: string) => void;
}

const sortEms = (options: EmsRow[]): EmsRow[] => {
  options.sort((rowA, rowB) => rowA.name.localeCompare(rowB.name));
  return options;
};

export const EmsSelect: React.FC<EmsSelectProps> = ({ val, onChange }) => {
  const [emsOptions, setEmsOptions] = React.useState<EmsRow[] | null>(null);
  const loading = !emsOptions;
  React.useEffect(() => {
    Rest.get<{ data: EmsRow[] }>('/api/v1/Ems/filters')
      .then(res => setEmsOptions(sortEms(res.data)))
      .catch(e => drawErrorMessage(e));
  }, []);

  return (
    <Select
      showSearch
      allowClear
      placeholder='Start typing to find EMS'
      loading={loading}
      onChange={onChange}
      defaultValue={val}
      filterOption={(input, option) => option?.children.toLowerCase().includes(input.toLowerCase())}
    >
      {emsOptions?.map(ems => (
        <Option value={ems['code']} key={ems.code}>
          {ems.name}
        </Option>
      ))}
    </Select>
  );
};

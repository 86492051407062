import * as React from 'react';
import { Input, Form } from 'antd';

export const BshPartNoInput: React.FC = () => {
  return (
    <Form.Item name='BshPartNo' label='BSH Part No'>
      <Input allowClear />
    </Form.Item>
  );
};

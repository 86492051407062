import * as React from 'react';
import { Form, Select } from 'antd';
import { getBundlings } from '../../../api/cached/getBundlings';

interface BundleItem {
  bundlingId: number;
  name: string;
}

export const BundlingSelectAddComponent: React.FC = () => {
  const [options, setOptions] = React.useState<BundleItem[] | null>(null);
  React.useEffect(() => {
    getBundlings().then(setOptions);
  }, []);
  return (
    <Form.Item initialValue={0} label='Bundling' name='BundlingId'>
      <Select allowClear loading={options === null}>
        {options &&
          options.map(rec => (
            <Select.Option key={rec.bundlingId} value={rec.bundlingId}>
              {rec.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

import { combineReducers } from '@reduxjs/toolkit';

import sessionReducer from './features/session/session-slice';
import demandListReducer from './features/demand-list/demand-list-slice';
import quotaReducer from './features/quota/quota-slice';
import demandFilterReducer from './features/demand-filter/demand-filter-slice';

const rootReducer = combineReducers({
  session: sessionReducer,
  demandList: demandListReducer,
  quota: quotaReducer,
  demandFilter: demandFilterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { LoaderStore } from '../../common/LoaderStore';
import { EmsEditData, EmsFilter, EmsPageActionsMap, EmsRow, EmsSetEndDate } from './EmsPage.types';
import { action, makeObservable, observable } from 'mobx';
import { FnDataLoad, RemoteResponse, TableStore } from '../../common/TableStore';
import { makeStrParams, Rest } from '../../api/Rest';
import { updatePageActions } from '../../common/updatePageActions';
import { FnFormSave, FormStore } from '../../common/FormStore';
import { transformToSave } from './EmsPage.utils';
import { drawErrorExt, drawErrorMessage } from '../../common/drawErrorMessage';

const loadEms: FnDataLoad<EmsRow, EmsFilter> = filter =>
  Rest.get<{ data: EmsRow[] }>('/api/v1/Ems', makeStrParams(filter)).then(response => ({
    data: response.data,
    total: response.data.length,
  }));

const saveEms: FnFormSave<EmsEditData> = (oldData, newData) => {
  const url = `/api/v1/Ems/${oldData.code}`;
  return Rest.patch(url, transformToSave(newData));
};

export const openDemand = (oldData: EmsEditData, newData: Partial<EmsEditData>): Promise<void> => {
  const url = `/api/v1/Ems/${oldData.code}/open-demand`;
  return Rest.patch(url, transformToSave(newData));
};

export const changeDate = (oldData: EmsEditData, newData: Partial<EmsEditData>): Promise<void> => {
    const url = `/api/v1/Ems/${oldData.code}/change-date`;
    return Rest.patch(url, transformToSave(newData));
};

class EmsTableStore extends TableStore<EmsRow, EmsFilter> {
  checkedRows: EmsRow[] = [];

  constructor() {
    super({ page: 0, pageSize: 0 }, loadEms);
    makeObservable(this, {
      checkedRows: observable,
      setCheckedRows: action,
    });
  }

  setCheckedRows(newRows: EmsRow[]) {
    this.checkedRows = newRows;
  }

  onData(response: RemoteResponse<EmsRow>, newFilter: EmsFilter) {
    super.onData(response, newFilter);
    this.checkedRows = [];
  }

  updateRow(code: string) {
    this.lock();
    Rest.get<EmsRow>(`/api/v1/Ems/${code}`)
      .then(res => this.modifyRowExt('code', res))
      .catch(e => drawErrorMessage(e))
      .finally(() => this.unlock());
  }

  bulkOp<TBody>(url: string, body: TBody): Promise<void> {
    this.lock();
    return Rest.post(url, body)
      .then(() => {
        this.setCheckedRows([]);
      })
      .then(() => this.reload())
      .catch(e => drawErrorExt<void>(e))
      .finally(() => this.unlock());
  }

  openDemands() {
    this.bulkOp(`/api/v1/Ems/bulk/open-demand`, {
      ems: this.checkedRows.map(row => row.code),
    });
  }

  setEndDates(data: EmsSetEndDate) {
    return this.bulkOp(`/api/v1/Ems/bulk/enddate`, {
      endDate: data.endDate!.format('D/M/YYYY'),
      ems: this.checkedRows.map(row => row.code),
    });
  }
}

export class EmsPageStore extends LoaderStore {
  actions: EmsPageActionsMap = {};
  table: EmsTableStore;
  edit: FormStore<EmsEditData>;
  setEndDate: FormStore<EmsSetEndDate>;

  constructor() {
    super();
    this.table = new EmsTableStore();
    this.edit = new FormStore<EmsEditData>(saveEms, () =>
      this.table.updateRow(this.edit.data.code)
    );
    this.setEndDate = new FormStore<EmsSetEndDate>((oldData, newData) =>
      this.table.setEndDates(newData)
    );
    makeObservable(this, {
      actions: observable,
      setActions: action,
    });
  }

  init() {
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([updatePageActions('Ems', this)])
        .then(() => {
          this.onReady();
          this.table.init();
        })
        .catch(e => this.onError(e));
    } else {
      updatePageActions('Ems', this);
      this.table.reload();
    }
  }

  setActions(actions: EmsPageActionsMap) {
    this.actions = actions;
  }
}

interface FieldData {
  name: string;
  value?: any;
}

/**
 * Helper for 'fields' property of Form
 * @param values
 */
export const expandFormFields = (values?: object): FieldData[] =>
  Object.entries(values || {}).map(([name, value]) => ({ name, value }));

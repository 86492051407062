import * as React from 'react';
import { observer } from 'mobx-react';
import { ManufInfo } from './OfferPriceExport.types';
import { Button, Tooltip } from 'antd';
import { ExportStatus, TableExportStore } from '../TableExport/TableExportStore';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { formatErrorMessage } from '../../api/utils/error-message-formatter';
import './OfferPriceExport.less';
import { Rest } from '../../api/Rest';

interface PropsApnExportManufacturer {
  info: ManufInfo;
  onClose(): void;
}

const msgMap: Record<ExportStatus, string> = {
  none: 'Offer Price Export',
  wait: 'Prepare',
  ready: 'Download',
  error: 'Repeat',
};

export const OfferPriceExportManufacturer: React.FC<PropsApnExportManufacturer> = observer(
  ({ info, onClose }) => {
    const getExportKey = () =>
      Rest.postExt<string, void>(`/api/v1/Batch/offerprice/export/${info.code}`, {
        responseType: 'text',
      });

    const [store] = React.useState(new TableExportStore(getExportKey, onClose));
    const { status } = store;
    const onStart = () => {
      if (status !== 'wait') store.start().catch(e => drawErrorMessage(e));
    };
    const onDownload = () => {
      store.setStatus('none');
    };
    const text = msgMap[status];
    return (
      <div className='apn-export-manuf-item'>
        <div className='apn-export-manuf-name'>{info.name}</div>
        {status !== 'ready' && (
          <Tooltip
            title={store.lastError ? formatErrorMessage(store.lastError) : ''}
            placement='left'
          >
            <Button
              type='primary'
              loading={status === 'wait'}
              danger={status === 'error'}
              onClick={onStart}
            >
              {text}
            </Button>
          </Tooltip>
        )}
        {status === 'ready' && (
          <a href={store.url} target='_blank' rel='noreferrer' onClick={onDownload}>
            {text}
          </a>
        )}
      </div>
    );
  }
);

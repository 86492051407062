import axios from 'axios';
import { auth } from './auth-service';

export interface ResponseError {
  message: string;
  response: {
    data: {
      reason: { [key: string]: string[] };
      status: number;
      title: string;
      traceId: string;
      type: string;
    };
  };
}

// AWS amplify uses axios under the hood.
// Here we add interceptor for all AWS Amplify requests.
// https://github.com/axios/axios#interceptors

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = auth.getToken();
    return config;
  },
  function (error) {
    // Do something with request error
    console.error('Send request error:', error);

    return Promise.reject(error);
  }
);

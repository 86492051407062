import { ApnFilterFields } from '../../components/ApnFilter';
import { UserActions } from '../../store/utils/state-types';
import { ActionPermission } from '../../api/types';
import { BaseFilter } from '../../common/TableStore';

export type SDPageAction =
  | 'edit'
  | 'export'
  | 'import'
    | 'exportloa'
    | 'exportloapdf'
  | 'completeall'
  | 'showhiddenitems';
export type SDPageActionsMap = Record<SDPageAction, ActionPermission>;

export interface SDRow {
  demandId: number;
  bshPartNo: string | null;
  designation: string | null;
  demand: number;
  bundlingId: number | null;
  bundlingName: string | null;
  emsCode: string | null;
  emsName: string | null;
  emsPartNo: string | null;
  commodityCode: string | null;
  commodityGpmt: string | null;
  commodityName: string | null;
  prevDemand: number;
  negotiatorNick: string | null;
  actions: UserActions;
  isCompleted: boolean;
  LogonDistri: string;
  DistriPartNo: string;
}

export interface SdRightFilter {
  EmsCode: string;
  ManufacturerCode: string;
  WithoutDemand: boolean;
  NewParts: boolean;
  BshPartNo: string;
  EmsPartNo: string;
  OrderCode: string;
  BundlingId: number;
  NegotiatorId: number;
  Designation: string;
  CommodityCode: string;
  CommodityName: string;
  CommodityGpmt: string;
  ProjectId: number;
  QuotaIsZero: boolean;
  QuotaBetweenZeroand100: boolean;
  SumOfQuotaGreaterThan100: boolean;
  PriceIsZeroAndQuotaGreaterThanZero: boolean;
  PriceIsZeroAndQuotaIsZero: boolean;
  ShowHiddenItemsNegotiator: boolean;
  LogonDistri: string;
  DistriPartNo: string;
}

export type SdPageAction =
  | 'addcomponent'
  | 'bulklock'
  | 'export'
  | 'fieldbundling'
  | 'fieldnegotiator'
  | 'import'
  | 'showhiddenitems';
export type SdPageActionsMap = Record<SdPageAction, ActionPermission>;

export interface SDFilter
  extends BaseFilter<SDRow>,
    Partial<SdRightFilter>,
    ApnFilterFields<SDRow> {}

const defaultFilter: SdRightFilter = {
  EmsCode: '',
  ManufacturerCode: '',
  WithoutDemand: false,
  NewParts: false,
  BshPartNo: '',
  EmsPartNo: '',
  BundlingId: 0,
  NegotiatorId: 0,
  OrderCode: '',
  Designation: '',
  CommodityCode: '',
  CommodityName: '',
  CommodityGpmt: '',
  ProjectId: 0,
  QuotaIsZero: false,
  QuotaBetweenZeroand100: false,
  SumOfQuotaGreaterThan100: false,
  PriceIsZeroAndQuotaGreaterThanZero: false,
  PriceIsZeroAndQuotaIsZero: false,
  ShowHiddenItemsNegotiator: false,
  LogonDistri: '',
  DistriPartNo: '',
};

export const sdRightFields = Object.keys(defaultFilter) as (keyof SDFilter)[];

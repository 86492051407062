import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { SD2TableStore } from './SD2TableStore';
import { SD2CellParams, SD2Row } from './SD2Table.types';
import {
  floatInputFormatter,
  integerInputFormatter,
  numberFormatter,
  priceFormatter,
} from '../../../utils';
import { editableCellHandler, editableComponents } from '../../../components/EditableTable';
import {
  NestedInput,
  NestedInputNumber,
  NestedSelect,
} from '../../../components/EditableTable/nested';
import { LabelValue } from '../../../common/types';
import {
  apnRowClassName,
  formatConvertedPriceEuro,
  getUpdateCurrencyHandler,
} from '../../../common/apn';
import { ApnCommentIcons } from '../../../components/ApnCommentIcons';
import { ColumnMYA } from '../../../components/ColumnMYA';
import { QuotaIncreasedPrices } from '../../../components/QuotaIncreasedPrice';
import { PreviousSessions } from '../../../components/PreviousSessions';
import { PreviousYearInfo } from '../../../components/PreviousYearInfo';
import { setBundlingId } from '../../../api/cached/getBundlings';
import { SharedDistributionActions } from '../SharedDistributionActions';
import { ShareDistributionEdit } from '../ShareDistributionEdit';
import { Rule } from 'antd/es/form';
import { requiredRule } from '../../../common/constants';
import moment from 'moment/moment';

interface PropsSD2Table {
  store?: SD2TableStore;
  currencyList: LabelValue[];
  bundlingOptions: LabelValue[];
  canEdit: boolean;
}

export const SD2Table: React.FC<PropsSD2Table> = observer(
  ({ store, currencyList, bundlingOptions, canEdit }) => {
    if (!store) return null;

    const updateCurrency = getUpdateCurrencyHandler(currencyList);
    const commentRules: Rule[] = [
      requiredRule,
      { type: 'string', max: 200, message: 'Comment exceed the max length.' },
      {
        type: 'string',
        max: 200,
        message: `Max characters is ${numberFormatter(200)}`,
      },
    ];
    const localCellHandler = (params: SD2CellParams) =>
      editableCellHandler<SD2Row>({
        store,
        keyField: 'quotaId',
        isEditable: () => canEdit,
        ...params,
      });

    return (
      <>
        <Table<SD2Row>
          size='small'
          rowKey='quotaId'
          dataSource={store.data}
          loading={store.isLoading}
          pagination={store.paginator}
          components={editableComponents}
          onChange={store.onTableChange}
          rowClassName={apnRowClassName}
        >
          <Table.Column
            title='Manufacturer'
            key='manufacturerName'
            dataIndex='manufacturerName'
            width='12%'
          />
          <Table.Column title='Order code' key='orderCode' dataIndex='orderCode' width='12%' />
          <Table.Column
            title='Distri Part No'
            key='distriPartNo'
            dataIndex='distriPartNo'
            width='13%'
            ellipsis
          />
          <Table.Column
            title='Release status'
            key='releaseStatus'
            width='6%'
            render={({ orderCodeStatusName, updated_on }) => (
              <span style={{ whiteSpace: 'nowrap' }}>
                {orderCodeStatusName ? orderCodeStatusName : ''}{' '}
                {updated_on && orderCodeStatusName ? <br></br> : ''}{' '}
                {updated_on ? moment(updated_on).format('DD.MM.YYYY') : ''}
              </span>
            )}
          />
          <Table.Column title='MYA' key='MYA' width='4%' render={row => <ColumnMYA row={row} />} />
          <Table.Column
            title='Current Unit Price'
            key='price'
            width='8%'
            render={(rec: SD2Row) => priceFormatter(rec.price)}
            onCell={localCellHandler({
              field: 'price',
              component: NestedInputNumber,
              extraProps: {
                min: 0,
                formatter: floatInputFormatter,
              },
            })}
          />
          <Table.Column
            title='Current currency'
            key='currency'
            width='6%'
            render={(rec: SD2Row) => rec.currencyCode}
            onCell={localCellHandler({
              field: 'currencyCode',
              component: NestedSelect,
              extraProps: {
                options: currencyList,
              },
              preSave: updateCurrency,
            })}
          />
          <Table.Column
            title='EUR'
            key='convertedPriceEuro'
            dataIndex='convertedPriceEuro'
            width='4%'
            render={value => formatConvertedPriceEuro(value)}
          />
          <Table.Column
            title='L'
            key='quotaIncreasedPrices'
            width='3%'
            align='center'
            render={({ quotaId }) => <QuotaIncreasedPrices quotaId={quotaId} />}
          />
          <Table.Column
            title='Price last year'
            render={({ previousPrice, previousCurrencyCode }) =>
              `${priceFormatter(previousPrice)} ${previousCurrencyCode || ''}`
            }
            width='6%'
          />
          <Table.Column
            title='Current quota'
            key='quota'
            dataIndex='quota'
            width='6%'
            render={value => (typeof value === 'number' ? `${value} %` : '')}
            onCell={localCellHandler({
              field: 'quota',
              component: NestedInputNumber,
              preSave: src => {
                const dst = { ...src };
                if (!dst.quota && dst.quota !== 0) dst.quota = null;
                return dst;
              },
              extraProps: {
                min: 0,
                max: 100,
                formatter: integerInputFormatter,
              },
            })}
          />
          <Table.Column
            title='Quota last year'
            key='previousQuota'
            dataIndex='previousQuota'
            width='5%'
            render={value => (value ? `${value} %` : '')}
          />
          <Table.Column
            title='Comment'
            key='comment'
            dataIndex='negotiatorComment'
            width='7%'
            onCell={localCellHandler({
              field: 'negotiatorComment',
              component: NestedInput,
              rules: commentRules,
            })}
          />
          <Table.Column
            title=''
            key='commentsExt'
            width='3%'
            render={row => <ApnCommentIcons row={row} />}
          />
          <Table.Column
            title='Previous Sessions'
            key='previousSessions'
            width='6%'
            align='center'
            render={({ quotaId }) => <PreviousSessions quotaId={quotaId} />}
          />
          <Table.Column
            title='Additional info'
            width='5%'
            render={row => <PreviousYearInfo data={row} year={store?.currentYear} />}
          />
          <Table.Column
            title='Actions'
            width='9%'
            align='left'
            render={row => <SharedDistributionActions row={row} store={store} />}
          />
        </Table>
        <ShareDistributionEdit formStore={store.editForm} />
      </>
    );
  }
);

import * as React from 'react';
import { DQRow } from './ComponentPriceListQuota.types';
import { ComponentPriceListQuotaStore } from './ComponentPriceListQuotaStore';
import { Button, Modal } from 'antd';
import { PermissionCheck } from '../../../components/PermissionCheck';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../store/features/session/session-slice';

interface PropsDemandQuotaActions {
  row: DQRow;
  store: ComponentPriceListQuotaStore;
}

export const ComponentPriceListQuotaActions: React.FC<PropsDemandQuotaActions> = ({
  row,
  store,
}) => {
  const { groups } = useSelector(selectUserInfo);
  const isCombuyer = groups.includes('COMBYR');
  const onDelete = () => {
    const { destroy } = Modal.confirm({
      title: `Component will be deleted!`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: async () => {
        destroy();
        store.deleteRow(row);
      },
    });
  };
  if (row.priceType !== null && row.priceType === 'Offer') {
    return (
      <>
        <PermissionCheck
          permission={row.actions.edit}
          type='link'
          onClick={() => store.editForm.activate(row)}
        >
          <Button disabled={isCombuyer}>edit</Button>
        </PermissionCheck>
        {/*TODO:should change perm to delete*/}
        <PermissionCheck permission={row.actions.edit} type='link' onClick={onDelete}>
          <Button>delete</Button>
        </PermissionCheck>
      </>
    );
  } else if (row.priceType !== null && (row.priceType === 'EMS' || row.priceType === 'Estimate')) {
    return (
      <>
        {/*TODO:should change perm to delete*/}
        <PermissionCheck permission={row.actions.edit} type='link' onClick={onDelete}>
          <Button disabled={isCombuyer}>delete</Button>
        </PermissionCheck>
      </>
    );
  } else {
    return null;
  }
};

import * as React from 'react';
import { Rule } from 'antd/lib/form';
import { PropsNested } from './nested';

export interface ParamsCellHandler<TRow> {
  store: { save(data: Partial<TRow>): Promise<void> };
  field: keyof TRow;
  keyField: keyof TRow;
  component: React.FC<PropsNested>;
  extraProps?: Object;
  rules?: Rule[];
  preSave?: (src: Partial<TRow>) => Partial<TRow>;
  isEditable?: (rec: TRow) => boolean;
}

export const editableCellHandler = <TRow>(params: ParamsCellHandler<TRow>) => {
  const onSave = (rec: Partial<TRow>) => {
    const { preSave } = params;
    return params.store.save(preSave ? preSave(rec) : rec);
  };

  return (rec: TRow) => {
    const isEditable = params.isEditable && params.isEditable(rec);

    return {
      record: rec,
      title: '',
      dataIndex: params.field,
      keyIndex: params.keyField,
      NestedComponent: isEditable ? params.component : null,
      extraProps: params.extraProps,
      rules: params.rules,
      handleSave: onSave,
    };
  };
};

import * as React from 'react';
import { Select } from 'antd';
import { CommodityRow } from '../CommodityPage/CommodityPage.types';
import { ComponentRow } from './ComponentPage.types';
import { getRows } from '../../api/getRows';

interface PropsSelectCommodity {
  initialValue?: ComponentRow;
  disabled: boolean;
  onChange(fields?: Partial<ComponentRow>): void;
}

export const SelectCommodity: React.FC<PropsSelectCommodity> = ({
  initialValue,
  disabled,
  onChange,
}) => {
  const [value, setValue] = React.useState('');
  const [options, setOptions] = React.useState<CommodityRow[]>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [loadCounter, setLoadCounter] = React.useState(0);
  const changeLoadCounter = (step: number) => setLoadCounter(prevCounter => prevCounter + step);

  React.useEffect(() => {
    setValue(initialValue?.commodityCode || '');
    if (initialValue && initialValue.commodityId) {
      // This is an attempt to populate the list with a single record without using a query.
      setOptions([
        {
          commodityId: initialValue.commodityId,
          code: initialValue.commodityCode,
          nameEng: initialValue.commodityNameEng,
          nameGer: '',
          negotiatorNickId: null,
          negotiatorNick: null,
          gpmt: null,
        },
      ]);
      setSearchValue('');
    } else {
      setOptions([]);
    }
  }, [initialValue]);

  const onInternalChange = (newValue: string) => {
    setValue(newValue);
    setSearchValue('');
    const res = options.find(({ code }) => code === newValue);
    if (!res && newValue) {
      // special case, when search string not found
      onChange();
      return;
    }
    const newFields = res
      ? {
          commodityId: res.commodityId,
          commodityCode: res.code,
          commodityNameEng: res.nameEng,
        }
      : { commodityId: null, commodityCode: null, commodityNameEng: null };
    onChange(newFields);
  };
  const onSearch = (valuePart: string) => {
    if (valuePart) {
      if (valuePart.length > 5) {
        valuePart = valuePart.slice(0, 5);
        if (valuePart === searchValue) {
          setSearchValue(valuePart);
          return;
        }
      }
      const params = {
        page: 0,
        pageSize: 10,
        CommodityCode: valuePart,
      };
      setSearchValue(valuePart);
      changeLoadCounter(1);
      getRows<CommodityRow, typeof params>('/api/v1/Commodity', params)
        .then(response => {
          setValue(valuePart);
          setOptions(response.data);
        })
        .finally(() => changeLoadCounter(-1));
    } else {
      setOptions([]);
      onChange();
      setSearchValue(valuePart);
    }
  };
  const msgNotFound = value ? 'Not found' : 'Type part of commodity code';
  return (
    <Select
      allowClear
      showSearch
      disabled={disabled}
      loading={loadCounter > 0}
      placeholder='Commodity code'
      value={value}
      onChange={onInternalChange}
      onSearch={onSearch}
      searchValue={searchValue}
      filterOption={false}
      notFoundContent={<div>{msgNotFound}</div>}
    >
      {options.map(opt => (
        <Select.Option value={opt.code || ''} key={opt.commodityId}>
          {opt.code}: {opt.nameEng}
        </Select.Option>
      ))}
    </Select>
  );
};

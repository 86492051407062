import React, { FunctionComponent } from 'react';
import { Button, Col, Layout, Row } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import './Forbidden.less';

const { Content } = Layout;

export const Forbidden: FunctionComponent = () => {
  return (
    <Layout>
      <Content className='forbidden'>
        <Row justify='end' align='middle'>
          <Col span={8}>
            <Button onClick={() => Auth.signOut()} type='default' icon={<LogoutOutlined />}>
              Log Out
            </Button>
          </Col>
        </Row>
        <Row justify='center' align='middle'>
          <Col span={8}>
            <h1 className='title'>403</h1>
            <h2 className='message'>Access Denied</h2>
            <p>You don't have permission to access requested page</p>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

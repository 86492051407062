import * as React from 'react';
import { Button, Space } from 'antd';
import { observer } from 'mobx-react';
import { CampaignRow } from '../CampaignPage.types';
import { PermissionCheck } from '../../../components/PermissionCheck/PermissionCheck';
import { CampaignPageStore } from '../CampaignPageStore';

interface PropsCampaignActionsView {
  row: CampaignRow;
  store: CampaignPageStore;
}

export const CampaignActionsView: React.FC<PropsCampaignActionsView> = observer(
  ({ row, store }) => {
    const { table } = store;
    const onApn = () => store.startAction(row, 'apn');
    const onOpen = () => store.startAction(row, 'open');
    return (
      <Space>
        <PermissionCheck
          permission={row.actions.apn}
          type='link'
          loading={table.activeAction === 'apn'}
          onClick={onApn}
        >
          <Button>RFQ</Button>
        </PermissionCheck>

        <PermissionCheck
          permission={row.actions.open}
          type='link'
          onClick={onOpen}
          loading={table.activeAction === 'open'}
        >
          <Button>New session</Button>
        </PermissionCheck>
      </Space>
    );
  }
);

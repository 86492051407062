import * as React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Collapse, Form, Input, Space } from 'antd';
import { DemandRightFilter } from './DemandPage.types';
import { EmsSelect } from '../../components/ems-select/ems-select';
import { ManufacturerSelect } from '../../components/ManufacturerSelect';
import { DemandPageStore } from './DemandPageStore';
import { ProjectSelectSingle } from '../../components/ProjectSelectSingle/ProjectSelectSingle';
const { Panel } = Collapse;

interface PropsDemandFilterForm {
  store: DemandPageStore;
}

export const DemandFilterForm: React.FC<PropsDemandFilterForm> = observer(({ store }) => {
  const [form] = Form.useForm<DemandRightFilter>();
  const onSubmit = (values: DemandRightFilter) => {
    console.log('DemandFilterForm>Submit', values);
    store.table.load({ page: 0, ...values });
  };
  const onClear = () => {
    form.resetFields();
    const values = form.getFieldsValue();
    onSubmit(values);
  };
  return (
    <Form<DemandRightFilter>
      layout='vertical'
      form={form}
      onFinish={onSubmit}
      className='demand-filter-panel'
    >
      <Collapse defaultActiveKey={['suppliers']}>
        <Panel header='Suppliers' key='suppliers'>
          <Form.Item label='EMS' name='EmsCode'>
            <EmsSelect />
          </Form.Item>
          <ManufacturerSelect name='ManufacturerCode' label='Manufacturer' />
          <ProjectSelectSingle />
        </Panel>
        <Panel header='Parts' key='parts'>
          <Form.Item name='WithoutDemand' valuePropName='checked'>
            <Checkbox>Without Demand</Checkbox>
          </Form.Item>

          <Form.Item name='NewParts' valuePropName='checked'>
            <Checkbox>New Parts</Checkbox>
          </Form.Item>

          <Form.Item label='BSH Part No' name='BshPartNo'>
            <Input placeholder='BSH Part No' allowClear />
          </Form.Item>

          <Form.Item label='EMS Part No' name='EmsPartNo'>
            <Input placeholder='EMS Part No' allowClear />
          </Form.Item>

          <Form.Item label='Order Code' name='OrderCode'>
            <Input placeholder='Order Code' allowClear />
          </Form.Item>

          <Form.Item label='Designation' name='Designation'>
            <Input placeholder='Designation' allowClear />
          </Form.Item>
        </Panel>
        <Panel header='Commodity' key='commodity'>
          <Form.Item label='Commodity Code (e.g. P1111)' name='CommodityCode'>
            <Input placeholder='Commodity Code' allowClear maxLength={5} />
          </Form.Item>

          <Form.Item label='Commodity Name (e.g. Driver IC)' name='CommodityName'>
            <Input placeholder='Commodity Name' allowClear />
          </Form.Item>
          <Form.Item label='GPMT (e.g. ANA)' name='CommodityGpmt'>
            <Input placeholder='GPMT' allowClear />
          </Form.Item>
        </Panel>
      </Collapse>
      <Form.Item>
        <Space>
          <Button type='primary' htmlType='submit' loading={store.table.isLoading}>
            Apply
          </Button>
          <Button onClick={onClear}>Clear filters</Button>
        </Space>
      </Form.Item>
    </Form>
  );
});

import { makeAutoObservable } from 'mobx';

export class DefaultCurrencyStore {
  defaultCurrency = 'EUR';

  constructor() {
    makeAutoObservable(this);
  }

  setDefaultCurrency(currency: string) {
    this.defaultCurrency = currency;
  }
}

export const defaultCurrencyStore = new DefaultCurrencyStore();

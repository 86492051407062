import { FnDataLoad, TableStore } from '../../../common/TableStore';
import { DQFilter, DQRow } from './MatrixBasisQuota.types';
import { Rest } from '../../../api/Rest';
import { FnFormSave, FormStore } from '../../../common/FormStore';
import { drawErrorMessage } from '../../../common/drawErrorMessage';

const loadDQ = (
  demandId: number,
  priceType: string,
  filterCurrencyRate: number
): FnDataLoad<DQRow, DQFilter> => {
  interface Response {
    data: DQRow[];
  }
  return params =>
    Rest.get<Response>(
      `/api/v1/demand/${demandId}/matrixbasis/${priceType}/Quota/${filterCurrencyRate}`
    ).then(response => ({
      data: response.data,
      total: response.data.length,
    }));
};

const saveDQ: FnFormSave<DQRow> = (oldData, newData) =>
  Rest.patch(`/api/v1/Batch/cpl/quota/edit/${oldData.quotaId}`, newData);

export class MatrixBasisQuotaStore extends TableStore<DQRow, DQFilter> {
  demandId: number;
  filterCurrencyRate: number;
  isLocked: boolean;
  editForm: FormStore<DQRow>;
  constructor(demandId: number, isLocked: boolean, priceType: string, filterCurrencyRate: number) {
    super({ page: 0, pageSize: 0 }, loadDQ(demandId, priceType, filterCurrencyRate));
    this.demandId = demandId;
    this.isLocked = isLocked;
    this.filterCurrencyRate = filterCurrencyRate;
    const onEditOk = () => {
      this.reload();
    };
    this.editForm = new FormStore<DQRow>(saveDQ, onEditOk);
  }
  // override to prevent scroll
  init() {
    this.reload({ notScrollTop: true });
  }
  deleteRow(row: DQRow) {
    this.lock();
    Rest.del(`/api/v1/Batch/cpl/quota/${row.quotaId}`)
      .catch(e => drawErrorMessage(e, "Can't delete this record"))
      .then(() => this.reload())
      .finally(() => this.unlock());
  }
}

import * as React from 'react';
import { observer } from 'mobx-react';
import {
  ComponentPriceListEditData,
  ComponentPriceListRightFilter,
} from './ComponentPriceListPage.types';
import { ModalForm } from '../../components/ModalForm';
import { Form, Input, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { formLayout, integerOnlyRule, priceRule, requiredRule } from '../../common/constants';
import { EmsSelect } from '../../components/ems-select/ems-select';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../store/features/session/session-slice';
import { BundlingSelectCplAddComponent } from '../../components/ApnFilter/components/BundlingSelectCplAddComponent';
import { ToolsMenuItem } from '../../components/ToolsMenuExt';
import { getCurrencyOptions } from '../../api/cached/getCurrency';
import { CurrencySelectCplEdit } from '../../components/CurrencySelectCplEdit';
import { getYears } from '../../api/cached/getYears';
import { FormStore } from '../../common/FormStore';

interface PropsDemandEdit {
  formStore: FormStore<ComponentPriceListEditData>;
}

interface YearItem {
  yearId: number;
  name: string;
}

const emsPartNoRules: Rule[] = [{ max: 100, message: 'Can not be longer than 100 characters' }];
const emsRules: Rule[] = [requiredRule];
const origPriceRule: Rule[] = [priceRule];
const intOnlyRule: Rule[] = [integerOnlyRule];

export const ComponentPriceListEdit: React.FC<PropsDemandEdit> = observer(({ formStore }) => {
  const onSave = (data: Partial<ComponentPriceListEditData>) => {
    if (data.origCurrency === 'n/a') {
      data.origCurrency = null;
    }
    data.bundlingName = data.bundlingID?.toString();
    return formStore.save(data);
  };
  const value = formStore.data;
  const { groups } = useSelector(selectUserInfo);
  const isSupr = groups.includes('SUPUSR');
  const isPrpur = groups.includes('PRPUR');
  const isNegtr = groups.includes('NEGTR');
  // const canChangeEMS = groups.includes('SUPUSR' || 'PRPUR');
  const [form] = Form.useForm<ComponentPriceListRightFilter>();

  async function getMenuItems(): Promise<ToolsMenuItem[]> {
    const options = await getCurrencyOptions();
    const values = form.getFieldsValue();
    const currencyMenuItems: ToolsMenuItem[] = options.map(option => ({
      text: option.label,
      permission: true,
      onClick() {},
    }));
    return currencyMenuItems;
  }
  const [options, setOptions] = React.useState<YearItem[]>();
  const [currencyMenueItems, setCurrencyMenueItems] = React.useState<ToolsMenuItem[]>([]);
  React.useEffect(() => {
    getMenuItems().then(items => setCurrencyMenueItems(items));
  }, []);
  React.useEffect(() => {
    getYears().then(setOptions);
  }, []);
  const onInternalChange = (newEmsValue: string) => {
    var dr = formStore.data;
    dr.emsCode = newEmsValue;
    value.emsName = newEmsValue;
    formStore.setData(dr);
  };
  return (
    <ModalForm
      title='Edit component price list'
      name='demandEdit'
      layout={formLayout}
      initialData={formStore.data}
      visible={formStore.visible}
      saving={formStore.saving}
      onClose={formStore.close}
      onSave={onSave}
    >
      <Form.Item label='EMS' name='emsCode' rules={emsRules}>
        {(isNegtr || isPrpur || isSupr) && value.priceType === 'EMS' && (
          <EmsSelect onChange={onInternalChange} val={value?.emsName} />
        )}
        {value?.emsName}
      </Form.Item>
      <Form.Item
        initialValue={value?.bundlingName ?? undefined}
        label='Bundling'
        name={'BundlingName'}
      >
        <BundlingSelectCplAddComponent
          disable={value.priceType === 'EMS'}
          name={undefined}
          defValue={value?.bundlingName ?? undefined}
        />
      </Form.Item>
      <Form.Item label='BSH Part No'>{value?.bshPartNo}</Form.Item>
      <Form.Item label='Designation'>{value?.designation}</Form.Item>
      <Form.Item
        label='Orig. Price EMS/Estimate'
        name='origCurrency'
        rules={value?.origCurrency === 'n/a' ? undefined : origPriceRule}
      >
        <Input
          disabled={value.priceType === 'Offer'}
          value={value?.origCurrency ?? ''}
          maxLength={30}
        />
      </Form.Item>
      <Form.Item initialValue={'EUR'} label='Orig. Currency' name={'mixedPriceOrigCurrency'}>
        <CurrencySelectCplEdit
          disabled={value.priceType === 'Offer'}
          bundlingId={value?.mixedPriceOrigCurrency}
          name={value?.mixedPriceOrigCurrency}
        />
      </Form.Item>
      <Form.Item label='Year' name='year'>
        <Select allowClear>
          {options &&
            options.map(rec => (
              <Select.Option key={rec.yearId} value={rec.name}>
                {rec.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label='Quantity Base' name='quantityBase' rules={intOnlyRule}>
        <Input
          disabled={value.priceType === 'EMS'}
          value={value?.quantityBase ?? ''}
          maxLength={30}
        />
      </Form.Item>
      <Form.Item label='Max Lead Time[wks]' name='maxLeadTime' rules={intOnlyRule}>
        <Input
          disabled={value?.priceType === 'Offer'}
          value={value?.maxLeadTime ?? ''}
          maxLength={30}
        />
      </Form.Item>
      <Form.Item label='Comment' name='comment'>
        <Input disabled={!isSupr && !isPrpur} value={value?.comment ?? ''} maxLength={200} />
      </Form.Item>
    </ModalForm>
  );
});

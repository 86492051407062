import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Table, Layout, Button } from 'antd';
import { LoaderBox } from '../../components/LoaderBox';
import { MYARow } from './MYA.types';
import { MYAPageStore } from './MYAPageStore';
import { dateFormatter } from '../../utils';
import { MYATools } from './MYATools';
import { MYAEditModal } from './MYAEditModal';
import { MYAActions } from './MYAActions';
import { OrderCodeList } from './OrderCodeList';

const pageStore = new MYAPageStore();

export const MYAPage: React.FC = observer(() => {
  const { table, orderCodesMya } = pageStore;
  React.useEffect(() => {
    pageStore.init();
  }, []);
  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>Multi-Year Agreement</h1>
        </Col>
        <Col>
          <MYATools store={pageStore} />
        </Col>
      </Row>
      <Layout>
        <Layout.Content>
          <Table<MYARow>
            size='small'
            rowKey='myaId'
            dataSource={table.data}
            loading={table.isLoading}
            pagination={table.paginator}
            onChange={table.onTableChange}
          >
            <Table.Column
              title='Manufacturer'
              key='manufacturerName'
              dataIndex='manufacturerName'
              width='10%'
              ellipsis
            />
            <Table.Column title='Document' key='subject' dataIndex='subject' width='15%' ellipsis />
            <Table.Column
              title='Start Date'
              key='startDate'
              dataIndex='startDate'
              width='10%'
              render={value => dateFormatter(value)}
            />
            <Table.Column
              title='End Date'
              key='endDate'
              dataIndex='endDate'
              width='10%'
              render={value => dateFormatter(value)}
            />
            <Table.Column
              title='Comment'
              key='comments'
              dataIndex='comments'
              width='25%'
              ellipsis
            />
            <Table.Column
              title='Order Codes'
              key='orderCodes'
              width='10%'
              render={record => (
                <Button type='link' onClick={() => pageStore.setOrderCodesMya(record)}>
                  List
                </Button>
              )}
            />
            <Table.Column
              title='PDF'
              key='link'
              dataIndex='link'
              width='5%'
              render={value =>
                value ? (
                  <a href={value} target='_blank' rel='noreferrer'>
                    Link
                  </a>
                ) : (
                  ''
                )
              }
            />
            <Table.Column
              title='Actions'
              key='actions'
              width='15%'
              align='center'
              render={(row: MYARow) => <MYAActions row={row} store={pageStore} />}
            />
          </Table>
          <MYAEditModal title='Edit MYA' isCreate={false} editStore={pageStore.edit} />
          <OrderCodeList mya={orderCodesMya} onFinish={() => pageStore.setOrderCodesMya(null)} />
        </Layout.Content>
      </Layout>
    </LoaderBox>
  );
});

import { action, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { drawErrorMessage, drawNotification, drawNotificationSuccess } from '../../common/drawErrorMessage';
import { Rest } from '../../api/Rest';
import { LOARow } from './LOAPage.types';
import { Console } from 'console';

interface PdfUrlResponse {
    url: string;
    emailSent: boolean;
}

export class LOAExportMailStore extends LoaderStore {
    addLineStatus: 'none' | 'active' | 'save' = 'none';

    constructor() {
        super();
        makeObservable(this, {
            addLineStatus: observable,
            startAddLine: action,
            closeAddLine: action,
            createNewLine: action,
        });
    }

    get isAddLine(): boolean {
        return this.addLineStatus !== 'none';
    }

    get isAddLineSaving(): boolean {
        return this.addLineStatus === 'save';
    }

    startAddLine() {
        this.addLineStatus = 'active';
    }

    async createNewLine(values: Partial<LOARow>) {
        this.addLineStatus = 'save';
        try {
            const response = await Rest.post<PdfUrlResponse, typeof values>('/api/v1/Loa/Mail', values);
            const pdfUrl = response.url;
            const emailSent = response.emailSent;
            if (pdfUrl) {
                const a = document.createElement('a');
                //a.href = pdfUrl;
                //document.body.appendChild(a);
                //a.click();
                //document.body.removeChild(a);
            } else {
                console.error('PDF URL is not valid. Response:', response);
            }
            if (emailSent) {
                drawNotificationSuccess('Email has been sent successfully.');
            }
            else {
                drawNotification("Failed to send the email")
            }
        } catch (e) {
            drawErrorMessage(e, "Can't generate PDF!");
        } finally {
            this.startAddLine();
        }
    }

    closeAddLine() {
        this.addLineStatus = 'none';
    }
}
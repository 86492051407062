import { createRequestSliceWithPagination } from '../../utils/request-slice-with-pagination';
import { Demand, DemandUserActions } from './demand-list-types';
import { AppThunk } from '../../index';
import { demand } from '../../../api';
import { RootState } from '../../root-reducer';
import { PaginationParams, ResponseWithPagination } from '../../utils/state-types';
import { selectNonNullDemandFilters } from '../demand-filter/demand-filter-slice';

export const demandListSlice = createRequestSliceWithPagination({
  name: 'demand-list',
  initialDataState: [] as Demand[],
  reducers: {},
});

const mapLockUnlockActions = (response: ResponseWithPagination<Demand[]>) => {
  return {
    ...response,
    data: response.data.map(item => ({
      ...item,
      actions: {
        ...item.actions,
        ...(item.actions.lock?.allow ? { unlock: undefined } : {}),
        ...(item.actions.unlock?.allow ? { lock: undefined } : {}),
      },
    })),
  } as ResponseWithPagination<Demand[]>;
};

export const fetchDemandList = (): AppThunk => async (dispatch, getState) => {
  try {
    const state = getState();
    const { pageSize, page } = state.demandList.meta;
    const filters = selectNonNullDemandFilters(state);

    dispatch(demandListSlice.actions.request());
    const demandList = await demand.getList({ pageSize, page, ...filters });
    dispatch(demandListSlice.actions.requestSuccess(mapLockUnlockActions(demandList)));
  } catch (err) {
    dispatch(demandListSlice.actions.requestError());
    console.log(err);
  }
};

export const setPagination = (
  params: Partial<PaginationParams> = {}
): AppThunk => async dispatch => {
  try {
    dispatch(demandListSlice.actions.setPagination(params));
    dispatch(fetchDemandList());
  } catch (err) {
    console.log(err);
  }
};

export const selectDemandListState = (state: RootState) => {
  return state.demandList;
};

export const selectDemandListStatus = (state: RootState) => {
  return state.demandList.status;
};

export const selectDemandList = (state: RootState) => {
  return state.demandList.data;
};

export const selectDemandListMeta = (state: RootState) => {
  return state.demandList.meta;
};

export const selectCanAddManufacturer = (demandId: number) => (state: RootState) => {
  const demand = state.demandList.data.find(demand => demand.demandId === demandId);
  if (!demand) return false;

  const action = demand.actions[DemandUserActions.AddManufacturer];
  if (!action) return false;

  return action.allow;
};

export default demandListSlice.reducer;

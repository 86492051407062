import * as React from 'react';
import { CurrencyList } from './currency-list';
import { Menu } from 'antd';
import { ActionPermission } from '../api/types';
import { PermissionCheck } from './PermissionCheck';

export interface CurrencyListItem {
  text: string;
  permission?: ActionPermission | boolean; // use true, if have no permission for item
  onClick(): void;
}
export interface CurrencyInfo {
  currencyId: number;
  name: string;
}

interface PropsCurrencyListExt {
  items: CurrencyListItem[];
  currency: string;
}

export const CurrencyListExt: React.FC<PropsCurrencyListExt> = ({ items, currency }) => {
  const isVisible = !!items.find(it => it.permission);
  if (!isVisible) {
    return null;
  }
  return (
    <CurrencyList caption={currency}>
      {items.map(({ text, permission, onClick }) =>
        typeof permission === 'boolean' ? (
          <Menu.Item key={text} onClick={onClick}>
            {text}
          </Menu.Item>
        ) : (
          <PermissionCheck key={text} permission={permission} onClick={onClick}>
            <Menu.Item>{text}</Menu.Item>
          </PermissionCheck>
        )
      )}
    </CurrencyList>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { LoaderBox } from '../components/LoaderBox';
import { appStore } from './MainFrameStore';

export const MainFrame: React.FC = observer(({ children }) => {
  React.useEffect(() => appStore.init(), []);
  return (
    <LoaderBox store={appStore} style={{ minHeight: '100vh' }}>
      {children}
    </LoaderBox>
  );
});

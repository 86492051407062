import * as React from 'react';
import { TableUploaderModal } from '../../components/TableUploader';
import { CommodityPageStore, getCommodityExportKey } from './CommodityPageStore';
import { TableExportModal } from '../../components/TableExport';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { findPermission } from '../../utils';

interface PropsCommodityMenu {
  store: CommodityPageStore;
}

const msgImport = 'Commodity Import';
const msgExport = 'Commodity Export';

export const CommodityMenu: React.FC<PropsCommodityMenu> = ({ store }) => {
  const [importActive, setImportActive] = React.useState(false);
  const [exportActive, setExportActive] = React.useState(false);
  const menuItems: ToolsMenuItem[] = [
    {
      text: msgImport,
      permission: store.importPermission,
      onClick: () => setImportActive(true),
    },
    {
      text: msgExport,
      permission: findPermission(store.actions, 'Export'),
      onClick: () => setExportActive(true),
    },
  ];
  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={importActive}
        title={msgImport}
        importType='Commodity'
        onClose={() => setImportActive(false)}
        store={store.table}
      />
      <TableExportModal
        visible={exportActive}
        title={msgExport}
        getExportKey={getCommodityExportKey}
        onClose={() => setExportActive(false)}
      />
    </>
  );
};

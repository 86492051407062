import * as React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { DemandQuotaStore } from './DemandQuotaStore';
import { DQRow } from './DemandQuota.types';
import './DemandQuota.less';
import { DemandQuotaActions } from './DemandQuotaActions';
import { DemandQuotaEdit } from './DemandQuotaEdit';

interface PropsDemandQuotaTable {
  store?: DemandQuotaStore;
}
export const DemandQuotaTable: React.FC<PropsDemandQuotaTable> = observer(({ store }) => {
  if (!store) return null;

  const rowClassName = () => {
    return store.isLocked ? 'demand-quota-row-locked' : '';
  };
  return (
    <>
      <Table<DQRow>
        dataSource={store.data}
        loading={store.isLoading}
        size='small'
        pagination={store.paginator}
        onChange={store.onTableChange}
        rowKey='quotaId'
        rowClassName={rowClassName}
        className='demand-quota-table'
      >
        <Table.Column
          title='Manufacturer'
          dataIndex='manufacturerName'
          key='manufacturerName'
          width='25%'
          ellipsis
        />
        <Table.Column
          title='Order code'
          dataIndex='orderCode'
          key='orderCode'
          width='20%'
          ellipsis
        />
        <Table.Column
          title='Distri Part No'
          dataIndex='distriPartNo'
          key='distriPartNo'
          width='22%'
          ellipsis
        />
        <Table.Column title='Comment' dataIndex='comment' key='comment' width='22%' ellipsis />
        <Table.Column
          title='Incoterm Code'
          dataIndex='incotermName'
          key='incotermName'
          width='15%'
        />
        <Table.Column
          title='Incoterm Location'
          dataIndex='incotermDirection'
          key='incotermDirection'
          width='15%'
        />
        <Table.Column title='Distributor' dataIndex='distributor' key='distributor' width='10%' />
        <Table.Column
          title='LogOn Distri'
          dataIndex='logOnDistri'
          key='logOnDistri'
          width='22%'
          ellipsis
        />
        <Table.Column
          title='Actions'
          key='actions'
          width='13%'
          render={row => <DemandQuotaActions row={row} store={store} />}
        />
      </Table>
      <DemandQuotaEdit store={store} />
    </>
  );
});

import * as React from 'react';
import { CurrentCampaignInfo, getCurrentCampaign } from '../api/cached/getCurrentCampaign';
import { mapCampaignStatus } from '../utils';

interface PropsCurrentCampaignTitle {}

export const CurrentCampaignTitle: React.FC<PropsCurrentCampaignTitle> = () => {
  const [info, setInfo] = React.useState<CurrentCampaignInfo | null>(null);
  React.useEffect(() => {
    getCurrentCampaign().then(res => setInfo(res));
  }, []);
  if (!info) {
    return null;
  }
  return (
    <span>
      {mapCampaignStatus(info.statusName)} [{info.year}]
    </span>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { DemandPageStore } from './DemandPageStore';
import { Button, Divider, Form, Input, Modal, Space } from 'antd';
import { formFooterLayout, formLayout, requiredRule } from '../../common/constants';
import { EmsSelect } from '../../components/ems-select/ems-select';
import { DemandField } from './demand-field';
import { Rule } from 'antd/es/form';
import { DemandRow } from './DemandPage.types';
import { useEffect } from 'react';
import { BshPartNoProjectsField } from './demand-add-line/bsh-part-no-projects-field';
import { BundlingSelectAddComponent } from '../../components/ApnFilter/components/BundlingSelectAddComponent';

interface PropsDemandAddNewLine {
  store: DemandPageStore;
}

let emsCache: string | undefined = undefined;

const emsPartNoRules: Rule[] = [{ max: 100, message: 'Can not be longer than 100 characters' }];
const emsRules: Rule[] = [requiredRule];
const initialValues = { demand: 0, emsCode: emsCache };

type Fields = Pick<DemandRow, 'demand' | 'emsCode' | 'emsPartNo' | 'bshPartNo'>;

export const DemandAddNewLine: React.FC<PropsDemandAddNewLine> = observer(({ store }) => {
  const [form] = Form.useForm();
  const [isBshPartNo, setIsBshPartNo] = React.useState(false);
  const isVisible = store.isAddLine;
  const onSubmit = (values: Fields) => {
    if (emsCache === undefined && (values.emsCode != null || values.emsCode != undefined)) {
      emsCache = values.emsCode ?? undefined;
    }
    if (values.emsCode === undefined) {
      values.emsCode = emsCache ?? null;
    }

    store.createNewLine(values);
    if (emsCache != undefined && emsCache != null) {
      emsCache = values.emsCode ?? undefined;
    }
  };
  const onCancel = () => store.closeAddLine();

  useEffect(() => {
    if (!isVisible) {
      form.resetFields();
    }
  }, [form, isVisible]);

  return (
    <Modal
      destroyOnClose={true}
      visible={isVisible}
      title='Add new component'
      footer={null}
      onCancel={onCancel}
      maskClosable={false}
    >
      <Form<Fields> {...formLayout} onFinish={onSubmit} form={form} initialValues={initialValues}>
        <Form.Item label='EMS' name='emsCode'>
          <EmsSelect val={emsCache} />
        </Form.Item>
        <BshPartNoProjectsField isActive={true} onChange={setIsBshPartNo} />
        <BundlingSelectAddComponent />
        <Form.Item label='EMS Part No' name='emsPartNo' rules={emsPartNoRules}>
          <Input />
        </Form.Item>
        <DemandField />

        <Divider />

        <Form.Item {...formFooterLayout}>
          <Space>
            <Form.Item noStyle shouldUpdate>
              {() => {
                const errors = form.getFieldsError();
                const isTouched = form.isFieldTouched(['bshPartNo']);
                const hasErrors = errors.some(({ errors }) => errors.length > 0);
                const isDisabled = !isBshPartNo || !isTouched || hasErrors;

                return (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={isDisabled}
                    loading={store.isAddLineSaving}
                  >
                    Add
                  </Button>
                );
              }}
            </Form.Item>

            <Button type='default' htmlType='reset' onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
});

import * as React from 'react';
import { Form, Select } from 'antd';
import { NegotiatorItem } from '../../../api/types';
import { getNegotiators } from '../../../api/cached/getNegotiators';
import { icmp } from '../../../common/icmp';

export const NegotiatorSelect: React.FC = () => {
  const [options, setOptions] = React.useState<NegotiatorItem[] | null>(null);
  React.useEffect(() => {
    getNegotiators().then(list => {
      list.sort((a, b) => icmp(a.name, b.name));
      setOptions(list);
    });
  }, []);
  return (
    <Form.Item label='Negotiator' name='NegotiatorId'>
      <Select
        allowClear
        loading={options === null}
        showSearch
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(a, b) => icmp(a.children, b.children)}
      >
        {options &&
          options.map(item => (
            <Select.Option value={item.negotiatorNickId} key={item.negotiatorNickId}>
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

import * as React from 'react';
import { Button, Form, Modal, Space } from 'antd';
import { expandFormFields } from '../../common/expandFormFields';
import { ColProps } from 'antd/lib/col';
import { FormInstance } from 'antd/es/form';

export interface FormLayout {
  layout?: 'horizontal' | 'vertical' | 'inline';
  labelCol?: ColProps;
  wrapperCol?: ColProps;
}

export interface PropsModalForm<T> {
  title: string;
  name: string;
  visible: boolean;
  saving: boolean;
  layout: FormLayout;
  initialData?: Partial<T>;
  autoFocus?: string;
  onClose(): void;
  onSave(data: Partial<T>): void;
  children?: React.ReactNode;
  onValuesChange?(changedValues: Partial<T>, allValues: T): void;
  submitText?: string;
  getForm?(form?: FormInstance<T>): void;
}

export const ModalForm = <T,>(props: PropsModalForm<T>) => {
  const {
    title,
    name,
    visible,
    saving,
    initialData,
    autoFocus,
    onClose,
    onSave,
    children,
    layout,
    onValuesChange,
    submitText = 'Save',
    getForm,
  } = props;
  const formRef = React.useRef(null);
  React.useEffect(() => {
    if (autoFocus && visible && formRef && formRef.current) {
      const item = document.querySelector(`input[name=${autoFocus}]`);
      if (item instanceof HTMLInputElement) {
        setTimeout(() => item.select(), 200);
      }
    }
    // eslint-disable-next-line
  }, [autoFocus, formRef, initialData, visible]);

  const tailLayout: FormLayout = {};
  if (layout) {
    const offset = layout?.labelCol?.span;
    const span = layout?.wrapperCol?.span;
    tailLayout.wrapperCol = { offset, span };
  }

  const [form] = Form.useForm<T>();
  React.useEffect(() => {
    if (getForm) getForm(form);
  }, [form, getForm]);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      destroyOnClose
    >
      <Form
        {...layout}
        ref={formRef}
        name={name}
        onFinish={onSave}
        fields={expandFormFields(initialData)}
        onValuesChange={onValuesChange}
        form={form}
      >
        {children}
        <Form.Item style={{ textAlign: 'right' }} {...tailLayout}>
          <Space>
            <Button type='default' onClick={onClose}>
              Cancel
            </Button>

            <Form.Item noStyle shouldUpdate>
              {() => {
                const errors = form.getFieldsError();
                const hasErrors = errors.some(({ errors }) => errors.length > 0);

                return (
                  <Button
                    type='primary'
                    htmlType='submit'
                    loading={saving}
                    disabled={saving || hasErrors}
                  >
                    {submitText}
                  </Button>
                );
              }}
            </Form.Item>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

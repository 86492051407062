import React from 'react';
import { Form, Select } from 'antd';

interface DocumentTypeProps {
    onSelectDocumentType: (value: string) => void;
}

const documentTypeOptions = [
    { value: 'Letter of Authorization', label: 'Letter of Authorization' },
    { value: 'Agreement on Conditions', label: 'Agreement on Conditions' },
];

export const DocumentType: React.FC<DocumentTypeProps> = ({ onSelectDocumentType }) => {
    const documentRules = [{ required: true, message: 'Please select a Document type' }];

    React.useEffect(() => {
        // Trigger the onSelectDocumentType callback with the default value when component mounts
        onSelectDocumentType(documentTypeOptions[0].value);
    }, []); // Run only once when component mounts

    return (
        <Form.Item
            label={<span><span style={{ color: '#ff4d4f', fontSize: '14px', fontFamily: 'SimSun, sans-serif', marginRight: '4px' }}>*</span><span>Document type</span></span>}
            rules={documentRules}
            colon={true}
        >
            <Select
                onSelect={onSelectDocumentType}
                style={{ width: '100%' }}
                placeholder="Select Document Type"
                defaultValue={documentTypeOptions[0].value}
                options={documentTypeOptions}
            />
        </Form.Item>
    );
};

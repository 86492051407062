import { action, computed, makeObservable, observable } from 'mobx';
import { LoaderStore } from '../../common/LoaderStore';
import { TableStore, FnDataLoad, RemoteResponse } from '../../common/TableStore';
import { SDRow, SDFilter, SDPageActionsMap } from './ShareDistribution.types';
import { getRows } from '../../api/getRows';
import { SD2TableStore } from './SD2Table/SD2TableStore';
import { getCurrentCampaign, CurrentCampaignInfo } from '../../api/cached/getCurrentCampaign';
import { getCurrencyOptions } from '../../api/cached/getCurrency';
import { LabelValue } from '../../common/types';
import { ApnFilterStore } from '../../components/ApnFilter/ApnFilterStore';
import { makeStrParams, Rest } from '../../api/Rest';
import { ComponentsExt, transformComponents } from '../../common/apn/transformComponents';
import { updatePageActions } from '../../common/updatePageActions';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { getBundlingOptions } from '../../api/cached/getBundlings';
import { sdRightFields } from './ShareDistribution.types';
import { CompleteAllResult } from '../../api/utils/complete-all-result';
import { SD2Table } from './SD2Table';

const complete = (demandId: number, isCompleted: boolean) => {
  return Rest.post(`/api/v1/apn/Distribution/${demandId}/completion`, { isCompleted });
};

const loadSD: FnDataLoad<SDRow, SDFilter> = filter => {
  // Array Components is used in the request parameters.
  return getRows<SDRow, ComponentsExt<SDFilter>>(
    '/api/v1/apn/Distribution',
    transformComponents(filter)
  );
};

class ShareDistributionTableStore extends TableStore<SDRow, SDFilter> {
  subTableDict: Record<number, SD2TableStore> = {};

  constructor() {
    super({ page: 0, pageSize: 10, sortOrder: 'ascend', sortBy: 'bshPartNo' }, loadSD);
    makeObservable(this, {
      onExpand: action,
      setSubTableDict: action,
      subTableDict: observable,
      expandedRowKeys: computed,
    });
  }
  onData(response: RemoteResponse<SDRow>, newFilter: SDFilter) {
    super.onData(response, newFilter);
    this.subTableDict = {};
  }
  onExpand(
    expand: boolean,
    row: SDRow,
    filter: ApnFilterStore<SDRow, SDFilter>,
    currentYear?: number
  ) {
    if (!expand) {
      delete this.subTableDict[row.demandId];
    } else {
      const subTable = new SD2TableStore(row, filter, currentYear);
      this.subTableDict[row.demandId] = subTable;
      subTable.init();
    }
  }
  async onComplete(demandId: number, isCompleted: boolean) {
    try {
      const { subTableDict } = this;
      await complete(demandId, isCompleted);
      await this.reload();
      this.setSubTableDict(subTableDict);
    } catch (e) {
      drawErrorMessage(e);
    }
  }
  getSubTable(row: SDRow): SD2TableStore | undefined {
    return this.subTableDict[row.demandId];
  }
  setSubTableDict(subTableDict: Record<number, SD2TableStore>) {
    this.subTableDict = subTableDict;
  }
  get expandedRowKeys(): number[] {
    return Object.keys(this.subTableDict).map(id => +id);
  }
  save(rowPart: Partial<SDRow>): Promise<void> {
    const { demandId, ...data } = rowPart;
    return Rest.patch(`/api/v1/apn/Distribution/${demandId}`, data).then(() => this.safeReload());
  }
  async safeReload(): Promise<void> {
    const { subTableDict } = this;
    await this.reload({ notScrollTop: true });
    this.setSubTableDict(subTableDict);
  }
}

export class ShareDistributionPageStore extends LoaderStore {
  table: ShareDistributionTableStore;
  campaign?: CurrentCampaignInfo;
  currencyList: LabelValue[] = [];
  bundlingOptions: LabelValue[] = [];
  filter: ApnFilterStore<SDRow, SDFilter>;
  actions?: SDPageActionsMap;
  checkedRows = [] as SDRow[];
  completeAllResponse: CompleteAllResult[] = [];
  addLineStatus: 'none' | 'active' | 'save' = 'none';


  constructor() {
      super();
    makeObservable(this, {
      campaign: observable,
      actions: observable,
      setCampaign: action,
      setActions: action,
      setCheckedRows: action,
      completeAllResponse: observable,
      checkedRows: observable,
      addLineStatus: observable,
      isAddLine: computed,
      startAddLine: action,
      closeAddLine: action,
      createNewLine: action,
    });
    this.table = new ShareDistributionTableStore();
    this.filter = new ApnFilterStore<SDRow, SDFilter>(this.table);
  }
  init() {
    const loadCampaign = async () => {
      this.setCampaign();
      this.setCampaign(await getCurrentCampaign());
    };
    if (this.loaderStatus === 'none') {
      this.onWait();
      Promise.all([
        loadCampaign(),
        getCurrencyOptions().then(currencyList => (this.currencyList = currencyList)),
        getBundlingOptions().then(res => {
          this.bundlingOptions = res;
        }),
        updatePageActions('apn/Quotation', this),
      ])
        .then(() => this.onLoad())
        .catch(e => this.onError(e));
    } else {
      this.table.reload();
      loadCampaign();
      updatePageActions('apn/Quotation', this);
    }
    }
   get isAddLine(): boolean {
        return this.addLineStatus !== 'none';
    }
   get isAddLineSaving(): boolean {
        return this.addLineStatus === 'save';
    }
   startAddLine() {
        this.addLineStatus = 'active';
    }
    //To to add create line
    createNewLine() {
        this.addLineStatus = 'save';
        
    }
    closeAddLine() {
        this.addLineStatus = 'none';
    }
  setCampaign(campaign?: CurrentCampaignInfo) {
    this.campaign = campaign;
  }
  onLoad() {
    this.onReady();
    this.table.init();
  }
  onLoadHiddenItemsNegotiator(hiddenItems: boolean) {
    this.table.filter.ShowHiddenItemsNegotiator = hiddenItems;
  }
  setActions(actions: SDPageActionsMap) {
    this.actions = actions;
  }
  async onComplete(demandId: number, isCompleted: boolean) {
    await this.table.onComplete(demandId, isCompleted);
    updatePageActions('apn/Quotation', this);
  }

  setCheckedRows(checkedRows: SDRow[]) {
    this.checkedRows = checkedRows;
  }

  async markCompleteAll() {
    interface DistributionCompletion {
      IsCompleted: boolean;
    }

    var distributionCompletionList: DistributionCompletion[] = [];
    this.checkedRows.map(({ isCompleted }) =>
      distributionCompletionList.push({ IsCompleted: true })
      );

    //interface Body {
    //  Completion: DistributionCompletion[];
    //  DemandIds: number[];
    //}

    // checked rows not required any more for this endpoint. All demands will be completed.

    //const body: Body = {
    //  Completion: distributionCompletionList,
    //  DemandIds: this.checkedRows.map(({ demandId }) => demandId),
    //};

    await Rest.post<CompleteAllResult[], Body>('/api/v1/Apn/Distribution/all/completion')
      .then(response => {
        this.completeAllResponse = response;
      })
      .catch(e => drawErrorMessage(e))
      .finally(() => {});
  }

  getExportKey = (): Promise<string> => {
    const pureFilter = {} as Partial<SDFilter>;
    // @ts-ignore
    sdRightFields.forEach(key => (pureFilter[key] = this.table.filter[key]));
    const filter = transformComponents(pureFilter);
    const params = makeStrParams(filter);
    return Rest.postExt<string, void>(`/api/v1/Apn/Distribution/${this.campaign?.year}/export`, {
      queryStringParameters: params,
      responseType: 'text',
    });
  };

  get canEdit(): boolean {
    return this.actions?.edit?.allow || false;
  }
}

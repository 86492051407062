import * as React from 'react';
import { changeDate, EmsPageStore, openDemand } from './EmsPageStore';
import { observer } from 'mobx-react';
import { Button, DatePicker, Form, Input, Modal, Space, Tooltip } from 'antd';
import { EmsEditData, EmsPageActionsMap } from './EmsPage.types';
import { formLayout, requiredRule } from '../../common/constants';
import moment, { Moment } from 'moment';
import { Rule } from 'antd/es/form';
import { expandFormFields } from '../../common/expandFormFields';
import { ProjectSelect } from '../../components/ProjectsSelect';
import { filterToSave } from './EmsPage.utils';
import { drawErrorMessage } from '../../common/drawErrorMessage';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionPermission } from '../../api/types';
import { PermissionCheck } from '../../components/PermissionCheck';
import { SapVendorSelect } from '../../components/SapVendorsSelect/SapVendorsSelect';

interface PropsEmsEditModal {
  store: EmsPageStore;
}

// End date can’t be less than tomorrow
const disabledDate = (current: Moment) => current < moment();

const emailSyntax: Rule = {
  pattern: new RegExp(
    '^(\\s?[^\\s,]+@[^\\s,]+\\.[^\\s,]+\\s?;)*(\\s?[^\\s,]+@[^\\s,]+\\.[^\\s,]+)$'
  ),
  message: 'E-mail or e-mails separated by semicolons',
};

const permissionMsg = (
  actions: EmsPageActionsMap,
  name: keyof EmsPageActionsMap
): string | undefined => {
  const permission: ActionPermission | undefined = actions[name];
  if (!permission) {
    return '';
  }
  if (!permission.allow) {
    return permission.reasons[0] || '';
  }
};

const notAllowed = (msg: string | undefined): boolean => msg !== undefined;

export const EmsEditModal: React.FC<PropsEmsEditModal> = observer(({ store }) => {
  const { edit, actions } = store;
  const { data, currentData } = edit;
  const { name, emsOpened } = data;
  const [form] = Form.useForm<EmsEditData>();
  const [openWait, setOpenWait] = React.useState(false);
  const cvtData = () => filterToSave(edit.currentData, edit.data);
  const onSave = () => {
    if (edit.data.endDate !== edit.currentData.endDate) {
      onChangeDate();
    } else {
      edit.save(cvtData());
    }
  };

  const isOpenValid = !!currentData.endDate && !!currentData.email;

  const onOpenOk = async () => {
    setOpenWait(true);
    try {
      const body = await form.validateFields();
      const values = filterToSave(body, edit.data);
      await openDemand(edit.data, values);
      edit.close();
    } catch (e) {
      drawErrorMessage(e);
    } finally {
      setOpenWait(false);
    }
    store.table.updateRow(edit.data.code);
  };

  const onChangeDateOk = async () => {
    setOpenWait(true);
    try {
      const body = await form.validateFields();
      const values = filterToSave(body, edit.data);
      await changeDate(edit.data, values);
      //    edit.close();
    } catch (e) {
      drawErrorMessage(e);
    } finally {
      setOpenWait(false);
    }
    store.table.updateRow(edit.data.code);
    edit.save(cvtData());
  };
  const onOpen = () => {
    const { destroy } = Modal.confirm({
      title: `Demand Collection will be opened for EMS ${name}`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: async () => {
        destroy();
        await onOpenOk();
      },
    });
  };

  const onChangeDate = () => {
    const { destroy } = Modal.confirm({
      title: `Email will be send to EMS ${name} with changed End date!`,
      icon: <ExclamationCircleOutlined />,
      content: `Press OK to continue`,
      onOk: async () => {
        destroy();
        await onChangeDateOk();
      },
    });
  };

  const emailRules: Rule[] = emsOpened ? [requiredRule, emailSyntax] : [emailSyntax];
  const reasonEndDate = permissionMsg(actions, 'setenddate');
  const reasonEMail = permissionMsg(actions, 'setemail');
  const reasonProjects = permissionMsg(actions, 'setprojects');

  return (
    <Modal
      destroyOnClose
      visible={edit.visible}
      title={`Edit properties of EMS ${name}`}
      onCancel={edit.close}
      footer={null}
      maskClosable={false}
    >
      <Form<EmsEditData>
        {...formLayout}
        form={form}
        fields={expandFormFields(edit.currentData)}
        onValuesChange={(_, values) => edit.onValuesChanged(values)}
        onFinish={onSave}
      >
        <Tooltip title={reasonEndDate}>
          <Form.Item label='End date' name='endDate' rules={emsOpened ? [requiredRule] : []}>
            <DatePicker
              allowClear={!emsOpened}
              disabledDate={disabledDate}
              showToday={false}
              disabled={notAllowed(reasonEndDate)}
            />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonEMail}>
          <Form.Item label='E-mail(s)' name='email' rules={emailRules}>
            <Input disabled={notAllowed(reasonEMail)} allowClear={!emsOpened} />
          </Form.Item>
        </Tooltip>
        {/* EPDB-596: The project list can't be edited for EMS with opened demand collection */}
        <Tooltip title={reasonProjects}>
          <Form.Item label='Project(s)' name='projects'>
            <ProjectSelect disabled={notAllowed(reasonProjects)} />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonProjects}>
          <Form.Item label='SAP vendor(s)' name='sapVendors'>
            <SapVendorSelect disabled={notAllowed(reasonProjects)} />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonEMail}>
          <Form.Item label='Address' name='address'>
            <Input disabled={notAllowed(reasonEMail)} allowClear={!emsOpened} />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonEMail}>
          <Form.Item label='Phone number' name='phoneNumber'>
            <Input disabled={notAllowed(reasonEMail)} allowClear={!emsOpened} />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonEMail}>
          <Form.Item label='BSH CKA name' name='bshCkaName'>
            <Input disabled={notAllowed(reasonEMail)} allowClear={!emsOpened} />
          </Form.Item>
        </Tooltip>
        <Tooltip title={reasonEMail}>
          <Form.Item label='Contact Name' name='contactName'>
            <Input disabled={notAllowed(reasonEMail)} allowClear={!emsOpened} />
          </Form.Item>
        </Tooltip>
        <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button onClick={edit.close}>Cancel</Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={edit.saving}
              disabled={Object.keys(cvtData()).length === 0 || edit.saving}
            >
              Save
            </Button>
            {!emsOpened && (
              <PermissionCheck
                permission={actions.opendemandcollection}
                onClick={onOpen}
                loading={openWait}
                disabled={!isOpenValid || openWait}
              >
                <Button>Open Demand Collection</Button>
              </PermissionCheck>
            )}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
});

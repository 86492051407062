import * as React from 'react';
import { Form, Select } from 'antd';
import { observer } from 'mobx-react';
import { CommodityRow } from '../CommodityPage.types';
import { ModalForm } from '../../../components/ModalForm';
import { FormStore } from '../../../common/FormStore';
import { NegotiatorItem } from '../../../api/types';
import { icmp } from '../../../common/icmp';

const { Option } = Select;

export interface PropsEditCommodity {
  formStore: FormStore<CommodityRow>;
  negotiators: NegotiatorItem[];
}

export const EditCommodity: React.FC<PropsEditCommodity> = observer(
  ({ formStore, negotiators }) => {
    const idNick = 'negotiatorNickId';
    const disabled = formStore.saving;
    const onSave = (data: Partial<CommodityRow>) => {
      const named = { ...data };
      if (named.negotiatorNickId === undefined) {
        named.negotiatorNick = null;
        named.negotiatorNickId = null;
      } else {
        const negItem = negotiators.find(item => item.negotiatorNickId === named.negotiatorNickId);
        if (negItem) {
          named.negotiatorNick = negItem.name;
        }
      }
      return formStore.save(named);
    };
    return (
      <ModalForm<CommodityRow>
        title='Edit Commodity'
        name='commodity'
        layout={{ layout: 'vertical' }}
        visible={formStore.visible}
        saving={formStore.saving}
        initialData={formStore.data}
        autoFocus={idNick}
        onClose={formStore.close}
        onSave={onSave}
      >
        <Form.Item
          name={idNick}
          label='Assigned Negotiator'
          initialValue={formStore.data[idNick] as number}
        >
          <Select
            allowClear
            disabled={disabled}
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(a, b) => icmp(a.children, b.children)}
          >
            {negotiators.map(item => (
              <Option value={item.negotiatorNickId}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </ModalForm>
    );
  }
);

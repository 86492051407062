import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { Button, Col, Menu, Row, Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './header-menu.module.less';
import { menuItems } from './constants';
import { getAvailableMenuItems, renderMenuItem } from './utils';
import { appStore } from '../../MainFrame/MainFrameStore';

const logoutButtonStyles = { borderWidth: 2 };

export const HeaderMenu: FunctionComponent = () => {
  const { pathname } = useLocation();
  const permissions = appStore.menuPermissions;
  const items = useMemo(() => getAvailableMenuItems(menuItems, permissions), [permissions]);

  const handleLogOut = useCallback(() => {
    Auth.signOut()
      .then(result => {
        console.log('Auth.signOut then', result);
      })
      .catch((error: any) => {
        console.log('Auth.signOut catch', error);
      });
  }, []);

  return (
    <Row>
      <Col span='4'>
        <div className={styles.logo}>
          <Logo />
        </div>
      </Col>
      <Col span='19'>
        <Menu mode='horizontal' className={styles.mainMenu} selectedKeys={[pathname]}>
          {items.map(renderMenuItem)}
        </Menu>
      </Col>
      <Col span='1'>
        <Tooltip title='Log Out'>
          <Button
            onClick={handleLogOut}
            type='default'
            shape='circle'
            icon={<LogoutOutlined />}
            style={logoutButtonStyles}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

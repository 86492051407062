import React, { FunctionComponent, useState } from 'react';
import { Form, InputNumber } from 'antd';
import { Rule } from 'antd/es/form';
import { numberFormatter } from '../../../utils';
import { requiredRule } from '../../../common/constants';

const fullWidthStyle = { width: '100%' };
const demandMaxValue = 2000000000;
const demandRules: Rule[] = [
  requiredRule,
  { type: 'number', min: 0, message: 'Must be zero or greater number' },
  {
    type: 'number',
    max: demandMaxValue,
    message: `Must be less than ${numberFormatter(demandMaxValue)}`,
  },
];

interface DemandFieldProps {
  onStep?: () => void;
}

export const DemandField: FunctionComponent<DemandFieldProps> = ({ onStep = () => {} }) => {
  const [formatted, setFormatted] = useState(false);

  return (
    <Form.Item label='Demand' name='demand' rules={demandRules}>
      {formatted ? (
        <InputNumber
          style={fullWidthStyle}
          formatter={numberFormatter}
          onStep={onStep}
          onFocus={() => setFormatted(false)}
        />
      ) : (
        <InputNumber style={fullWidthStyle} onBlur={() => setFormatted(true)} />
      )}
    </Form.Item>
  );
};

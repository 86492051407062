import * as React from 'react';
import { Select } from 'antd';
import { getCurrencyOptions } from '../api/cached/getCurrency';

interface BundleItem {
  bundlingId: string | null;
  disabled: boolean;
  name: string | null;
  onChange?: (value: string) => void;
}

export const CurrencySelectCplEdit: React.FC<BundleItem> = ({
  bundlingId,
  disabled,
  name,
  onChange,
}) => {
  const [options, setOptions] = React.useState<BundleItem[] | null>(null);

  async function getMenuItems(): Promise<BundleItem[]> {
    const options = await getCurrencyOptions();
    const currencyMenuItems: BundleItem[] = options.map(option => ({
      bundlingId: option.label,
      disabled: false,
      name: option.label,
      onChange() {},
    }));
    return currencyMenuItems;
  }

  React.useEffect(() => {
    getMenuItems().then(setOptions);
  }, []);
  return (
    <Select
      disabled={disabled}
      defaultValue={name ?? ''}
      onChange={onChange}
      allowClear
      loading={options === null}
    >
      {options &&
        options.map(rec => (
          <Select.Option
            disabled={disabled}
            key={rec.bundlingId ?? undefined}
            value={rec.bundlingId ?? ''}
          >
            {rec.bundlingId}
          </Select.Option>
        ))}
    </Select>
  );
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Table } from 'antd';
import { LoaderBox } from '../../components/LoaderBox';
import { ManufacturerPageStore } from './ManufacturerPageStore';
import { ManufacturerRow } from './ManufacturerPage.types';
import { ManufacturerMenu } from './ManufacturerMenu';
const { Column } = Table;

const pageStore = new ManufacturerPageStore();

export const ManufacturerPage: React.FC = observer(() => {
  const { table } = pageStore;

  React.useEffect(() => pageStore.init(), []);

  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>Manufacturers</h1>
        </Col>
        <Col>
          <ManufacturerMenu store={pageStore} />
        </Col>
      </Row>
      <Table<ManufacturerRow>
        size='small'
        rowKey='manufacturerId'
        dataSource={table.data}
        loading={table.isLoading}
        pagination={table.paginator}
      >
        <Column title='Manufacturer' key='name' dataIndex='name' />
        <Column title='M-code' key='code' dataIndex='code' />
        <Column title='EDM-code' key='edmCode' dataIndex='edmCode' />
      </Table>
    </LoaderBox>
  );
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { TableUploaderModal } from '../../components/TableUploader';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { TableExportModal } from '../../components/TableExport';
import { ManufacturerPageStore } from './ManufacturerPageStore';
import { Rest } from '../../api/Rest';

type Mode = 'import' | 'export';
interface ManufacturerMenuProps {
  store: ManufacturerPageStore;
}

const exportManufacturer = 'Export Manufacturer Codes';
const importManufacturer = 'Import Manufacturer Codes';

const getExportKey = (): Promise<string> =>
  Rest.postExt('/api/v1/Manufacturer/export', { responseType: 'text' });

export const ManufacturerMenu: React.FC<ManufacturerMenuProps> = observer(({ store }) => {
  const [mode, setMode] = React.useState<'' | Mode>('');
  const clear = () => setMode('');
  const menuItems: ToolsMenuItem[] = [
    {
      text: importManufacturer,
      permission: store.actions?.import,
      onClick: () => setMode('import'),
    },
    {
      text: exportManufacturer,
      permission: store.actions?.export,
      onClick: () => setMode('export'),
    },
  ];

  return (
    <>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderModal
        visible={mode === 'import'}
        title={importManufacturer}
        importType='Manufacturer'
        onClose={clear}
        store={store.table}
      />
      <TableExportModal
        visible={mode === 'export'}
        title={exportManufacturer}
        onClose={clear}
        getExportKey={getExportKey}
      />
    </>
  );
});

import React, { FunctionComponent } from 'react';
import { Spin } from 'antd';
import { dateFormatter, numberFormatter, priceFormatter } from '../../utils';
import { PreviousSessionRow } from './PreviousSessions.types';
import './PreviousSession.less';

interface PreviousSessionProps {
  loading: boolean;
  sessions: PreviousSessionRow[] | null;
}

export const PreviousSession: FunctionComponent<PreviousSessionProps> = ({ loading, sessions }) => {
  if (loading) {
    return (
      <div className='previous-session-loading'>
        <Spin size='small' tip='Loading' />
      </div>
    );
  }

  if (!sessions || sessions.length === 0) {
    return <div>No previous sessions</div>;
  }

  return (
    <div className='previous-session-container'>
      {sessions?.map(({ createdOn, demand, price, currencyCode, quota, sessionId }) => {
        return (
          <div className='previous-session' key={sessionId}>
            <h4 color='default' className='previous-session-title'>
              Session ({dateFormatter(createdOn)})
            </h4>
            <span className='previous-session-item'>Demand: {numberFormatter(demand)}</span>
            <span className='previous-session-item'>
              Unit price: {priceFormatter(price)} {currencyCode}
            </span>
            <span className='previous-session-item'>Quota: {quota}%</span>
          </div>
        );
      })}
    </div>
  );
};

import { TableStore } from '../../common/TableStore';
import { ApnFilterFields, getFilterFields } from './ApnFilter.types';
import { action, computed, makeObservable, observable } from 'mobx';

export class ApnFilterStore<TRow, TFilter extends ApnFilterFields<TRow>> {
  table: TableStore<TRow, TFilter>;
  isOpen = false;
  constructor(table: TableStore<TRow, TFilter>) {
    this.table = table;
    makeObservable(this, {
      isOpen: observable,
      toggle: action,
      isUsed: computed,
    });
  }
  toggle() {
    this.isOpen = !this.isOpen;
  }
  /** true, if any filter field is non empty */
  get isUsed(): boolean {
    return !!getFilterFields().find(name => {
      const value = this.table.filter[name];
      return value !== undefined && value !== false;
    });
  }
}

import { RequestStatus } from './state-types';

export const RequestStatuses: {
  init: Readonly<RequestStatus>;
  request: Readonly<RequestStatus>;
  requestSuccess: Readonly<RequestStatus>;
  error: Readonly<RequestStatus>;
} = {
  init: {
    loading: false,
    loaded: false,
    error: false,
  },
  request: {
    loading: true,
    loaded: false,
    error: false,
  },
  requestSuccess: {
    loading: false,
    loaded: true,
    error: false,
  },
  error: {
    loading: false,
    loaded: false,
    error: true,
  },
};

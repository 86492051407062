import * as React from 'react';
import { observer } from 'mobx-react';
import { ComponentPriceListPageStore } from './ComponentPriceListPageStore';
import { addNoItemsPermission } from '../../utils';
import { ToolsMenuExt, ToolsMenuItem } from '../../components/ToolsMenuExt';
import { OfferPriceExport } from '../../components/OfferPriceExport/OfferPriceExport';
import { CurrencyListExt } from '../../components/CurrencyListExt';
import { getCurrencyOptions } from '../../api/cached/getCurrency';
import { Col, Form } from 'antd';
import { ComponentPriceListRightFilter } from './ComponentPriceListPage.types';
import { TableExportModal } from '../../components/TableExport';
import { TableUploaderPreImportModal } from '../../components/TableUploaderPreImport/TableUploaderPreImportModal';
import { defaultCurrencyStore } from './DefaultCurrencyStore';

interface PropsDemandTools {
  store: ComponentPriceListPageStore;
}
type Mode =
  | 'importComponents'
  | 'importDemand'
  | 'importEmsPrice'
  | 'importOfferPrice'
  | 'importPreEmsPrice'
  | 'importPreOfferPrice'
  | 'exportOfferPrice'
  | 'exportCplGrid';

const msgUploadEMSPrices = 'Import EMS Prices';
const msgUploadOfferPrices = 'Import Offer Prices';
const msgCplExport = 'Export Component Price List';

export const ComponentPriceListTools: React.FC<PropsDemandTools> = observer(({ store }) => {
  const [mode, setMode] = React.useState<'' | Mode>('');
  const clear = () => setMode('');
  const { table } = store;
  const { canLock, canUnlock } = table;
  const addComponentPermission = store.permissions?.addcomponentcpl;
  const importEmsPrice = store.permissions?.import;
  const importOfferPrice = store.permissions?.import;
  const exportOfferPrice = store.permissions?.export;
  const exportCplGrid = store.permissions?.export;
  const allowBulk = store.permissions?.bulklock;
  const bulkChangeEMSPermission = store.permissions?.bulkemschange;
  const [form] = Form.useForm<ComponentPriceListRightFilter>();
  const [defaultCurrency, setDefaultCurrency] = React.useState(
    defaultCurrencyStore.defaultCurrency
  );
  const reload = async () => {
    await table.reload();
  };
  const canLockPermission = addNoItemsPermission(
    canLock,
    allowBulk,
    'Please check a row suitable for lock'
  );
  const canUnlockPermission = addNoItemsPermission(
    canUnlock,
    allowBulk,
    'Please check a row suitable for unlock'
  );
  const canBulkChangeEmskPermission = addNoItemsPermission(
    table.checkedRows.length > 0,
    bulkChangeEMSPermission,
    'Please check a row suitable for bulk ems change'
  );
  const onSubmit = (values: ComponentPriceListRightFilter, currency: string) => {
    defaultCurrencyStore.setDefaultCurrency(currency);
    setDefaultCurrency(currency);
    values.Currency = currency;
    store.table.load({ page: 0, ...values });
  };

  async function getMenuItems(): Promise<ToolsMenuItem[]> {
    const options = await getCurrencyOptions();
    const values = form.getFieldsValue();
    const currencyMenuItems: ToolsMenuItem[] = options.map(option => ({
      text: option.label,
      permission: exportCplGrid,
      onClick: () => {
        onSubmit(values, option.value);
      },
    }));
    return currencyMenuItems;
  }
  const [currencyMenueItems, setCurrencyMenueItems] = React.useState<ToolsMenuItem[]>([]);
  React.useEffect(() => {
    getMenuItems().then(items => setCurrencyMenueItems(items));
  }, []);

  const menuItems: ToolsMenuItem[] = [
    {
      text: 'Add component',
      permission: addComponentPermission,
      onClick: () => store.startAddLine(),
    },
    {
      text: 'Import EMS prices',
      permission: importEmsPrice,
      onClick: () => setMode('importPreEmsPrice'),
    },
    {
      text: 'Import Offer prices',
      permission: importOfferPrice,
      onClick: () => setMode('importPreOfferPrice'),
    },
    {
      text: 'Export Offer prices',
      permission: exportOfferPrice,
      onClick: () => setMode('exportOfferPrice'),
    },
    {
      text: 'Export Component Price List',
      permission: exportCplGrid,
      onClick: () => setMode('exportCplGrid'),
    },
  ];

  return (
    <>
      <span style={{ float: 'left', color: '#ff8629' }}>
        Currency :<CurrencyListExt items={currencyMenueItems} currency={defaultCurrency} />
      </span>
      <ToolsMenuExt items={menuItems} />
      <TableUploaderPreImportModal
        visible={mode === 'importPreEmsPrice'}
        title={msgUploadEMSPrices}
        importType='PreEmsPrice'
        onClose={clear}
        store={null}
      />
      <TableUploaderPreImportModal
        visible={mode === 'importPreOfferPrice'}
        title={msgUploadOfferPrices}
        importType='PreOfferPrice'
        onClose={clear}
        store={null}
      />
      <OfferPriceExport
        visible={mode === 'exportOfferPrice'}
        onClose={clear}
        filter={store.table.filter}
      />
      <TableExportModal
        visible={mode === 'exportCplGrid'}
        title={msgCplExport}
        onClose={clear}
        getExportKey={store.getExportKey}
      />
    </>
  );
});

import * as React from 'react';
import { Button } from 'antd';
import { TableExportModal } from '../../../components/TableExport';
import { CampaignRow } from '../CampaignPage.types';
import { CampaignPageStore } from '../CampaignPageStore';
import { PermissionCheck } from '../../../components/PermissionCheck';

interface PropsCampaignDownloadColumn {
  row: CampaignRow;
  store: CampaignPageStore;
}

export const CampaignDownloadColumn: React.FC<PropsCampaignDownloadColumn> = ({ row, store }) => {
  const [isDownload, setDownload] = React.useState(false);
  return (
    <>
      <PermissionCheck permission={row.actions.exportapn} onClick={() => setDownload(true)}>
        <Button type='link'>Download APN</Button>
      </PermissionCheck>
      <TableExportModal
        visible={isDownload}
        title='Download APN'
        onClose={() => setDownload(false)}
        getExportKey={store.exportKeyMaker(row)}
      />
    </>
  );
};

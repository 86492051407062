import * as React from 'react';
import { ApnRow } from '../common/apn';
import { Space, Tooltip } from 'antd';
import { AuditOutlined, ShopOutlined } from '@ant-design/icons';

interface PropsApnCommentIcons {
  row: ApnRow;
}

export const ApnCommentIcons: React.FC<PropsApnCommentIcons> = ({ row }) => (
  <Space>
    {row.manufacturerComment && (
      <Tooltip title={row.manufacturerComment} placement='bottom'>
        <ShopOutlined />
      </Tooltip>
    )}
    {row.emsComment && (
      <Tooltip title={row.emsComment} placement='bottom'>
        <AuditOutlined />
      </Tooltip>
    )}
  </Space>
);

import * as React from 'react';
import { observer } from 'mobx-react';
import { EmsPageStore } from './EmsPageStore';
import { LoaderBox } from '../../components/LoaderBox';
import { Col, Row } from 'antd';
import { CurrentCampaignTitle } from '../../components/CurrentCampaignTitle';
import { EmsTools } from './EmsTools';
import { EmsTable } from './EmsTable';
import { EmsEditModal } from './EmsEditModal';
import { EmsSetEndDate } from './EmsSetEndDate';

const pageStore = new EmsPageStore();

export const EmsPage: React.FC = observer(() => {
  React.useEffect(() => pageStore.init(), []);
  return (
    <LoaderBox store={pageStore}>
      <Row align='middle'>
        <Col flex='auto'>
          <h1>
            EMS List <CurrentCampaignTitle />
          </h1>
        </Col>
        <Col>
          <EmsTools store={pageStore} />
        </Col>
      </Row>
      <EmsTable store={pageStore} />
      <EmsEditModal store={pageStore} />
      <EmsSetEndDate store={pageStore} />
    </LoaderBox>
  );
});

import React from 'react';
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import {
  overviewPaths,
  demandCollectionPaths,
  reportsPath,
  requestForQuotationPath,
  shareDistribution,
  bomPath,
  componentPriceListPath,
  matrixBasisPath,
} from './routes-paths';
import { Overview } from '../pages/overview/overview';
import { DefaultLayout } from '../components/default-layout/default-layout';
import { Campaign } from '../pages/campaign/campaign';
import { CommodityPage } from '../pages/CommodityPage';
import { ComponentPage } from '../pages/ComponentPage';
import { RequestForQuotationPage } from '../pages/RequestForQuotationPage';
import { ShareDistributionPage } from '../pages/ShareDistributionPage';
import { EdmDataPage } from '../pages/EdmDataPage';
import { DemandPage } from '../pages/DemandPage';
import { MYAPage } from '../pages/MYAPage';
import { EmsPage } from '../pages/EmsPage';
import { appStore } from '../MainFrame/MainFrameStore';
import { ManufacturerPage } from '../pages/ManufacturerPage';
import { ReportsPage } from '../pages/ReportsPage';
import { BomPage } from '../pages/BomPage';
import { ComponentPriceListPage } from '../pages/BomPage/ComponentPriceListPage';
import { MatrixBasisPage } from '../pages/BomPage/MatrixBasisPage';

const routesMap = [
  { path: overviewPaths.campaign, component: <Campaign /> },
  { path: overviewPaths.ems, component: <EmsPage /> },
  { path: overviewPaths.components, component: <ComponentPage /> },
  { path: overviewPaths.manufacturers, component: <ManufacturerPage /> },
  { path: overviewPaths.edmData, component: <EdmDataPage /> },
  { path: overviewPaths.commodityCodes, component: <CommodityPage /> },
  { path: demandCollectionPaths.current, component: <DemandPage /> },
  { path: overviewPaths.mya, component: <MYAPage /> },
  { path: demandCollectionPaths.demandHistory, component: null },
  { path: requestForQuotationPath, component: <RequestForQuotationPage /> },
  { path: componentPriceListPath, component: <ComponentPriceListPage /> },
  { path: matrixBasisPath, component: <MatrixBasisPage /> },
  { path: shareDistribution, component: <ShareDistributionPage /> },
  { path: reportsPath, component: <ReportsPage /> },
  { path: bomPath, component: <BomPage /> },
];

const Routes = () => {
  const { homeMenuItem } = appStore;
  const homePath = homeMenuItem?.to || '404';
  return (
    <Router>
      <DefaultLayout>
        <Switch>
          {routesMap.map(({ path, component }) => (
            <Route key={path} path={path}>
              {component || <h1>{path}</h1>}
            </Route>
          ))}
          <Route path='404'>
            <Overview />
          </Route>
          <Route>
            <Redirect to={homePath} />
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  );
};

export default Routes;
